import { UPDATE_BASKET_MUTATION } from "../schema/basket";
import ukfdGraph from "../ukfdGraph";
import { updateRollSkuWidth } from "./updateRollSkuWidth";

export const updateRollQuantity = (
  basketId: string,
  product: Product,
  width: string,
  length: string,
  promocodesRequested: string[],
  customerType: string
) => {
  return ukfdGraph(UPDATE_BASKET_MUTATION, {
    id: basketId,
    basketItemId: product.basketItemId,
    sku: updateRollSkuWidth(product.sku, Number(width)),
    quantity: Number(length),
    productType: product.details?.productType,
    promocodesRequested,
    customerType,
  });
};
