import { gql } from "@apollo/client";

export const PROMO_FIELDS = gql`
  fragment Promo on PromoSummary {
    subtotal
    subtotalExVat
    discounttotal
    summary {
      code
      isValid
      applicabilityreason
      status
    }
  }
`;
