import { useState } from "react";
import TrashIcon from "@heroicons/react/solid/TrashIcon";
import { Button, LoadingSpinner } from "@ukfd/ui";
import s from "./SampleItem.module.css";
import { SampleItemProps } from "./SampleItem.types";

const SampleItem: React.FC<SampleItemProps> = ({ altText, imageUrl, title, onRemove }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className={s.root} data-testid="basket-item-sample">
      {isLoading && <LoadingSpinner />}
      {imageUrl && (
        <img
          alt={altText}
          className={s.image}
          height={400}
          loading="lazy"
          src={imageUrl}
          width={600}
        />
      )}
      <div className={s.productDetailsWrapper}>
        <div className={s.productDetails}>{title && <p className={s.title}>{title}</p>}</div>
        <div className={s.prices}>
          <p className={s.sample} data-testid="basket-item-sample-indicator">
            Sample
          </p>
          <Button
            className={s.removeButton}
            data-testid="basket-item-sample-remove-button"
            variant="icon"
            onClick={() => {
              setIsLoading(true);
              onRemove?.();
            }}
          >
            <TrashIcon className={s.removeButtonIcon} />
          </Button>
        </div>
      </div>
    </div>
  );
};
export default SampleItem;
