import { FC, useState } from "react";
import TrashIcon from "@heroicons/react/solid/TrashIcon";
import { HOME_URL, mappedRollWidthOptions, preventValuesLessThanOne } from "@ukfd/checkout-utils";
import {
  formatAsCurrency,
  getAnchorElementFromClick,
  getCheckoutRequiredData,
  onLinkClickForwardWithURLParams,
} from "@ukfd/shared-utils";
import { Button, LoadingSpinner, NativeInput, Select } from "@ukfd/ui";
import s from "./BasketItemRolls.module.css";
import type { BasketItemRollsProps } from "./BasketItemRolls.types";

const BasketItemRolls: FC<BasketItemRollsProps> = ({
  altText,
  imageUrl,
  title,
  totalPrice,
  squareMeterPrice,
  rollLength,
  rollWidth,
  rollWidthOptions,
  onChange,
  onRemove,
  url,
}) => {
  const [length, setLength] = useState(String(rollLength));
  const [width, setWidth] = useState(rollWidth);
  const [isLoading, setIsLoading] = useState(false);

  const mappedOptions = mappedRollWidthOptions(rollWidthOptions);
  const mappedOptionsFallback = [{ text: String(rollWidth), value: String(rollWidth) }];

  const itemURL = `${HOME_URL}/${url}`;

  return (
    <div className={s.root} data-testid="basket-item-roll">
      {isLoading && <LoadingSpinner />}
      {imageUrl && (
        <a
          href={itemURL}
          onClick={(e) => {
            e.preventDefault();
            const checkoutData = getCheckoutRequiredData();
            const target = getAnchorElementFromClick(e.target as HTMLElement);
            onLinkClickForwardWithURLParams(target as HTMLElement, checkoutData);
          }}
        >
          <img
            alt={altText}
            className={s.image}
            height={400}
            loading="lazy"
            src={imageUrl}
            width={600}
          />
        </a>
      )}
      <div className={s.productDetailsWrapper}>
        <div className={s.productDetails}>
          {title && (
            <a className={s.title} href={itemURL}>
              {title}
            </a>
          )}
        </div>
        <div className={s.prices}>
          {!!totalPrice && (
            <p className={s.totalPrice} data-testid="basket-item-roll-total-price">
              {formatAsCurrency(totalPrice)}
            </p>
          )}
          {squareMeterPrice && (
            <p className={s.squareMeterPrice} data-testid="basket-item-roll-sqm-price">
              {"Per sq m. "}
              {formatAsCurrency(squareMeterPrice)}
            </p>
          )}
        </div>
      </div>
      <div className={s.units}>
        <div className={s.inputWrapper}>
          <div className={s.inputGroup}>
            <Select
              className={s.select}
              data-testid="basket-item-roll-width"
              disabled={!mappedOptions}
              label="Roll width"
              labelClassName={s.inputLabel}
              name="rollWidth"
              options={mappedOptions ?? mappedOptionsFallback}
              value={String(rollWidth)}
              native
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                onChange?.({ width: Number(e.target.value), length });
                setWidth(Number(e.target.value));
              }}
            />
          </div>
          <div className={s.inputGroup}>
            <NativeInput
              className={s.input}
              inputMode="decimal"
              labelClassName={s.inputLabel}
              name="length-input"
              step={0.1}
              type="number"
              value={length}
              onBlur={(e) => {
                if (e.target.value === "") {
                  setLength(preventValuesLessThanOne("1"));
                  onChange?.({ length: preventValuesLessThanOne("1"), width });
                }
              }}
              onChange={(e) => {
                setLength(preventValuesLessThanOne(e.target.value));
                onChange?.({
                  length: preventValuesLessThanOne(e.target.value),
                  width,
                });
              }}
            />
          </div>
          <Button
            className={s.removeButton}
            data-testid="basket-item-roll-remove-button"
            variant="icon"
            onClick={() => {
              setIsLoading(true);
              onRemove?.();
            }}
          >
            <TrashIcon className={s.removeButtonIcon} />
          </Button>
          <p className={s.rollRequired} data-testid="basket-item-roll-required">
            {`Roll required = ${rollWidth}m x ${rollLength}m`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BasketItemRolls;
