import * as React from "react";
import { InvoiceMethodProps } from "./InvoiceMethod.types";

const InvoiceMethod: React.FC<InvoiceMethodProps> = () => (
  <div id="invoice">
    <p>
      <b>Terms</b>
    </p>
    <p>SCA Web Payment - BACS/Finance</p>
    <br />
    <p>
      I agree to pay with my current Invoice{" "}
      <a
        className="underline"
        href="https://trade.ukflooringdirect.co.uk/terms-conditions"
        rel="noreferrer"
        target="_blank"
      >
        Terms & Conditions
      </a>
    </p>
  </div>
);

export default InvoiceMethod;
