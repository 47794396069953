import { Field, useFormikContext } from "formik";
import clsx from "clsx";
import { formatAsCurrency } from "@ukfd/shared-utils";
import s from "./DeliveryOptions.module.css";
import { DeliveryOptionsProps } from "./DeliveryOptions.types";

const DeliveryOptions: React.FC<DeliveryOptionsProps> = ({ options }) => {
  const { values } = useFormikContext<FormikOrderDocument>();

  if (!options.length) {
    return null;
  }

  return (
    <div className={s.root}>
      {options?.map(({ description, price, value }, index) => {
        return (
          <label
            key={index}
            className={clsx(s.option, {
              [s.selected]: values.deliveryMethod.deliveryOption === value,
            })}
          >
            <Field name="deliveryMethod.deliveryOption" type="radio" value={value} />
            <span className={s.description}>{description}</span>
            <span className={s.price}>{formatAsCurrency(price)}</span>
          </label>
        );
      })}
    </div>
  );
};

export default DeliveryOptions;
