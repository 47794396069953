import * as React from "react";
import { useFormikContext } from "formik";
import { getFormikErrorMessages, gtmEvent, scrollFirstErrorIntoView } from "@ukfd/checkout-utils";
import { Button } from "@ukfd/ui";
import s from "./PlaceSampleOrderButton.module.css";

const PlaceSampleOrderButton: React.FC = () => {
  const { isSubmitting, isValid, submitForm, errors } = useFormikContext<FormikOrderDocument>();

  return (
    <div className={s.root}>
      <div className={s.container}>
        <div className={s.buttonContainer}>
          <Button
            className={s.placeSampleOrderButton}
            disabled={isSubmitting}
            loading={isSubmitting}
            type={isValid ? "submit" : "button"}
            onClick={async () => {
              if (!isValid) {
                await submitForm();
                await scrollFirstErrorIntoView();
                gtmEvent("placeSampleOrderValidationError", {
                  page: { category: "checkout-validation" },
                  errors: getFormikErrorMessages(errors),
                });
              }
            }}
          >
            {isSubmitting ? "Processing" : "Place Order"}
          </Button>
        </div>
        <div className={s.termsContainer}>
          <p className={s.termsText}>
            By placing your order, you agree to our{" "}
            <a
              className={s.termsAnchor}
              href="https://trade.ukflooringdirect.co.uk/terms-conditions"
              rel="noreferrer"
              target="_blank"
            >
              Terms &amp; Conditions
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PlaceSampleOrderButton;
