import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Cookies from "js-cookie";
import { withRetry } from "@ukfd/shared-utils";

const useAnalytics = (): {
  custbody_captured_data_webstore?: NetSuiteOrderDocumentWebstoreData;
  custbody_google_analytics_client_id?: string;
} => {
  const router = useRouter();
  const [clientId, setClientId] = useState<string>("");

  useEffect(() => {
    const waitForId = async () => {
      const id = await withRetry(() => window?.ga?.getByName?.("newTracker")?.get?.("clientId"));
      return setClientId(id);
    };
    waitForId();
  }, []);

  if (typeof window === "undefined") {
    return {
      custbody_google_analytics_client_id: clientId,
      custbody_captured_data_webstore: undefined,
    };
  }

  let custbody_captured_data_webstore =
    (Cookies.get("custbody_captured_data_webstore") as string) ||
    (router.query?.custbody_captured_data_webstore as string);

  if (custbody_captured_data_webstore) {
    custbody_captured_data_webstore = JSON.parse(window.atob(custbody_captured_data_webstore));
  }

  return {
    custbody_google_analytics_client_id:
      clientId ||
      Cookies.get("custbody_google_analytics_client_id") ||
      (router.query?.custbody_google_analytics_client_id as string) ||
      "",
    custbody_captured_data_webstore: (custbody_captured_data_webstore ||
      {}) as unknown as NetSuiteOrderDocumentWebstoreData,
  };
};

export default useAnalytics;
