import { gql } from "@apollo/client";

export const VOID_PAYMENT_MUTATION = gql`
  mutation voidPayment($orderId: String!) {
    voidPayment(orderId: $orderId) {
      orderId
      status
    }
  }
`;
