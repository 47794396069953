import * as React from "react";
import { Section, Text } from "@ukfd/ui";
import { BasketLayout } from "@components";

const EmptyBasket: React.FC = () => (
  <BasketLayout>
    <Section>
      <Text variant="sectionHeading">Shopping Basket</Text>
      <p data-testid="empty-basket-msg">Your basket is empty</p>
    </Section>
  </BasketLayout>
);

export default EmptyBasket;
