import { useContext } from "react";
import { useRouter } from "next/router";
import { Form, Formik } from "formik";
import clsx from "clsx";
import { SAMPLE_CHECKOUT_VALIDATION_SCHEMA } from "@ukfd/checkout-utils";
import { getInitialSampleCheckoutValues } from "@ukfd/constants";
import { Logger, scrollIntoView } from "@ukfd/shared-utils";
import { Section, Text } from "@ukfd/ui";
import { CheckoutContext, UiStateContext } from "@context";
import { useOrderDocument, useTryValidateOrder } from "@hooks";
import {
  AddressSelector,
  BasketLayout,
  CustomerDetails,
  MessageCentre,
  PlaceSampleOrderButton,
  SampleItem,
  SampleSurveyForm,
} from "@components";
import s from "./SampleCheckout.module.css";

const SampleCheckout: React.FC = () => {
  const { basket, customer, handleOnRemoveProduct } = useContext(CheckoutContext);
  const router = useRouter();

  const {
    uiState: { deliveryAddressMode },
    setUiState,
  } = useContext(UiStateContext);

  const { tryValidateOrder } = useTryValidateOrder();
  const { createSampleOrderDocument } = useOrderDocument();

  return (
    <Formik
      initialValues={getInitialSampleCheckoutValues({ customer })}
      validationSchema={SAMPLE_CHECKOUT_VALIDATION_SCHEMA}
      onSubmit={async (values) => {
        const orderDocument = createSampleOrderDocument(values);

        const savedOrderDocument: SavedOrderDocument = {
          ...orderDocument,
          basket: {
            ...orderDocument.basket,
            items: basket.basketItems,
          },
          payment: {
            type: "card",
          },
          deliveryAddressMode,
        };

        const { success, data } = await tryValidateOrder(orderDocument, savedOrderDocument);
        if (!success) {
          (data as Error[]).forEach((error) => Logger.error(error));
          setUiState({
            processingError: "sample order validation failed",
          });
          scrollIntoView();
          return;
        }

        await router.push(`/order/ProcessOrder`);
      }}
    >
      <BasketLayout
        aside={
          <>
            <div className="bg-white p-5 gap-3">
              <Text className={s.mainTitle} variant="asideHeading">
                Order summary
              </Text>
              <>
                <div className={s.container} data-testid="order-value">
                  <p className={s.title}>Sub total</p> <p className={s.value}>Free</p>{" "}
                  <p className={s.title}>Delivery</p> <p className={s.value}>Free</p>
                </div>
                <div className={clsx(s.container, s.basketTotal)} data-testid="basket-total">
                  <p className={s.title}>Total</p>{" "}
                  <p className={s.value} data-testid="basket-total">
                    Free
                  </p>
                </div>
              </>
            </div>
          </>
        }
      >
        <MessageCentre />

        <Section>
          <Text variant="sectionHeading">Shopping Basket</Text>
          {basket.basketItems
            .filter((product) => product.details?.productClass === "sample")
            .map((product) => (
              <SampleItem
                key={product.sku}
                altText=""
                imageUrl={encodeURI(product.details?.image ?? "")}
                title={product.details?.title}
                onRemove={() => handleOnRemoveProduct(product)}
              />
            ))}
        </Section>

        <Form className={s.checkoutForm}>
          <Section>
            <CustomerDetails title="Customer Details" />
          </Section>

          <Section>
            <AddressSelector />
          </Section>

          <Section>
            <SampleSurveyForm />
          </Section>

          <PlaceSampleOrderButton />
        </Form>
      </BasketLayout>
    </Formik>
  );
};

export default SampleCheckout;
