import { FC, ReactNode, createContext, useState } from "react";
import { useRouter } from "next/router";
import to from "await-to-js";
import {
  getPromoCodes,
  gtmCheckoutUpdate,
  gtmHandleIncreaseOrDecrease,
  removeBasketProduct,
  savePromoCodesToCookie,
  sortBasket,
  updatePackQuantity,
  updateRollQuantity,
} from "@ukfd/checkout-utils";
import { Logger } from "@ukfd/shared-utils";

export type CheckoutContext = {
  basket: Basket;
  delivery: DeliveryOptions;
  paymentOptions: PaymentOptions;
  customer: Customer;
  token: string;
  dataLayerProps?: DataLayerProps;
  basketTotal: number | null;
  isVatIncluded: boolean;
  isVatExcluded: boolean;
};

export const CheckoutContext = createContext<
  CheckoutContext & BasketItemsContainer & PromotionalCode
>({} as any);

const CheckoutProvider: FC<{ value: CheckoutContext; children?: ReactNode }> = ({
  children,
  value,
}) => {
  const router = useRouter();
  const params = router.query;

  const [basket, setBasket] = useState(value.basket);
  const [delivery, setDelivery] = useState(value.delivery);
  const basketTotal = basket?.price?.subtotal || 0;

  const isVatExcluded = value.customer?.customerType === "trade" && params.isVatOn === "false";
  const isVatIncluded = !isVatExcluded;
  const MAX_QUANTITY = 9999;

  const setUpdatedBasket = (updatedBasket: Basket) => {
    setBasket({
      ...updatedBasket,
      basketItems: sortBasket(updatedBasket?.basketItems),
    });
  };

  const setUpdatedDelivery = (updatedDelivery: DeliveryOptions) => {
    setDelivery({
      ...delivery,
      ...updatedDelivery,
    });
  };

  const handleOnPackUpdate = async (product: Product, quantity: number) => {
    if (!quantity) {
      return;
    }

    // exceed max quantity check
    const updatedQuantity = quantity > MAX_QUANTITY ? MAX_QUANTITY : quantity;
    const [updatePackError, updatePackResponse] = await to(
      updatePackQuantity(
        basket.id,
        product,
        updatedQuantity,
        getPromoCodes(basket.promo),
        value.customer.customerType
      )
    );

    if (updatePackError) {
      Logger.error(
        new Error(`Error updating pack product quantity: ${updatePackError}, SKU: ${product.sku}`)
      );
      return;
    }

    gtmHandleIncreaseOrDecrease({
      product,
      basketItems: basket.basketItems,
      updatedBasketItems: updatePackResponse?.data.checkoutUpdateBasket.basket.basketItems,
    });

    setUpdatedBasket(updatePackResponse?.data.checkoutUpdateBasket.basket);
    setDelivery(updatePackResponse?.data.checkoutUpdateBasket.delivery);
  };

  const handleOnRollUpdate = async (product: Product, width: string, length: string) => {
    if (length === "") {
      return;
    }

    // exceed max quantity check
    const updatedLength =
      Number.parseFloat(length) > MAX_QUANTITY ? `${MAX_QUANTITY}` : `${Number.parseFloat(length)}`;

    const [updateRollError, updateRollResponse] = await to(
      updateRollQuantity(
        basket.id,
        product,
        width,
        updatedLength,
        getPromoCodes(basket.promo),
        value.customer.customerType
      )
    );

    window.dataLayer.push({
      event: "roll_width_edit_checkout",
      roll_width_edit_parent_sku: product.sku,
      roll_width_edit_previous: product.details?.rollWidth,
      roll_width_edit_new: Number(width),
    });

    gtmHandleIncreaseOrDecrease({
      product,
      basketItems: basket.basketItems,
      updatedBasketItems: updateRollResponse?.data.checkoutUpdateBasket.basket.basketItems,
    });

    if (updateRollError) {
      Logger.error(
        new Error(`Error updating roll product quantity: ${updateRollError}, SKU: ${product.sku}`)
      );
      return;
    }

    setUpdatedBasket(updateRollResponse?.data.checkoutUpdateBasket.basket);
    setDelivery(updateRollResponse?.data.checkoutUpdateBasket.delivery);
  };

  const handleOnRemoveProduct = async (product: Product) => {
    const [onRemoveProductError, onRemoveProductResponse] = await to(
      removeBasketProduct(
        basket.id,
        product,
        getPromoCodes(basket.promo),
        value.customer.customerType
      )
    );

    gtmCheckoutUpdate("remove", product);

    if (onRemoveProductError) {
      console.error("Error removing product quantity", onRemoveProductError, product.sku);
      return;
    }

    setUpdatedBasket(onRemoveProductResponse?.data?.checkoutDeleteBasketItem.basket);
    setDelivery(onRemoveProductResponse?.data?.checkoutDeleteBasketItem.delivery);
  };

  const onPromotionalCodeApplied = (updatedBasket: Basket) => {
    // Update the basket
    setUpdatedBasket(updatedBasket);
    savePromoCodesToCookie(getPromoCodes(updatedBasket.promo));
  };

  return (
    <CheckoutContext.Provider
      value={{
        ...value,
        handleOnPackUpdate,
        handleOnRemoveProduct,
        handleOnRollUpdate,
        setUpdatedBasket,
        setDelivery: setUpdatedDelivery,
        onPromotionalCodeApplied,
        basket,
        delivery,
        basketTotal,
        isVatIncluded,
        isVatExcluded,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutProvider;
