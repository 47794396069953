import { DeepPartial } from "ts-essentials";
import { getAccessories, getRecommendedAccessories } from "./getRelatedProducts";

export const getEligibleBasketItemForRelatedProducts = (basketItems: DeepPartial<Product[]>) => {
  const getParentSku = (product: DeepPartial<Product>) => {
    if (product?.details?.matrixType === "CHILD") {
      const splitSku = product?.sku?.split("-");
      splitSku?.pop();
      return splitSku?.join("-");
    }
    return product.sku;
  };

  // check for just samples and / or accessories
  const eligibleItems = basketItems
    .map((item) => {
      if (item) item.sku = getParentSku(item);
      return item;
    })
    .filter((item) => {
      if (item) return !/^ACC-/.test(item.sku!) && !/^S-/.test(item.sku!);
    });

  // if only accessories or samples in basket do nothing
  if (!eligibleItems.length) {
    return null;
  }

  // return last item
  return eligibleItems.at(-1)!;
};

const showRelatedProducts = (basketItems: DeepPartial<Product[]>, customerType: CustomerType) => {
  const recommendedAccessories = getRecommendedAccessories();

  // if no items do nothing
  if (!basketItems || !basketItems.length) {
    return null;
  }

  // if customer is trade do nothing
  if (customerType === "trade") {
    return null;
  }

  const product = getEligibleBasketItemForRelatedProducts(basketItems);

  // if only accessories or samples in basket do nothing
  if (!product) {
    return null;
  }

  const recommendedAccessoryData: any[] = recommendedAccessories[product.sku!];

  // if sku look up for related products fails, do nothing
  if (!recommendedAccessoryData) {
    return null;
  }

  const m2TotalCoverage =
    product.details?.productType === "pack"
      ? (product.details?.packs || 1) * (product.details?.m2PerPack || 1)
      : product.details?.m2TotalCoverage || 1;

  let relatedProducts: RelatedProductProps[] = [];

  // if there is a m2Threshold property then check to see
  // if product is greater than or less / equal to threshold
  // and choose the associated product
  if (recommendedAccessoryData[0].m2Threshold) {
    const { m2Threshold } = recommendedAccessoryData[0];
    if (Number(m2TotalCoverage) > Number(m2Threshold)) {
      relatedProducts.push(recommendedAccessoryData[0].greaterThanThreshold);
    } else {
      relatedProducts.push(recommendedAccessoryData[0].lessOrEqualToThreshold);
    }
    // add the rest of related product SKUs
    relatedProducts = relatedProducts.concat(
      recommendedAccessoryData.slice(1, recommendedAccessoryData.length)
    );
  } else {
    relatedProducts = relatedProducts.concat(recommendedAccessoryData);
  }

  const accessories: GetAccessories = getAccessories();

  //@ts-expect-error: will fix later
  relatedProducts = relatedProducts.map((sku) => accessories[sku]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const accessoriesInBasket = basketItems.filter((item) => /^ACC-/.test(item?.sku!));

  // filter our any accessories that are already in basket
  relatedProducts = relatedProducts.filter((item) => {
    const isInBasket = accessoriesInBasket.find((accessory) => accessory?.sku === item?.id);
    if (!isInBasket) {
      return item;
    }
    return null;
  });

  // if no related products, do nothing
  if (!relatedProducts.length) {
    return null;
  }

  return relatedProducts.map((relatedProduct) => {
    if (relatedProduct.coverage) {
      const recommendedQuantity = Math.ceil(m2TotalCoverage / Number(relatedProduct.coverage));
      return { ...relatedProduct, recommendedQuantity };
    }
    return { ...relatedProduct, recommendedQuantity: 1 };
  });
};

export default showRelatedProducts;
