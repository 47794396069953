import { FormikErrors } from "formik";
import isEmpty from "lodash/isEmpty";
import { titleCase } from "@ukfd/shared-utils";

const getFormikErrorMessages = (errors: FormikErrors<FormikOrderDocument>) => {
  if (isEmpty(errors)) {
    return [];
  }

  return Object.keys(errors)
    .map((key) =>
      Object.keys((errors as any)[key]).map(
        (prop) => `${titleCase(key)}: ${(errors as any)[key][prop]}`
      )
    )
    .flat();
};

export default getFormikErrorMessages;
