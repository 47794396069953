import { useContext, useState } from "react";
import clsx from "clsx";
import to from "await-to-js";
import { addProductToBasket, getPromoCodes } from "@ukfd/checkout-utils";
import { formatAsCurrency, logGraphQLErrors } from "@ukfd/shared-utils";
import { Button, Counter, ResponsiveImage } from "@ukfd/ui";
import { CheckoutContext } from "@context";
import s from "./RelatedProductCard.module.css";
import { RelatedProductCardProps } from "./RelatedProductCard.types";

const RelatedProductCard: React.FC<RelatedProductCardProps> = ({
  sku,
  title,
  coverage,
  m2Price,
  packPrice,
  recommendedQuantity,
  srcSet,
  src,
  alt,
  onRelatedProductAddToBasket,
}) => {
  const {
    basket: { basketItems, id, promo },
    customer: { customerType },
    setUpdatedBasket,
    setDelivery,
  } = useContext(CheckoutContext);
  const [quantity, setQuantity] = useState(1);
  const [loading, setIsLoading] = useState(false);

  const totalPrice = quantity * Number(packPrice);
  const recommendedCoverage = recommendedQuantity * Number(coverage);
  const isRollsOrRoll = coverage && recommendedQuantity > 1 ? "rolls" : "roll";
  const isUnitsOrUnit = coverage && recommendedQuantity > 1 ? "units" : "unit";
  const recommendedAmount = sku.match(/^ACC-UN/)
    ? `You'll need ${recommendedQuantity} ${isRollsOrRoll} (${recommendedCoverage}m2)`
    : `You'll need ${recommendedQuantity} ${isUnitsOrUnit} (${recommendedCoverage}m2)`;

  const hasBeenAddedToBasket = !!basketItems.find((item) => sku === item.sku);

  const addToBasket = async () => {
    setIsLoading(true);

    const [error, result] = await to(
      addProductToBasket({
        basketId: id,
        sku,
        promocodesRequested: getPromoCodes(promo),
        quantity,
        customerType,
        productType: "pack",
      })
    );

    const errors = logGraphQLErrors(error, result);
    if (!errors?.length) {
      const { basket, delivery } = result!.data!.checkoutUpdateBasket;
      setUpdatedBasket(basket);
      setDelivery(delivery as DeliveryOptions);
      onRelatedProductAddToBasket({ title, sku, quantity, coverage, packPrice });
    }

    setIsLoading(false);
  };

  return (
    <div className={s.root} data-testid="related-product-card">
      <div className={s.content}>
        <div className={s.section}>
          <ResponsiveImage alt={alt} className={s.thumbnail} src={src} srcSet={srcSet} />
        </div>
        <div className={clsx(s.section, s.details)}>
          <p className={s.title}>{title}</p>
          <div className={s.spec}>
            {coverage && (
              <p className={s.text}>
                Coverage: <span>{coverage}m2</span>
              </p>
            )}
            {sku.match(/^ACC-UN/) && (
              <p className={s.text}>
                Price m2: <span>{formatAsCurrency(Number(m2Price))}</span>
              </p>
            )}
            <p className={s.text}>
              Unit price: <span>{formatAsCurrency(Number(packPrice))}</span>
            </p>
          </div>
        </div>
        <div className={s.section}>
          <div className={s.pricing}>
            {coverage && recommendedQuantity && <p className={s.text}>{recommendedAmount}</p>}
            <div className={s.quantity}>
              <Counter
                id="quantity"
                label="Quantity:"
                max={99}
                min={1}
                start={recommendedQuantity}
                onValueChanged={setQuantity}
              />
            </div>
            <p className={s.text}>Total price: {formatAsCurrency(totalPrice)}</p>
            <Button className={s.button} disabled={loading} loading={loading} onClick={addToBasket}>
              {!hasBeenAddedToBasket && !loading && "Add to Basket"}
              {!hasBeenAddedToBasket && loading && ""}
              {hasBeenAddedToBasket && !loading && "Added to Basket"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedProductCard;
