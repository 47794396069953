import { FC, useContext } from "react";
import debounce from "lodash/debounce";
import { isProductKind } from "@ukfd/checkout-utils";
import { CheckoutContext } from "@context";
import { BasketItem, BasketItemRolls, SampleItem } from "@components";
import s from "./BasketItemsContainer.module.css";

const BasketItemsContainer: FC = () => {
  const { basket, handleOnRemoveProduct, handleOnRollUpdate, handleOnPackUpdate, isVatIncluded } =
    useContext(CheckoutContext);

  const Roll: FC<Product> = (product) => {
    const { details, price } = product || {};
    return (
      <BasketItemRolls
        key={product.basketItemId}
        altText=""
        imageUrl={encodeURI(details?.image?.replace?.("Data Feed", "Data_Feed") ?? "")}
        rollLength={product.quantity}
        rollPrice={isVatIncluded ? price?.packPrice : price?.packPriceExTax}
        rollWidth={details?.rollWidth ?? null}
        rollWidthOptions={details?.rollWidthOptions ?? null}
        squareMeterPrice={isVatIncluded ? price?.m2Price : price?.m2PriceExTax}
        title={details?.title}
        totalPrice={isVatIncluded ? price?.subtotal : price?.subtotalExVat}
        url={details?.url}
        onChange={debounce(({ width, length }) => handleOnRollUpdate(product, width, length), 350)}
        onRemove={() => handleOnRemoveProduct(product)}
      />
    );
  };

  const Pack: FC<Product> = (product) => {
    const { details, price } = product || {};
    return (
      <BasketItem
        key={product.basketItemId}
        altText=""
        imageUrl={encodeURI(details?.image ?? "")}
        packPrice={isVatIncluded ? price?.packPrice : price?.packPriceExTax}
        packValue={details?.packs}
        productClass={details?.productClass}
        squareMeterPrice={isVatIncluded ? price?.m2Price : price?.m2PriceExTax}
        squareMeterValue={product.quantity}
        title={details?.title}
        totalPrice={isVatIncluded ? price?.subtotal : price?.subtotalExVat}
        url={details?.url}
        onChange={debounce((quantity) => handleOnPackUpdate(product, quantity), 350)}
        onRemove={() => handleOnRemoveProduct(product)}
      />
    );
  };

  const Sample: FC<Product> = (product) => {
    const { details } = product || {};
    return (
      <SampleItem
        key={product.basketItemId}
        altText=""
        imageUrl={encodeURI(details?.image ?? "")}
        title={details?.title}
        url={details?.url}
        onRemove={() => handleOnRemoveProduct(product)}
      />
    );
  };

  return (
    <div className={s.root}>
      {basket.basketItems?.map((product, index) => {
        const { isPackProduct, isRollProduct, isPackAccessory, isRollAccessory, isSampleProduct } =
          isProductKind(product);
        switch (true) {
          case isPackProduct:
          case isPackAccessory:
            return <Pack key={index} {...product} />;
          case isRollProduct:
          case isRollAccessory:
            return <Roll key={index} {...product} />;
          case isSampleProduct:
            return <Sample key={index} {...product} />;
          default:
            return null;
        }
      })}
    </div>
  );
};

export default BasketItemsContainer;
