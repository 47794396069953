export const isProductKind = (product: Product) => {
  const { productType, productClass } = product?.details || {};
  return {
    isPackProduct: productType === "pack" && productClass === "product",
    isRollProduct: productType === "roll" && productClass === "product",
    isPackAccessory: productType === "pack" && productClass === "accessory",
    isRollAccessory: productType === "roll" && productClass === "accessory",
    isSampleProduct: productClass === "sample",
  };
};
