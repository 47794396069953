import Bugsnag from "@bugsnag/js";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { Logger } from "@ukfd/shared-utils";
import { SESSION_STORAGE_ORDER_KEY } from "./constants";

type PaymentFlowLog = {
  date: string;
  paymentProvider: string;
  basketID: string;
  orderID: string | null;
  sampleOrderID: string | null;
  paypalOrderId: string | null;
  paypalAuthorisationId: string | null;
  custbody_google_analytics_client_id: string | undefined;
  status: string | null;
  url: string;
  step:
    | "start-order"
    | "validate-order-fail"
    | "validate-order-success"
    | "attempt-claim-payment"
    | "claim-payment-fail"
    | "claim-payment-success"
    | "attempt-validate-payment"
    | "validate-payment-fail"
    | "validate-payment-success"
    | "attempt-place-order"
    | "place-order-fail"
    | "place-order-success"
    | "attempt-tokenise-card"
    | "tokenise-card-success"
    | "tokenise-card-fail";
};
type PaymentFlowLogParams = Pick<PaymentFlowLog, "step">;

const paymentFlowLogger = (params: PaymentFlowLogParams) => {
  try {
    const { step } = params;
    const date = dayjs().toISOString();
    const custbody_google_analytics_client_id = Cookies.get("custbody_google_analytics_client_id");
    const basketID = Cookies.get("basketId")!;

    let orderDocument: SavedOrderDocument | undefined;
    let orderID;
    let sampleOrderID;
    let status;
    let price;
    try {
      orderDocument = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_ORDER_KEY)!);
    } catch (e) {
      Bugsnag.leaveBreadcrumb(`Order document unavailable during ${step}`);
    }

    if (orderDocument) {
      orderID = orderDocument?.orderId;
      sampleOrderID = orderDocument?.sampleOrderId;
      status = orderDocument?.status;
      price = JSON.stringify(orderDocument.price);
    }

    const url = window.location.href;
    const queryParams = new URLSearchParams(window.location.search);
    const paypalOrderId = queryParams.get("externalOrderId");
    const paypalAuthorisationId = queryParams.get("authorisationId");

    Bugsnag.leaveBreadcrumb(
      JSON.stringify({
        step,
        date,
        paymentProvider: orderDocument?.payment?.type,
        basketID,
        orderID,
        sampleOrderID,
        status,
        custbody_google_analytics_client_id,
        paypalOrderId,
        paypalAuthorisationId,
        url,
        price,
      })
    );
    Logger.error(new Error(`Log for payment flow during step: ${step}`));
  } catch (e) {
    Logger.error(e);
  }
};

export default paymentFlowLogger;
