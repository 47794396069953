const gtmPromoCode = (event: string, promoCode?: string, isValid?: boolean): void => {
  if (event === "click") {
    return window.dataLayer?.push({ event: "promo_box_click" });
  } else if (event === "apply") {
    return window.dataLayer?.push({
      event: "promo_apply_click",
      promo_apply_click_text: promoCode,
    });
  } else if (event === "validate") {
    return window.dataLayer?.push({
      event: "promo_apply_validation",
      promo_apply_validation_result: isValid ? "success" : "error",
      promo_apply_validation_text: promoCode,
    });
  }
};

export default gtmPromoCode;
