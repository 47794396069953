import { SESSION_STORAGE_ORDER_KEY } from "./constants";
import {
  deleteSessionStorageItem,
  getSessionStorageItem,
  setSessionStorageItem,
} from "./sessionStorageHelper";

export const saveOrderDocumentToSessionStorage = (orderDocument: SavedOrderDocument) => {
  setSessionStorageItem(SESSION_STORAGE_ORDER_KEY, orderDocument);
};

export const deleteOrderDocumentFromSessionStorage = () => {
  deleteSessionStorageItem(SESSION_STORAGE_ORDER_KEY);
};

export const getOrderDocumentFromSessionStorage = () => {
  return getSessionStorageItem(SESSION_STORAGE_ORDER_KEY);
};
