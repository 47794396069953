import { useContext, useEffect } from "react";
import { useFormikContext } from "formik";
import { EMPTY_ORDER_DOCUMENT } from "@ukfd/constants";
import { UiStateContext } from "@context";
import { AddressFormContainerProps } from "./AddressFormContainer.types";

const AddressFormContainer: React.FC<AddressFormContainerProps> = ({ form: AddressForm }) => {
  const { values, setValues } = useFormikContext<FormikOrderDocument>();
  const {
    uiState: { isCopyingDeliveryAddress },
    setUiState,
  } = useContext(UiStateContext);

  useEffect(() => {
    if (!isCopyingDeliveryAddress) {
      return;
    }

    setValues({
      ...values,
      billingAddress: {
        ...values.deliveryAddress,
      },
    });
  }, [
    isCopyingDeliveryAddress,
    // Needed because of a conflicting useEffect hook that is watching
    // delivery address Id (AddressBook component)
    values.deliveryAddress.addressLine1,
    values.deliveryAddress.addressLine2,
    values.deliveryAddress.postcode,
    values.deliveryAddress.city,
    values.deliveryAddress.country,
    values.deliveryAddress.county,
  ]);

  if (!AddressForm) {
    return null;
  }

  const onAddressSelected = (address: AddressData) => {
    setValues({
      ...values,
      ...address,
    });
  };

  const onCopyAddressChanged = (isChecked: boolean) => {
    setUiState({ isCopyingDeliveryAddress: isChecked });
    setValues({
      ...values,
      billingAddress: {
        ...(isChecked ? values.deliveryAddress : EMPTY_ORDER_DOCUMENT.billingAddress),
      },
    });
  };

  return (
    <AddressForm
      isAddressBeingCopied={isCopyingDeliveryAddress}
      onAddressSelected={onAddressSelected}
      onCopyAddressChanged={onCopyAddressChanged}
      onSetPostCode={onAddressSelected}
    />
  );
};

export default AddressFormContainer;
