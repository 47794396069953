import { UPDATE_BASKET_MUTATION } from "../schema/basket";
import ukfdGraph from "../ukfdGraph";

export const addProductToBasket = ({
  basketId,
  sku,
  promocodesRequested,
  quantity,
  customerType,
  productType,
}: {
  basketId: string;
  sku: string;
  promocodesRequested: string[];
  quantity: number;
  customerType: CustomerType;
  productType: ProductType;
}) => {
  return ukfdGraph<AddToBasket, AddToBasketVariables>(UPDATE_BASKET_MUTATION, {
    id: basketId,
    sku,
    promocodesRequested,
    quantity,
    customerType,
    productType,
  });
};
