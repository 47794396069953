const formatAddressBookAddress = (address: Address): string => {
  const { firstLine, secondLine, city, postcode, country } = address || {};
  const newAddress: { [key: string]: string | undefined } = {
    firstLine,
    secondLine,
    city,
    postcode,
    country,
  };

  const filteredArray = Object.keys(newAddress)
    .filter((item) => newAddress[item])
    .map((item) => {
      if (newAddress[item] === "GB") {
        newAddress[item] = "United Kingdom";
      }
      return newAddress[item];
    });

  if (filteredArray.length > 1) {
    return filteredArray.join(", ");
  }

  return "";
};

export default formatAddressBookAddress;
