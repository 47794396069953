import { Children, FC, PropsWithChildren } from "react";
import clsx from "clsx";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";
import s from "./RelatedProductsCarousel.module.css";
import { RelatedProductsCarouselProps } from "./RelatedProductsCarousel.types";

const RelatedProductsCarousel: FC<PropsWithChildren<RelatedProductsCarouselProps>> = ({
  children,
  additionalClasses,
}) => {
  return (
    <Splide
      className={clsx(s.root, additionalClasses)}
      data-testid="carousel"
      hasTrack={false}
      options={{
        rewind: true,
      }}
    >
      <SplideTrack>
        {Children.map(children, (child) => (
          <SplideSlide>{child}</SplideSlide>
        ))}
      </SplideTrack>
    </Splide>
  );
};

export default RelatedProductsCarousel;
