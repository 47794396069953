import Cookies from "js-cookie";
import { UKFD_COOKIES } from "./constants";

export const getPromoCodes = (promo?: Promo): string[] => {
  if (!promo) {
    return [];
  }

  return promo?.summary?.map(({ code }) => code!) || [];
};

export const savePromoCodesToCookie = (promoCodes: string[] = []) => {
  Cookies.set(UKFD_COOKIES.PROMO_CODES, promoCodes.join(",") || "", { expires: 7 });
};
