import { UPDATE_BASKET_MUTATION } from "../schema/basket";
import ukfdGraph from "../ukfdGraph";

export const updatePackQuantity = (
  basketId: string,
  product: Product,
  quantity: number,
  promocodesRequested: string[],
  customerType: string
) => {
  return ukfdGraph(UPDATE_BASKET_MUTATION, {
    id: basketId,
    basketItemId: product.basketItemId,
    sku: product.sku,
    quantity: Number(quantity),
    productType: product.details?.productType,
    promocodesRequested,
    customerType,
  });
};
