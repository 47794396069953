import { DELETE_BASKET_ITEM_MUTATION } from "../schema/basket";
import ukfdGraph from "../ukfdGraph";

export const removeBasketProduct = (
  basketId: string,
  product: Product,
  promocodesRequested: string[],
  customerType: string
) => {
  return ukfdGraph(DELETE_BASKET_ITEM_MUTATION, {
    id: basketId,
    basketItemId: product.basketItemId,
    sku: product.sku,
    promocodesRequested,
    customerType,
  });
};
