import { useContext, useEffect } from "react";
import { useFormikContext } from "formik";
import { RadioProps } from "libs/ui/src/components/Radio/Radio.types";
import sortBy from "lodash/sortBy";
import { formatAddressBookAddress } from "@ukfd/checkout-utils";
import { Button, RadioGroup, Text } from "@ukfd/ui";
import { CheckoutContext, UiStateContext } from "@context";
import s from "./AddressBook.module.css";
import { AddressBookProps } from "./AddressBook.types";

const AddressBook: React.FC<AddressBookProps> = () => {
  const { values, setValues } = useFormikContext<FormikOrderDocument>();

  const { customer } = useContext(CheckoutContext);
  const { setUiState } = useContext(UiStateContext);

  const addressOptions: RadioProps[] = sortBy(
    customer.deliveryAddress,
    (address) => address.isDefaultShipping !== "true"
  ).map((address: Address) => {
    return {
      name: "deliveryAddress.id",
      label: formatAddressBookAddress(address),
      value: address.id,
    };
  });

  useEffect(() => {
    const foundAddress =
      customer.deliveryAddress.find((item) => item.id === values.deliveryAddress.id) ||
      customer.deliveryAddress[0];
    setValues({
      ...values,
      deliveryAddress: {
        id: foundAddress.id,
        addressLine1: foundAddress.firstLine,
        addressLine2: foundAddress.secondLine,
        postcode: foundAddress.postcode,
        city: foundAddress.city,
        country: foundAddress.country,
        county: foundAddress.state,
      },
    });
  }, [values?.deliveryAddress?.id]);

  return (
    <>
      <Text id="delivery-address" variant="sectionHeading">
        Delivery Address
      </Text>
      <div className={s.root}>
        <RadioGroup className={s.radio} options={addressOptions} />
      </div>
      <Button
        className={s.minimal}
        variant="minimal"
        onClick={() => setUiState({ deliveryAddressMode: "MANUAL" })}
      >
        Use a different address
      </Button>
    </>
  );
};

export default AddressBook;
