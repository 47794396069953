export const getRecommendedAccessories = (): RecommendedAccessories => {
  return {
    "ENG-BAR-PU-009": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-PEARL",
      "ACC-SO-TP-Pearl",
    ],
    "ENG-BAR-PU-010": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-CTY-14-003": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-FL-14-014": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-FL-14-018": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-FL-14-033": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-FL-14-034": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-FL-14-036": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-FL-14-037-5GS": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Espresso180",
      "ACC-SO-TP-Espresso180",
    ],
    "ENG-FL-14-040": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Antique",
      "ACC-SO-TP-Antique",
    ],
    "ENG-FL-14-042": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
      "ACC-MA-WO-005",
    ],
    "ENG-FL-14-044": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Espresso3",
      "ACC-SO-TP-Espresso3",
    ],
    "ENG-FL-14-046": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Espresso180",
      "ACC-SO-TP-Espresso180",
    ],
    "ENG-FL-14-047": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Pearl",
      "ACC-SO-TP-Pearl",
    ],
    "ENG-FL-14-050": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
      "ACC-MA-WO-005",
    ],
    "ENG-FL-14-051": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Pearl",
      "ACC-SO-TP-Pearl",
    ],
    "ENG-GA-14-112": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GA-14-190": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Espresso180",
    ],
    "ENG-GA-14-539": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Pearl",
    ],
    "ENG-GA-14-544": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Espresso180",
    ],
    "ENG-GA-15-126": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GA-15-127": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GA-15-129": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Pearl",
    ],
    "ENG-GA-15-132": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Wwash",
    ],
    "ENG-GA-15-133": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Antique",
    ],
    "ENG-GA-15-506": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GA-18-507": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GA-18-508": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GA-20-523": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GA-20-570": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Espresso3",
    ],
    "ENG-GA-20-571": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-PR-SC-001",
    ],
    "ENG-GA-20-572": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-PR-SC-001",
    ],
    "ENG-GA-20-580": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GA-20-581": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-PR-SC-001",
    ],
    "ENG-GA-20-582": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Espresso3",
    ],
    "ENG-GA-20-626": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-PR-SC-001",
    ],
    "ENG-GAL-001": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GAL-002": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GAL-100": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Pearl",
    ],
    "ENG-GAL-101": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-WFumed",
    ],
    "ENG-GAL-102": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Espresso3",
    ],
    "ENG-GAL-103": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Wwash",
    ],
    "ENG-GAL-104": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Antique",
    ],
    "ENG-GAL-105": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GAL-106": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Espresso3",
    ],
    "ENG-GAL-107": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GAL-113": ["ACC-LJACK65014", "ACC-SO-SC-Oak", "ACC-SO-TP2-Oak"],
    "ENG-GP-20-506": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-HC-003": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Espresso3",
      "ACC-SO-TP-Espresso3",
    ],
    "ENG-HC-14-003": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-HC-14-004": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-HC-14-007": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Fumed",
    ],
    "ENG-HC-14-008": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-HC-14-027": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Pearl",
      "ACC-SO-TP-Pearl",
    ],
    "ENG-HC-14-028": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Pearl",
      "ACC-SO-TP-Pearl",
    ],
    "ENG-HC-14-029": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-034": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-HC-14-038": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-039": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-041": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-042": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-043": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-044": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-045": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-046": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-047": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-048": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-049": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-050": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-051": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Antique",
      "ACC-SO-TP-Antique",
    ],
    "ENG-HC-14-052": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-053": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-054": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-056": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-058": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Espresso3",
      "ACC-SO-TP-Espresso3",
    ],
    "ENG-HC-14-059": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
      "ACC-MA-WO-005",
    ],
    "ENG-HC-14-060": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-HC-14-062": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-063": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-064": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-065": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-HC-14-067": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-069": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Antique",
      "ACC-SO-TP-Antique",
    ],
    "ENG-HC-14-070": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Fumed",
    ],
    "ENG-HC-14-071": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-072": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-HC-14-073": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-077": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-HC-14-078": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-079": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Antique",
      "ACC-SO-TP-Antique",
    ],
    "ENG-HC-14-081": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-082": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-083": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-091": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-092": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-301": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-HC-14-302": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
      "ACC-MA-WO-005",
    ],
    "ENG-HC-14-303": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
      "ACC-MA-WO-005",
    ],
    "ENG-HC-14-304": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
      "ACC-MA-WO-005",
    ],
    "ENG-HC-14-305": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
      "ACC-MA-WO-005",
    ],
    "ENG-HCB-14-001": ["ACC-LJACK65014", "ACC-SO-SC-Oak", "ACC-SO-TP2-Oak"],
    "ENG-HCB-14-002": ["ACC-LJACK65014", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "ENG-HCB-14-003": ["ACC-LJACK65014", "ACC-SO-SC-Pearl", "ACC-SO-TP-Pearl"],
    "ENG-HCB-14-004": ["ACC-LJACK65014", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "ENG-HCB-14-005": ["ACC-LJACK65014", "ACC-PR-SC-001"],
    "ENG-HCB-14-006": ["ACC-LJACK65014", "ACC-SO-SC-Oak", "ACC-SO-TP2-Oak"],
    "ENG-HCB-14-007": ["ACC-LJACK65014", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "ENG-HCB-14-308": ["ACC-LJACK65014", "ACC-SO-SC-Oak", "ACC-SO-TP2-Oak"],
    "ENG-HCB-14-309": ["ACC-LJACK65014", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "ENG-HCB-14-310": ["ACC-LJACK65014", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "ENG-MD-14-534": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-PR-SC-001",
    ],
    "ENG-MD-14-535": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-PR-SC-001",
    ],
    "ENG-MD-14-536": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-PR-SC-001",
    ],
    "ENG-MD-14-537": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-PR-SC-001",
    ],
    "ENG-MD-14-680": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-MD-14-681": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-MD-14-682": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-MD-14-683": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-MD-14-684": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Pearl",
      "ACC-SO-TP-Pearl",
    ],
    "ENG-MD-14-685": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-MD-14-686": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-MD-14-687": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Antique",
      "ACC-SO-TP-Antique",
    ],
    "ENG-MD-14-688": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Espresso180",
      "ACC-SO-SC-Espresso180",
    ],
    "ENG-MD-14-689": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Espresso3",
      "ACC-SO-TP-Espresso3",
    ],
    "ENG-BAR-TA-006": ["ACC-UN-NO-005", "ACC-D41", "ACC-SO-SC-Ash"],
    "ENG-CTY-14-007": ["ACC-UN-NO-005", "ACC-SO-SC-Pearl", "ACC-SO-TP-Pearl"],
    "ENG-GA-14-122": ["ACC-UN-NO-005", "ACC-SO-SC-Oak", "ACC-SO-TP2-Oak"],
    "ENG-GA-14-500": ["ACC-UN-NO-005", "ACC-D41", "ACC-SO-SC-Antique"],
    "ENG-GA-14-501": ["ACC-UN-NO-005", "ACC-D41", "ACC-SO-SC-Oak"],
    "ENG-GA-14-533": ["ACC-UN-NO-005", "ACC-D41", "ACC-SO-SC-Oak"],
    "ENG-GA-20-583": ["ACC-UN-NO-005", "ACC-D41", "ACC-SO-SC-Oak"],
    "ENG-HC-14-120": ["ACC-UN-NO-005", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "LAM-AL-12-332": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC69",
      "ACC-PR-FL-DP69",
    ],
    "LAM-AL-12-333": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC54",
      "ACC-PR-FL-DP54",
    ],
    "LAM-AL-12-334": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC45",
      "ACC-PR-FL-DP45",
    ],
    "LAM-AL-12-335": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC54",
      "ACC-PR-FL-DP45",
    ],
    "LAM-AL-12-336": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC47",
      "ACC-PR-FL-DP47",
    ],
    "LAM-AL-8-320": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC59",
      "ACC-PR-FL-DP59",
    ],
    "LAM-AL-8-322": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC45",
      "ACC-PR-FL-DP45",
    ],
    "LAM-AL-8-323": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC47",
      "ACC-PR-FL-DP47",
    ],
    "LAM-AL-8-324": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC54",
      "ACC-PR-FL-DP54",
    ],
    "LAM-AU-12-300": ["ACC-PR-FL-SC57", "ACC-UN-DP-001", "ACC-PR-FL-DP57"],
    "LAM-AU-12-303": ["ACC-PR-FL-SC59", "ACC-UN-DP-001", "ACC-PR-FL-DP59"],
    "LAM-AU-12-304": ["ACC-PR-FL-SC69", "ACC-UN-DP-001", "ACC-PR-FL-DP69"],
    "LAM-AU-12-305": ["ACC-UN-DP-001", "ACC-PR-SC-001"],
    "LAM-AU-12-306": ["ACC-PR-FL-SC47", "ACC-PR-FL-DP47", "ACC-UN-DP-001"],
    "LAM-AU-12-307": ["ACC-PR-FL-SC45", "ACC-PR-FL-DP45", "ACC-UN-DP-001"],
    "LAM-AU-12-308": ["ACC-PR-FL-SC56", "ACC-UN-DP-001", "ACC-PR-FL-DP56"],
    "LAM-AU-12-309": ["ACC-UN-DP-001", "ACC-PR-FL-SC69", "ACC-PR-FL-DP69"],
    "LAM-AU-12-310": ["ACC-PR-FL-SC33", "ACC-PR-FL-DP33", "ACC-UN-DP-001"],
    "LAM-AU-12-311": ["ACC-UN-DP-001", "ACC-PR-FL-SC54", "ACC-PR-FL-DP54"],
    "LAM-FL-10-020": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC59",
      "ACC-PR-FL-DP59",
    ],
    "LAM-MD-10-395": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC54",
      "ACC-PR-FL-DP54",
    ],
    "LAM-MD-7-390": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC54",
      "ACC-PR-FL-DP54",
    ],
    "LAM-SE-10-006": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC41",
      "ACC-PR-FL-DP41",
    ],
    "LAM-SE-10-017": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC70",
      "ACC-PR-FL-DP70",
    ],
    "LAM-SE-10-019": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC54",
      "ACC-PR-FL-DP54",
    ],
    "LAM-SE-10-020": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC45",
      "ACC-PR-FL-DP45",
    ],
    "LAM-SE-10-023": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC69",
      "ACC-PR-FL-DP69",
    ],
    "LAM-SE-10-325": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC70",
      "ACC-PR-FL-DP70",
    ],
    "LAM-SE-12-001": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC70",
      "ACC-PR-FL-DP70",
    ],
    "LAM-SE-12-002": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-SE-12-004": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC33",
      "ACC-PR-FL-DP33",
    ],
    "LAM-SE-12-005": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC57",
      "ACC-PR-FL-DP57",
    ],
    "LAM-SE-12-017": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC49",
      "ACC-PR-FL-DP49",
    ],
    "LAM-SE-12-019": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SE-SC13",
      "ACC-PR-FL-DP13",
    ],
    "LAM-SE-12-022": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC56",
      "ACC-PR-FL-DP56",
    ],
    "LAM-SE-12-023": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC59",
      "ACC-PR-FL-DP59",
    ],
    "LAM-SE-12-024": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-DP25",
    ],
    "LAM-SE-12-025": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC69",
      "ACC-PR-FL-DP69",
    ],
    "LAM-SE-12-026": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC59",
      "ACC-PR-FL-DP59",
    ],
    "LAM-SE-12-027": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC54",
      "ACC-PR-FL-DP54",
    ],
    "LAM-SE-12-313": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC45",
      "ACC-PR-FL-DP45",
    ],
    "LAM-SE-12-343": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC47",
      "ACC-PR-FL-DP47",
    ],
    "LAM-SE-12-351": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-SE-12-344": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC45",
      "ACC-PR-FL-DP45",
    ],
    "LAM-SE-12-345": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SE-SC13",
      "ACC-PR-FL-DP13",
    ],
    "LAM-SE-12-352": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-SE-12-347": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC49",
      "ACC-PR-FL-DP49",
    ],
    "LAM-SE-12-354": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC49",
      "ACC-PR-FL-DP49",
    ],
    "LAM-SE-12-348": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC70",
      "ACC-PR-FL-DP70",
    ],
    "LAM-SE-12-353": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC70",
      "ACC-PR-FL-DP70",
    ],
    "LAM-SE-7-009": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-SE-7-013": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-SE-7-352": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-SE-8-001": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC70",
      "ACC-PR-FL-DP70",
    ],
    "LAM-SE-8-002": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC54",
      "ACC-PR-FL-DP54",
    ],
    "LAM-SE-8-003": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC45",
      "ACC-PR-FL-DP45",
    ],
    "LAM-SE-8-009": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC59",
      "ACC-PR-FL-DP59",
    ],
    "LAM-SE-8-010": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC54",
      "ACC-PR-FL-DP54",
    ],
    "LAM-SE-8-011": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC45",
      "ACC-PR-FL-DP45",
    ],
    "LAM-SE-8-012": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC69",
      "ACC-PR-FL-DP69",
    ],
    "LAM-SE-8-013": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC69",
      "ACC-PR-FL-DP69",
    ],
    "LAM-SE-8-014": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC59",
      "ACC-PR-FL-DP59",
    ],
    "LAM-SE-8-015": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC57",
      "ACC-PR-FL-DP57",
    ],
    "LAM-SE-8-018": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC56",
      "ACC-PR-FL-DP56",
    ],
    "LAM-SE-8-362": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-PR-FL-SC47",
    ],
    "LAM-SE-8-363": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SE-SC13",
      "ACC-PR-FL-SC13",
    ],
    "LAM-SE-8-364": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SE-SC13",
      "ACC-PR-FL-DP13",
    ],
    "LAM-SE-8-365": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC70",
      "ACC-PR-FL-DP70",
    ],
    "LAM-SE-8V-014": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC56",
      "ACC-PR-FL-DP56",
    ],
    "LAM-SE-8V-015": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC70",
      "ACC-PR-FL-DP70",
    ],
    "LAM-SE-8V-016": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SE-SC13",
      "ACC-PR-FL-DP13",
    ],
    "LAM-SE-8V-017": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC47",
      "ACC-PR-FL-DP47",
    ],
    "LAM-VA-14-001": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC59",
      "ACC-PR-FL-DP59",
    ],
    "LAM-VA-14-003": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC70",
      "ACC-PR-FL-DP70",
    ],
    "LAM-WM-12-017": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC54",
      "ACC-PR-FL-DP54",
    ],
    "LAM-WM-12-018": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
      "ACC-MA-WO-005",
    ],
    "LAM-WM-12-021": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SE-SC13",
      "ACC-PR-FL-DP13",
    ],
    "SOL-GA-18-567": ["ACC-LJACK65014", "ACC-SO-SC-Oak", "ACC-SO-TP2-Oak"],
    "SOL-GA-18-568": ["ACC-LJACK65014", "ACC-SO-SC-Oak", "ACC-SO-TP2-Oak"],
    "SOL-GA-18-569": ["ACC-LJACK65014", "ACC-SO-SC-Wwash", "ACC-SO-TP-Wwash"],
    "SOL-GA-18-570": ["ACC-LJACK65014", "ACC-SO-SC-Espresso3", "ACC-SO-TP-Espresso3"],
    "SOL-GA-18-571": ["ACC-LJACK65014", "ACC-SO-SC-Espresso180", "ACC-SO-TP-Espresso180"],
    "SOL-GA-20-208": ["ACC-LJACK65014", "ACC-SO-SC-Oak", "ACC-SO-TP2-Oak"],
    "SOL-GA-20-209": ["ACC-LJACK65014", "ACC-SO-SC-Wwash", "ACC-SO-TP-Wwash"],
    "SOL-GA-20-210": ["ACC-LJACK65014", "ACC-SO-SC-Wwash", "ACC-SO-TP-Wwash"],
    "SOL-GA-20-213": ["ACC-LJACK65014", "ACC-PR-SC-001"],
    "SOL-GA-20-215": ["ACC-LJACK65014", "ACC-SO-SC-Fumed"],
    "VIN-CLIC-PL-400": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-401": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-412": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-413": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-414": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-415": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-416": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-417": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-418": ["ACC-PR-FL-SC33"],
    "VIN-CLIC-PL-419": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-420": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-421": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-422": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-423": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-425": ["ACC-PR-FL-SC33"],
    "VIN-CLIC-PL-426": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-427": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-428": ["ACC-PR-FL-SC57"],
    "VIN-CLIC-PL-429": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-430": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-431": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-432": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-434": ["ACC-PR-FL-SC70"],
    "VIN-CLIC-PL-436": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-440": ["ACC-UN-NO-012", "ACC-PR-FL-SC69"],
    "VIN-CLIC-PL-441": ["ACC-UN-NO-012", "ACC-PR-FL-SC56", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-445": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-446": ["ACC-PR-FL-SC45"],
    "VIN-CLIC-PL-447": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-448": ["ACC-PR-FL-SC49"],
    "VIN-CLIC-PL-455": ["ACC-PR-FL-SC56"],
    "VIN-CLIC-PL-456": ["ACC-PR-FL-SC70"],
    "VIN-CLIC-PL-457": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-458": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-459": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-460": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-461": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-469": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-470": ["ACC-PR-FL-SC69"],
    "VIN-CLIC-PL-471": ["ACC-UN-NO-012", "ACC-PR-FL-SC49", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-472": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-473": ["ACC-PR-FL-SC70"],
    "VIN-CLIC-PL-474": ["ACC-PR-FL-SC45"],
    "VIN-CLIC-PL-475": ["ACC-PR-FL-SC47"],
    "VIN-CLIC-PL-476": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-800": ["ACC-PR-FL-SC59"],
    "VIN-CLIC-PL-801": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-802": ["ACC-PR-FL-SC69"],
    "VIN-CLIC-PL-803": ["ACC-PR-FL-SC45"],
    "VIN-CLIC-PL-804": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-854": ["ACC-UN-NO-012", "ACC-PR-SC-001"],
    "VIN-CLIC-PL-855": ["ACC-UN-NO-012", "ACC-PR-FL-SC69"],
    "VIN-CLIC-PL-856": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-857": ["ACC-UN-NO-012", "ACC-PR-FL-SC47", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-858": ["ACC-UN-NO-012", "ACC-PR-FL-SC69"],
    "VIN-CLIC-PL-859": ["ACC-UN-NO-012", "ACC-PR-FL-SC70", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-863": ["ACC-UN-NO-012", "ACC-PR-FL-SC69", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-864": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-TL-350": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-TL-351": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-TL-352": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-TL-442": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-443": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-444": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-449": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-450": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-451": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-452": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-453": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-454": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-462": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-463": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-TL-464": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-465": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-466": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-467": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-468": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-850": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-TL-851": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-TL-852": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-853": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-860": ["ACC-UN-NO-012", "ACC-PR-SC-001"],
    "VIN-CLIC-TL-861": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-TL-862": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-DRY-PL-477": ["ACC-IN-AD-001", "ACC-PR-FL-SC45"],
    "VIN-DRY-PL-478": ["ACC-IN-AD-001", "ACC-PR-FL-SC49"],
    "VIN-DRY-PL-479": ["ACC-IN-AD-001", "ACC-PR-FL-SC47"],
    "VIN-DRY-PL-480": ["ACC-IN-AD-001", "ACC-PR-FL-SC70"],
    "VIN-DRY-PL-481": ["ACC-IN-AD-001", "ACC-PR-FL-SC70"],
    "VIN-DRY-PL-485": ["ACC-IN-AD-001", "ACC-PR-FL-SC49"],
    "VIN-DRY-PL-488": ["ACC-IN-AD-001", "ACC-PR-FL-SC69"],
    "VIN-DRY-PL-491": ["ACC-IN-AD-001", "ACC-PR-SC-001"],
    "VIN-DRY-TL-482": ["ACC-IN-AD-001", "ACC-PR-SC-001"],
    "VIN-DRY-TL-483": ["ACC-IN-AD-001", "ACC-PR-SC-001"],
    "VIN-DRY-TL-484": ["ACC-IN-AD-001", "ACC-PR-SC-001"],
    "VIN-DRY-TL-486": ["ACC-IN-AD-001", "ACC-PR-SC-001"],
    "VIN-DRY-TL-487": ["ACC-IN-AD-001", "ACC-PR-SC-001"],
    "VIN-DRY-TL-489": ["ACC-IN-AD-001", "ACC-PR-SC-001"],
    "VIN-DRY-HB-001": ["ACC-IN-AD-001", "ACC-PR-SC-001"],
    "VIN-DRY-HB-003": ["ACC-IN-AD-001", "ACC-PR-SC-001"],
    "VIN-DRY-PL-508": ["ACC-IN-AD-001", "ACC-PR-SC-001"],
    "VIN-DRY-PL-509": ["ACC-IN-AD-001", "ACC-PR-SC-001"],
    "VIN-DRY-PL-510": ["ACC-IN-AD-001", "ACC-PR-SC-001"],
    "VIN-DRY-TL-511": ["ACC-IN-AD-001", "ACC-PR-SC-001"],
    "VIN-DRY-TL-512": ["ACC-IN-AD-001", "ACC-PR-SC-001"],
    "VIN-DRY-TL-513": ["ACC-IN-AD-001", "ACC-PR-SC-001"],
    "VIN-DRY-TL-516": ["ACC-IN-AD-001", "ACC-PR-SC-001"],
    "VIN-CLIC-PL-600": ["ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-601": ["ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-602": ["ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-603": ["ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-604": ["ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-605": ["ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-606": ["ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-607": ["ACC-PR-SC-001", "ACC-MA-WO-005"],
    "LAM-SE-10-030": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC59",
      "ACC-PR-FL-DP59",
    ],
    "LAM-SE-10-031": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC70",
      "ACC-PR-FL-DP69",
    ],
    "LAM-SE-10-032": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC47",
      "ACC-PR-FL-DP47",
    ],
    "LAM-SE-12-030": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-SE-12-031": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC54",
      "ACC-PR-FL-DP54",
    ],
    "LAM-SE-12-032": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC59",
      "ACC-PR-FL-DP59",
    ],
    "LAM-SE-12-033": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC41",
      "ACC-PR-FL-DP41",
    ],
    "LAM-SE-12-034": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-SE-12-035": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-SE-8V-025": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SE-SC13",
      "ACC-PR-FL-DP13",
    ],
    "LAM-SE-8V-026": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC70",
      "ACC-PR-FL-DP70",
    ],
    "LAM-TL-8-001": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
      "ACC-PR-DP-002",
    ],
    "LAM-TL-8-002": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
      "ACC-PR-DP-002",
    ],
    "LAM-TL-8-003": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
      "ACC-PR-DP-002",
    ],
    "LAM-TL-8-004": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
      "ACC-PR-DP-002",
    ],
    "LAM-TL-8-005": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
      "ACC-PR-DP-002",
    ],
    "LAM-TL-8-006": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
      "ACC-PR-DP-002",
    ],
    "VIN-CLIC-PL-825": ["ACC-PR-SC-001", "ACC-IN-RO-003"],
    "VIN-CLIC-PL-826": ["ACC-PR-SC-001", "ACC-IN-RO-003"],
    "VIN-CLIC-PL-828": ["ACC-PR-SC-001", "ACC-IN-RO-003"],
    "VIN-CLIC-PL-829": ["ACC-PR-SC-001", "ACC-IN-RO-003"],
    "VIN-CLIC-PL-830": ["ACC-PR-SC-001", "ACC-IN-RO-003"],
    "VIN-CLIC-PL-832": ["ACC-PR-SC-001", "ACC-IN-RO-003"],
    "VIN-CLIC-PL-833": ["ACC-PR-SC-001", "ACC-IN-RO-003"],
    "VIN-CLIC-PL-834": ["ACC-PR-SC-001", "ACC-IN-RO-003"],
    "LAM-SEC-8-001": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC15",
      "ACC-PR-FL-DP15",
    ],
    "LAM-SEHB-8-001": ["ACC-UN-NO-012", "ACC-D41", "ACC-PR-SE-SC13"],
    "VIN-CLIC-PL-827": ["ACC-PR-SC-001", "ACC-IN-RO-003"],
    "VIN-CLIC-PL-831": ["ACC-PR-SC-001", "ACC-IN-RO-003"],
    "CAR-ADE-BOT": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-ADE-CRI": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-ADE-LAN": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-ADE-ROC": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-ADE-SUM": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-ADE-VIB": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-ADE-VIN": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-BRI-BEA": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-BRI-CON": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-BRI-DOM": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-BRI-PAV": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-BRI-PEB": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-BRI-WHE": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-CAM-CAM": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAM-CHA": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAM-CHR": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAM-COL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAM-GRA": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAM-GRV": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAM-MIS": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAM-PRE": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAM-STO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAM-TRI": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAM-TRO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAM-UND": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAR-BAY": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-CAR-CAH": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-CAR-CAS": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-CAR-DRA": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-CAR-ROS": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-CAR-SLA": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-CAR-TRE": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-DUB-BUL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-DUB-DRE": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-DUB-FER": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-DUB-GUI": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-DUB-RES": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-DUB-SHO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-DUB-TEM": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-DUB-WIS": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-EDI-MEM": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-EDI-ROY": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-EDI-SAN": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-EDI-SHO": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-EDI-STE": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-EDI-WHI": ["ACC-UN-CAR08-001", "ACC-CCA-001", "ACC-GR1502-001"],
    "CAR-MAD-AMA": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MAD-BOU": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MAD-EUR": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MAD-FAS": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MAD-OSC": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MAD-PAL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MAD-PEN": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MAD-PLA": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MAD-REG": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MAD-SUN": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MAD-TRE": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MAD-VIL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MON-ALP": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MON-ARC": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MON-CHO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MON-CIR": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MON-GOL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MON-HER": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MON-IMP": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MON-JAZ": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MON-MAP": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MON-MET": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MON-PRO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MON-URB": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OXF-CAM": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OXF-CHA": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OXF-CHR": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OXF-COL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OXF-GRA": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OXF-GRV": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OXF-MIS": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OXF-NOE": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OXF-PRE": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OXF-SPI": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OXF-STO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OXF-TRI": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OXF-TRO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OXF-UND": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OXF-BRO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-WAS-CAP": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-WAS-COS": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-WAS-DIM": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-WAS-GOL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-WAS-GRA": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-WAS-LIB": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-WAS-LUM": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-WAS-MON": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-WAS-OLY": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-WAS-PAR": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-WAS-SKY": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-WAS-SPO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "VIN-AQU-CHA": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-AQU-DAW": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-AQU-DEL": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-AQU-EQU": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-AQU-FOR": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-AQU-GAZ": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-AQU-LUC": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-AQU-MET": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-AQU-VER": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-AQU-YOU": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-COL-BOH": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-COL-CHE": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-COL-TRE": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-NOV-FUS": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-NOV-IGN": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-NOV-NEB": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-NOV-REM": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-NOV-SKY": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-NOV-STA": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-ORI-BEL": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-ORI-BRI": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-ORI-BRO": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-ORI-GAM": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-ORI-HOR": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-ORI-ODY": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-ORI-POI": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-ORI-REG": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-ORI-SHE": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-PEG-BET": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-PEG-CAV": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-PEG-CLO": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-PEG-FLI": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-PEG-GAL": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-PEG-LUM": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-PEG-LUN": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-PEG-MAN": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-PEG-MID": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "VIN-PEG-NIM": ["ACC-VA-001", "ACC-PR-DP-001", "ACC-PR-DP-002"],
    "ENG-GA-20-531": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GA-15-147": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GA-15-148": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-SO-TP2-Oak",
        greaterThanThreshold: "ACC-SO-TP2-Oak",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GA-15-149": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-SO-TP2-Oak",
        greaterThanThreshold: "ACC-SO-TP2-Oak",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "SOL-GA-18-573": ["ACC-LJACK65014", "ACC-SO-SC-Oak", "ACC-SO-TP2-Oak"],
    "SOL-GA-18-574": ["ACC-LJACK65014", "ACC-SO-SC-Oak", "ACC-SO-TP2-Oak"],
    "SOL-GA-18-575": ["ACC-LJACK65014", "ACC-SO-SC-Oak", "ACC-SO-TP2-Oak"],
    "VIN-CLIC-PL-805": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-806": ["ACC-PR-FL-SC59"],
    "VIN-CLIC-PL-807": ["ACC-PR-FL-SC56"],
    "VIN-CLIC-PL-808": ["ACC-PR-SE-SC13"],
    "VIN-CLIC-PL-809": ["ACC-PR-FL-SC45"],
    "VIN-CLIC-PL-810": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-811": ["ACC-PR-FL-SC49"],
    "VIN-CLIC-PL-812": ["ACC-PR-FL-SC47"],
    "VIN-CLIC-PL-813": ["ACC-UN-NO-012", "ACC-PR-FL-SC45"],
    "VIN-CLIC-PL-814": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-815": ["ACC-PR-FL-SC47"],
    "VIN-CLIC-TL-816": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-817": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-818": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-819": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-820": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-821": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-822": ["ACC-UN-NO-012", "ACC-PR-SC-001"],
    "VIN-CLIC-TL-823": ["ACC-PR-SC-001"],
    "VIN-CLIC-TL-824": ["ACC-PR-SC-001"],
    "ENG-GAHB-20-001": ["ACC-LJACK65014", "ACC-SO-SC-Oak", "ACC-SO-TP2-Oak"],
    "ENG-GAHB-20-002": ["ACC-LJACK65014", "ACC-SO-SC-Oak", "ACC-SO-TP2-Oak"],
    "ENG-GAHB-20-003": ["ACC-LJACK65014", "ACC-SO-SC-Oak", "ACC-SO-TP2-Oak"],
    "LAM-SE-8-030": ["ACC-UN-NO-005", "ACC-PR-FL-DP25"],
    "LAM-SE-8-031": ["ACC-UN-NO-005", "ACC-PR-FL-SC69", "ACC-PR-FL-DP69"],
    "LAM-SE-8-032": ["ACC-UN-NO-005", "ACC-PR-FL-SC41", "ACC-PR-FL-DP41"],
    "LAM-SE-8-033": ["ACC-UN-NO-005", "ACC-PR-SE-SC13", "ACC-PR-FL-DP13"],
    "VIN-CLIC-PL-865": ["ACC-UN-NO-012", "ACC-PR-SC-001"],
    "VIN-CLIC-PL-866": ["ACC-UN-NO-012", "ACC-PR-FL-SC41", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-867": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-868": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-869": ["ACC-UN-NO-012", "ACC-PR-FL-SC45", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-870": ["ACC-UN-NO-012", "ACC-PR-FL-SC41", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-871": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-872": ["ACC-UN-NO-012", "ACC-PR-SC-001"],
    "VIN-CLIC-PL-873": ["ACC-PR-SC-001"],
    "VIN-CLIC-PL-874": ["ACC-UN-NO-012", "ACC-PR-FL-SC70", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-875": ["ACC-PR-SC-001", "ACC-IN-RO-003"],
    "VIN-CLIC-PL-876": ["ACC-PR-FL-SC47", "ACC-IN-RO-003"],
    "VIN-CLIC-PL-877": ["ACC-PR-SC-001", "ACC-IN-RO-003"],
    "VIN-CLIC-PL-878": ["ACC-PR-FL-SC54", "ACC-IN-RO-003"],
    "VIN-CLIC-PL-879": ["ACC-PR-SC-001", "ACC-IN-RO-003"],
    "VIN-CLIC-PL-500": ["ACC-UN-NO-012", "ACC-PR-SC-001"],
    "VIN-CLIC-PL-501": ["ACC-UN-NO-012", "ACC-PR-SC-001"],
    "VIN-CLIC-PL-514": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-515": ["ACC-UN-NO-012", "ACC-PR-SC-001"],
    "VIN-CLIC-PL-517": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-518": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-519": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-520": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-521": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-522": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-523": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-524": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-525": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-PL-526": ["ACC-UN-NO-012", "ACC-PR-SC-001"],
    "VIN-CLIC-PL-527": ["ACC-UN-NO-012", "ACC-PR-SC-001"],
    "VIN-CLIC-PL-528": ["ACC-UN-NO-012", "ACC-PR-SC-001"],
    "VIN-CLIC-PL-529": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-TL-502": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-TL-503": ["ACC-UN-NO-012", "ACC-PR-SC-001"],
    "VIN-CLIC-TL-504": ["ACC-UN-NO-012", "ACC-PR-SC-001"],
    "VIN-CLIC-TL-505": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-TL-506": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-TL-863": ["ACC-UN-NO-012", "ACC-PR-SC-001", "ACC-MA-WO-005"],
    "VIN-CLIC-TL-864": ["ACC-UN-NO-012", "ACC-PR-SC-001"],
    "LAM-AL-10-001": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-AL-10-003": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC47",
      "ACC-PR-FL-DP47",
    ],
    "LAM-AL-10-004": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC49",
      "ACC-PR-FL-DP49",
    ],
    "LAM-AL-10-005": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC13",
      "ACC-PR-FL-DP13",
    ],
    "LAM-AL-10-006": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
      "ACC-MA-WO-005",
    ],
    "LAM-AL-10-007": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC59",
      "ACC-PR-FL-DP59",
    ],
    "LAM-AL-8-001": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC56",
      "ACC-PR-FL-DP56",
    ],
    "LAM-AL-8-002": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC13",
      "ACC-PR-FL-DP13",
    ],
    "LAM-AL-8-003": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC54",
      "ACC-PR-FL-DP54",
    ],
    "LAM-AUTL-12-313": ["ACC-UN-DP-001", "ACC-PR-SC-001"],
    "LAM-AUTL-12-314": ["ACC-UN-DP-001", "ACC-PR-SC-001"],
    "LAM-AUTL-12-315": ["ACC-UN-DP-001", "ACC-PR-SC-001"],
    "LAM-AUTL-12-316": ["ACC-UN-DP-001", "ACC-PR-SC-001"],
    "CAR-OSL-VOY": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OSL-NOS": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OSL-SEA": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OSL-EXP": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OSL-FJO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OSL-COM": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OSL-FRO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OSL-SKO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OSL-WOL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OSL-BAL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OSL-THO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OSL-POL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OSL-AMB": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OSL-WIN": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OSL-TIG": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-OSL-NOR": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "VIN-COL-GRE": ["ACC-VA-001", "ACC-PR-DP-002", "ACC-PR-DP-001"],
    "VIN-COL-BLU": ["ACC-VA-001", "ACC-PR-DP-002", "ACC-PR-DP-001"],
    "VIN-COL-GRP": ["ACC-VA-001", "ACC-PR-DP-002", "ACC-PR-DP-001"],
    "VIN-COL-COL": ["ACC-VA-001", "ACC-PR-DP-002", "ACC-PR-DP-001"],
    "VIN-COL-JES": ["ACC-VA-001", "ACC-PR-DP-002", "ACC-PR-DP-001"],
    "VIN-NOV-CLU": ["ACC-VA-001", "ACC-PR-DP-002", "ACC-PR-DP-001"],
    "VIN-NOV-WAN": ["ACC-VA-001", "ACC-PR-DP-002", "ACC-PR-DP-001"],
    "VIN-NOV-STE": ["ACC-VA-001", "ACC-PR-DP-002", "ACC-PR-DP-001"],
    "VIN-NOV-COM": ["ACC-VA-001", "ACC-PR-DP-002", "ACC-PR-DP-001"],
    "VIN-NOV-GAL": ["ACC-VA-001", "ACC-PR-DP-002", "ACC-PR-DP-001"],
    "VIN-NOV-NEU": ["ACC-VA-001", "ACC-PR-DP-002", "ACC-PR-DP-001"],
    "VIN-NOV-COS": ["ACC-VA-001", "ACC-PR-DP-002", "ACC-PR-DP-001"],
    "VIN-NOV-GRE": ["ACC-VA-001", "ACC-PR-DP-002", "ACC-PR-DP-001"],
    "VIN-NOV-STO": ["ACC-VA-001", "ACC-PR-DP-002", "ACC-PR-DP-001"],
    "CAR-LON-PAL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-LON-HER": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-LON-JAM": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-LON-CRO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-LON-BUC": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-LON-ROY": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-LON-REG": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-LON-TOW": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-LON-UND": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-LON-WIM": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-LON-BRI": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CHI-PIE": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CHI-MEL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CHI-MIL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CHI-BEA": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CHI-MIE": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CHI-BUR": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CHI-SKY": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CHI-SEC": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CHI-SHO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CHI-SST": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CHI-SSO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CHI-SLI": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CHI-SSG": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CHI-SPR": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-LAT": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-VIN": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-BAS": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-COM": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-COR": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-CHI": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-GOT": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-SVI": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-SGA": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-SBA": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-SLO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-SDU": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-SME": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-BOU": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-INT": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-CAS": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-POL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-TRA": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-SIR": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-ROM": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MIL-CAT": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-ADE-TER": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAM-MAR": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAM-NOE": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAM-SPI": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-DUB-DRU": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-DUB-ELK": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-DUB-GAE": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-DUB-GEO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-DUB-LAN": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-DUB-SPI": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-DUB-STO": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-DUB-WES": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MON-FLE": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MON-MAT": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MON-SOL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MON-WAL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-MON-SUM": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-WAS-DIS": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-WAS-HEI": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-WAS-MER": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-WAS-NAT": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-BRI-PIE": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAR-BAR": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAR-HEA": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAR-PIN": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAR-SIL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "CAR-CAR-VAL": ["ACC-UN-CAR08-001", "ACC-GR1502-001", "ACC-CS-S09-005"],
    "LAM-SE-12-355": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SE-SC13",
      "ACC-PR-FL-DP13",
    ],
    "LAM-SE-12-356": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SE-SC15",
      "ACC-PR-FL-DP15",
    ],
    "LAM-SE-12-357": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SE-SC49",
      "ACC-PR-FL-DP49",
    ],
    "LAM-SE-12-358": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SE-SC41",
      "ACC-PR-FL-DP41",
    ],
    "LAM-SE-12-360": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SE-SC54",
      "ACC-PR-FL-DP54",
    ],
    "LAM-SE-12-361": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SE-SC13",
      "ACC-PR-FL-DP13",
    ],
    "ENG-GA-20-530": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GA-20-532": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GA-18-509": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GA-18-510": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-HC-14-100": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Wwash",
      "ACC-SO-TP-Ash",
    ],
    "ENG-HC-14-101": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Espresso180",
      "ACC-SO-TP-Espresso180",
    ],
    "ENG-GA-15-507": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Oak",
    ],
    "ENG-GA-15-508": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-GREY",
    ],
    "ENG-GA-15-509": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Espresso180",
    ],
    "ENG-GA-15-153": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Espresso180",
    ],
    "ENG-GA-15-154": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-D41",
      "ACC-SO-SC-Espresso180",
    ],
    "ENG-HC-14-005": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HC-14-102": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Oak",
      "ACC-SO-TP2-Oak",
    ],
    "ENG-HC-14-104": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-SO-SC-Espresso180",
      "ACC-SO-TP-Espresso180",
    ],
    "LAM-AL-8-009": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-SE-12-362": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SE-SC13",
      "ACC-PR-FL-DP13",
    ],
    "LAM-SE-12-363": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC49",
      "ACC-PR-FL-DP49",
    ],
    "LAM-SE-12-364": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SE-SC13",
      "ACC-PR-FL-DP13",
    ],
    "LAM-SE-8-386": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-FL-SC54",
      "ACC-PR-FL-DP54",
    ],
    "LAM-SE-8-387": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-SE-8-388": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-SE-8-389": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-SE-8-390": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-SE-8-391": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-SE-8-392": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-SE-8-393": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "LAM-SE-8-394": [
      {
        m2Threshold: "10",
        lessOrEqualToThreshold: "ACC-UN-NO-010",
        greaterThanThreshold: "ACC-UN-NO-005",
      },
      "ACC-PR-SC-001",
    ],
    "ENG-HCB-14-008": ["ACC-LJACK65014", "ACC-SO-SC-Espresso3", "ACC-SO-TP-Espresso3"],
    "ENG-GAHB-20-004": ["ACC-LJACK65014", "ACC-PR-FL-SC70"],
    "ENG-GAHB-20-005": ["ACC-LJACK65014", "ACC-SO-SC-Oak", "ACC-SO-TP2-Oak"],
    "ENG-GAHB-20-006": ["ACC-LJACK65014", "ACC-SO-SC-Espresso180", "ACC-SO-TP-Espresso180"],
    "SOL-MD-18-001": ["ACC-LJACK65014", "ACC-SO-SC-Oak", "ACC-SO-TP2-Oak"],
    "SOL-GA-18-577": ["ACC-LJACK65014", "ACC-SO-SC-Espresso180", "ACC-SO-TP-Espresso180"],
    "ENG-GA-15-150": ["ACC-LJACK65014", "ACC-SO-SC-Ash", "ACC-SO-TP-Ash"],
    "ENG-GA-15-151": ["ACC-LJACK65014", "ACC-SO-SC-GREY"],
    "ENG-GA-15-152": ["ACC-LJACK65014", "ACC-SO-SC-Espresso180", "ACC-SO-TP-Espresso180"],
    "SOL-GA-18-576": ["ACC-LJACK65014", "ACC-SO-SC-GREY"],
  };
};

export const getAccessories = (): GetAccessories => {
  return {
    "ACC-D41": {
      id: "ACC-D41",
      internalId: "14298",
      name: "Lumberjack D4 Wood Flooring Adhesive 1Ltr",
      m2Price: "24.99",
      packPrice: "24.99",
      coverage: "20",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Lumberjack_D4_Wood_Flooring_Adhesvie_1ltr_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-installation-lumberjack-d4-wood-flooring-adhesive-1ltr",
    },
    "ACC-UN-NO-012": {
      id: "ACC-UN-NO-012",
      internalId: "87490",
      name: "Acoustic Vinyl 15m Underlay",
      m2Price: "6.49",
      packPrice: "97.35",
      coverage: "15",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Acoustic_Vinyl_15m_Underlay_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-flooring-underlay-acoustic-15m-lvt-underlay",
    },
    "ACC-IN-AD-001": {
      id: "ACC-IN-AD-001",
      internalId: "72315",
      name: "Spectra Pressure Sensitive Glue Down Adhesive 5kg",
      m2Price: "41.99",
      packPrice: "41.99",
      coverage: "15",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Spectra_Pressure_Sensitive_Glue_Down_Adhesive_5kg_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-spectra-pressure-sensitive-glue-down-adhesive-5kg",
    },
    "ACC-IN-AD-002": {
      id: "ACC-IN-AD-002",
      internalId: "72316",
      name: "Spectra Pressure Sensitive Glue Down Adhesive 15kg",
      m2Price: "104.99",
      packPrice: "104.99",
      coverage: "45",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Spectra_Pressure_Sensitive_Glue_Down_Adhesive_15kg_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-spectra-pressure-sensitive-glue-down-adhesive-15kg",
    },
    "ACC-IN-AD-003": {
      id: "ACC-IN-AD-003",
      internalId: "72317",
      name: "Spectra High Temperature Glue Down Adhesive 5kg",
      m2Price: "62.99",
      packPrice: "62.99",
      coverage: "15",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Spectra_High_Temperature_Glue_Down_Adhesive_5kg_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-spectra-high-temperature-glue-down-adhesive-5kg?page=1",
    },
    "ACC-IN-AD-004": {
      id: "ACC-IN-AD-004",
      internalId: "72318",
      name: "Spectra High Temperature Glue Down Adhesive 15kg",
      m2Price: "148.99",
      packPrice: "148.99",
      coverage: "45",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Spectra_High_Temperature_Glue_Down_Adhesive_15kg_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-spectra-high-temperature-glue-down-adhesive-15kg",
    },
    "ACC-IN-CS-002": {
      id: "ACC-IN-CS-002",
      internalId: "64267",
      name: "Unika Light Oak Sealant",
      m2Price: "7.99",
      packPrice: "7.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Unika_Light_Oak_Sealant_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-installation-unika-light-oak-sealant",
    },
    "ACC-IN-CS-005": {
      id: "ACC-IN-CS-005",
      internalId: "64270",
      name: "Unika Grey Dust Sealant",
      m2Price: "7.99",
      packPrice: "7.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Unika_Grey_Dust_Sealant_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-installation-unika-grey-dust-sealant",
    },
    "ACC-IN-CS-007": {
      id: "ACC-IN-CS-007",
      internalId: "64272",
      name: "Unika Soft White Sealant",
      m2Price: "7.99",
      packPrice: "7.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Unika_Soft_White_Sealant_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-installation-unika-soft-white-sealant",
    },
    "ACC-IN-CS-012": {
      id: "ACC-IN-CS-012",
      internalId: "64277",
      name: "Unika Click Seal Gel",
      m2Price: "10.99",
      packPrice: "10.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Unika_Click_Seal_Gel_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-installation-unika-click-seal-gel",
    },
    "ACC-IN-KT-001": {
      id: "ACC-IN-KT-001",
      internalId: "4051",
      name: "Easy Fit Flooring Installation Kit",
      m2Price: "10.99",
      packPrice: "10.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Easy_Fit_Flooring_Installation_Kit_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-installation-easy-fit-flooring-installation-kit",
    },
    "ACC-IN-RO-003": {
      id: "ACC-IN-RO-003",
      internalId: "67687",
      name: "Luxury Click Vinyl Hand Roller",
      m2Price: "14.99",
      packPrice: "14.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Luxury_Click_Vinyl_Hand_Roller_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-installation-luxury-click-vinyl-hand-roller",
    },
    "ACC-IN-SB-010": {
      id: "ACC-IN-SB-010",
      internalId: "71014",
      name: "SikaBond Rapid DPM 5ltr",
      m2Price: "99.99",
      packPrice: "99.99",
      coverage: "20",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_SikaBond_Rapid_DPM_5ltr_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-installation-sikabond-rapid-dpm",
    },
    "ACC-IN-TT-002": {
      id: "ACC-IN-TT-002",
      internalId: "4473",
      name: "Tongue Tite Wood Flooring Screws",
      m2Price: "10.99",
      packPrice: "10.99",
      coverage: "10",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Tongue_Tite_Wood_Flooring_Screws_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-installation-tongue-tite-wood-flooring-screws",
    },
    "ACC-LJACK5506": {
      id: "ACC-LJACK5506",
      internalId: "14304",
      name: "Lumberjack 550 Foil Adhesive",
      m2Price: "11.99",
      packPrice: "11.99",
      coverage: "2",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Lumberjack_550_Foil_Adhesive_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-installation-lumberjack-550-foil-adhesive",
    },
    "ACC-LJACK65014": {
      id: "ACC-LJACK65014",
      internalId: "14300",
      name: "Lumberjack 650 Floor Adhesive 14Kg",
      m2Price: "119.99",
      packPrice: "119.99",
      coverage: "14",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Lumberjack_650_Floor_Adhesive_14KG_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-installation-lumberjack-650-floor-adhesive-14kg",
    },
    "ACC-MA-CL-001": {
      id: "ACC-MA-CL-001",
      internalId: "59425",
      name: "Ciranova Extra Matt Floor Refresher",
      m2Price: "40.99",
      packPrice: "40.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Ciranova_Extra_Matt_Floor_Refresher_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-after-care-ciranova-extra-matt-floor-refresher",
    },
    "ACC-MA-UA-001": {
      id: "ACC-MA-UA-001",
      internalId: "55014",
      name: "Unika Laminate And Wood Floor Mop",
      m2Price: "16.99",
      packPrice: "16.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Unika_Laminate_and_Wood_Floor_Mop_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-after-care-unika-laminate-and-wood-floor-mop",
    },
    "ACC-MA-UA-002": {
      id: "ACC-MA-UA-002",
      internalId: "55015",
      name: "Unika Laminate And Wood Daily Cleaner",
      m2Price: "4.99",
      packPrice: "4.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Unika_Laminate_and_Wood_Daily_Cleaner_01.jpg	",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-after-care-unika-laminate-and-wood-daily-cleaner",
    },
    "ACC-MA-UA-003": {
      id: "ACC-MA-UA-003",
      internalId: "55016",
      name: "Unika LVT Daily Cleaner",
      m2Price: "4.99",
      packPrice: "4.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Unika_LVT_Daily_Cleaner_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-after-care-unika-lvt-daily-cleaner",
    },
    "ACC-MA-WO-001": {
      id: "ACC-MA-WO-001",
      internalId: "22853",
      name: "Woca Soap Spray Natural",
      m2Price: "12.99",
      packPrice: "12.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Woca_Soap_Spray_Natural_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-after-care-woca-soap-spray-natural",
    },
    "ACC-MA-WO-002": {
      id: "ACC-MA-WO-002",
      internalId: "22854",
      name: "Woca Soap Spray White",
      m2Price: "12.99",
      packPrice: "12.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Woca_Soap_Spray_White_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-after-care-woca-soap-spray-white",
    },
    "ACC-MA-WO-003": {
      id: "ACC-MA-WO-003",
      internalId: "22855",
      name: "Woca Care And Protect Kit Oil - Natural",
      m2Price: "46.99",
      packPrice: "46.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Woca_Maintenance_Box_Oil_Natural_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-after-care-woca-maintenance-box-oil-natural",
    },
    "ACC-MA-WO-004": {
      id: "ACC-MA-WO-004",
      internalId: "22856",
      name: "Woca Care And Protect Kit Oil - White",
      m2Price: "54.99",
      packPrice: "54.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Woca_Maintenance_Box_Oil_White_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-after-care-woca-maintenance-box-oil-white",
    },
    "ACC-MA-WO-005": {
      id: "ACC-MA-WO-005",
      internalId: "22857",
      name: "Woca Care and Protect Kit Lacquer, Laminate & Vinyl",
      m2Price: "44.99",
      packPrice: "44.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Woca_Maintenance_Box_Lacquer_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-after-care-woca-maintenance-box-lacquer",
    },
    "ACC-MA-WO-007": {
      id: "ACC-MA-WO-007",
      internalId: "69000",
      name: "Woca Tannin Spot Remover",
      m2Price: "14.99",
      packPrice: "14.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Woca_Tannin_Spot_Remover_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-after-care-woca-tannin-spot-remover",
    },
    "ACC-MA-WO-008": {
      id: "ACC-MA-WO-008",
      internalId: "69001",
      name: "Woca Master Care For Lacquer, Laminate & Vinyl",
      m2Price: "10.99",
      packPrice: "10.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Woca_Master_Care_For_Lacquer,_Laminate_Vinyl_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-after-care-woca-master-care-lacquer-laminate-vinyl",
    },
    "ACC-MA-WO-009": {
      id: "ACC-MA-WO-009",
      internalId: "69002",
      name: "Woca Intensive Wood Cleaner",
      m2Price: "10.99",
      packPrice: "10.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Woca_Intensive_Wood_Cleaner_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-after-care-woca-intensive-wood-cleaner",
    },
    "ACC-MA-WO-010": {
      id: "ACC-MA-WO-010",
      internalId: "69003",
      name: "Woca Oil Refreshing Soap For Natural Floors",
      m2Price: "17.99",
      packPrice: "17.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Woca_Oil_Refreshing_Soap_For_Natural_Floors_01.jpg	",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-after-care-woca-oil-refreshing-soap-natural-floors",
    },
    "ACC-MA-WO-011": {
      id: "ACC-MA-WO-011",
      internalId: "69004",
      name: "Woca Master Cleaner",
      m2Price: "5.99",
      packPrice: "5.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Woca_Master_Cleaner_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-after-care-woca-master-cleaner",
    },
    "ACC-MA-WO-012": {
      id: "ACC-MA-WO-012",
      internalId: "69005",
      name: "Woca Maintenance Gel",
      m2Price: "19.99",
      packPrice: "19.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Woca_Maintenance_Gel_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-after-care-woca-maintenance-gel",
    },
    "ACC-MA-WO-013": {
      id: "ACC-MA-WO-013",
      internalId: "69006",
      name: "Woca Oil Care For Natural Floors",
      m2Price: "17.99",
      packPrice: "17.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Woca_Oil_Care_For_Natural_Floors_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-after-care-woca-oil-care-natural-floors",
    },
    "ACC-MA-WO-016": {
      id: "ACC-MA-WO-016",
      internalId: "69099",
      name: "Woca Wood Stain Remover",
      m2Price: "11.99	",
      packPrice: "11.99	",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Woca_Wood_Stain_Remover_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-after-care-woca-wood-stain-remover",
    },
    "ACC-PR-DP-001": {
      id: "ACC-PR-DP-001",
      internalId: "68799",
      name: "90cm Stainless Steel Matt Ramp",
      m2Price: "18.99",
      packPrice: "18.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_90cm_Stainless_Steel_Matt_Ramp_01.jpg",
      productLink: "https://trade.ukflooringdirect.co.uk/90cm-stainless-steel-matt-ramp",
    },
    "ACC-PR-DP-002": {
      id: "ACC-PR-DP-002",
      internalId: "68800",
      name: "90cm Stainless Steel Matt Doorbar",
      m2Price: "18.99",
      packPrice: "18.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_90cm_Stainless_Steel_Matt_Doorbar_01.jpg",
      productLink: "https://trade.ukflooringdirect.co.uk/90cm-stainless-steel-matt-doorbar",
    },
    "ACC-PR-FL-DP13": {
      id: "ACC-PR-FL-DP13",
      internalId: "10847",
      name: "Series Laminate Door Profile 0.9M FC13",
      m2Price: "13.99",
      packPrice: "13.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Door_Profile_DP13_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-door-profile-0-9m-fc13",
    },
    "ACC-PR-FL-DP15": {
      id: "ACC-PR-FL-DP15",
      internalId: "10759",
      name: "Series Laminate Door Profile 0.9M FC15",
      m2Price: "13.99",
      packPrice: "13.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Door_Profile_DP15_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-door-profile-0-9m-fc15",
    },
    "ACC-PR-FL-DP25": {
      id: "ACC-PR-FL-DP25",
      internalId: "10757",
      name: "Series Laminate Door Profile 0.9M FC25",
      m2Price: "13.99",
      packPrice: "13.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Door_Profile_DP25_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-door-profile-0-9m-fc25",
    },
    "ACC-PR-FL-DP33": {
      id: "ACC-PR-FL-DP33",
      internalId: "10848",
      name: "Series Laminate Door Profile 0.9M FC33",
      m2Price: "13.99",
      packPrice: "13.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Door_Profile_DP33_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-door-profile-0-9m-fc33",
    },
    "ACC-PR-FL-DP41": {
      id: "ACC-PR-FL-DP41",
      internalId: "10761",
      name: "Series Laminate Door Profile 0.9M FC41",
      m2Price: "13.99",
      packPrice: "13.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Door_Profile_DP41_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-door-profile-0-9m-fc41",
    },
    "ACC-PR-FL-DP45": {
      id: "ACC-PR-FL-DP45",
      internalId: "10754",
      name: "Series Laminate Door Profile 0.9M FC45",
      m2Price: "13.99",
      packPrice: "13.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Door_Profile_DP45_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-door-profile-0-9m-fc45",
    },
    "ACC-PR-FL-DP47": {
      id: "ACC-PR-FL-DP47",
      internalId: "10762",
      name: "Series Laminate Door Profile 0.9M FC47",
      m2Price: "13.99",
      packPrice: "13.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Door_Profile_DP47_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-door-profile-0-9m-fc47",
    },
    "ACC-PR-FL-DP49": {
      id: "ACC-PR-FL-DP49",
      internalId: "10849",
      name: "Series Laminate Door Profile 0.9M FC49",
      m2Price: "13.99",
      packPrice: "13.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Door_Profile_DP49_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-door-profile-0-9m-fc49",
    },
    "ACC-PR-FL-DP54": {
      id: "ACC-PR-FL-DP54",
      internalId: "10755",
      name: "Series Laminate Door Profile 0.9M FC54",
      m2Price: "13.99",
      packPrice: "13.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Door_Profile_DP54_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-door-profile-0-9m-fc54",
    },
    "ACC-PR-FL-DP56": {
      id: "ACC-PR-FL-DP56",
      internalId: "10760",
      name: "Series Laminate Door Profile 0.9M FC56",
      m2Price: "13.99",
      packPrice: "13.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Door_Profile_DP56_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-door-profile-0-9m-fc56",
    },
    "ACC-PR-FL-DP57": {
      id: "ACC-PR-FL-DP57",
      internalId: "10753",
      name: "Series Laminate Door Profile 0.9M FC57",
      m2Price: "13.99",
      packPrice: "13.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Door_Profile_DP57_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-door-profile-0-9m-fc57",
    },
    "ACC-PR-FL-DP59": {
      id: "ACC-PR-FL-DP59",
      internalId: "10846",
      name: "Series Laminate Door Profile 0.9M FC59",
      m2Price: "13.99",
      packPrice: "13.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Door_Profile_DP59_01.jpg	",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-door-profile-0-9m-fc59",
    },
    "ACC-PR-FL-DP69": {
      id: "ACC-PR-FL-DP69",
      internalId: "10758",
      name: "Series Laminate Door Profile 0.9M FC69",
      m2Price: "13.99",
      packPrice: "13.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Door_Profile_DP69_01.jpg	",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-door-profile-0-9m-fc69",
    },
    "ACC-PR-FL-DP70": {
      id: "ACC-PR-FL-DP70",
      internalId: "10756",
      name: "Series Laminate Door Profile 0.9M FC70",
      m2Price: "13.99",
      packPrice: "13.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Door_Profile_DP70_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-door-profile-0-9m-fc70",
    },
    "ACC-PR-FL-SC15": {
      id: "ACC-PR-FL-SC15",
      internalId: "10746",
      name: "Series Laminate Scotia 2.4M Light Oak",
      m2Price: "9.99",
      packPrice: "9.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Scotia_SC15_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-scotia-2-4m-fc15",
    },
    "ACC-PR-FL-SC25": {
      id: "ACC-PR-FL-SC25",
      internalId: "10748",
      name: "Series Laminate Scotia 2.4M Cherry",
      m2Price: "7.99",
      packPrice: "7.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Scotia_SC25_01.jpg	",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-scotia-2-4m-fc25",
    },
    "ACC-PR-FL-SC33": {
      id: "ACC-PR-FL-SC33",
      internalId: "4016",
      name: "Series Laminate Scotia 2.4M Elegance Oak",
      m2Price: "9.99",
      packPrice: "9.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Scotia_2.4m_Elegance_Oak_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-scotia-2-4m-elegance-oak",
    },
    "ACC-PR-FL-SC41": {
      id: "ACC-PR-FL-SC41",
      internalId: "10764",
      name: "Series Laminate Scotia 2.4M Black Cherry",
      m2Price: "9.99",
      packPrice: "9.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Scotia_SC41_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-scotia-2-4m-fc41",
    },
    "ACC-PR-FL-SC45": {
      id: "ACC-PR-FL-SC45",
      internalId: "10751",
      name: "Series Laminate Scotia 2.4M Golden Oak",
      m2Price: "9.99",
      packPrice: "9.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Scotia_SC45_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-scotia-2-4m-fc45",
    },
    "ACC-PR-FL-SC47": {
      id: "ACC-PR-FL-SC47",
      internalId: "10763",
      name: "Series Laminate Scotia 2.4M Black Brown",
      m2Price: "9.99",
      packPrice: "9.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Scotia_SC47_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-scotia-2-4m-fc47",
    },
    "ACC-PR-FL-SC49": {
      id: "ACC-PR-FL-SC49",
      internalId: "4027",
      name: "Series Laminate Scotia 2.4M White Oak",
      m2Price: "9.99",
      packPrice: "9.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Scotia_2.4m_White_Oak_01.jpg	",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-scotia-2-4m-white-oak",
    },
    "ACC-PR-FL-SC54": {
      id: "ACC-PR-FL-SC54",
      internalId: "7976",
      name: "Series Laminate Scotia 2.4m Black Grey",
      m2Price: "9.99",
      packPrice: "9.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Scotia_SC54_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-scotia-2-4m-fc54",
    },
    "ACC-PR-FL-SC56": {
      id: "ACC-PR-FL-SC56",
      internalId: "10765",
      name: "Series Laminate Scotia 2.4M Old White",
      m2Price: "9.99",
      packPrice: "9.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Scotia_SC56_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-scotia-2-4m-fc56",
    },
    "ACC-PR-FL-SC57": {
      id: "ACC-PR-FL-SC57",
      internalId: "10752",
      name: "Series Laminate Scotia 2.4M Wild Berry",
      m2Price: "9.99",
      packPrice: "9.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Scotia_SC57_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-scotia-2-4m-fc57",
    },
    "ACC-PR-FL-SC59": {
      id: "ACC-PR-FL-SC59",
      internalId: "4005",
      name: "Series Laminate Scotia 2.4M Harvest Oak",
      m2Price: "9.99",
      packPrice: "9.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Scotia_2.4m_Harvest_Oak_01.jpg	",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-scotia-2-4m-harvest-oak",
    },
    "ACC-PR-FL-SC69": {
      id: "ACC-PR-FL-SC69",
      internalId: "10747",
      name: "Series Laminate Scotia 2.4M Rustic Grey",
      m2Price: "9.99",
      packPrice: "9.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Scotia_SC69_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-scotia-2-4m-fc69",
    },
    "ACC-PR-FL-SC70": {
      id: "ACC-PR-FL-SC70",
      internalId: "10749",
      name: "Series Laminate Scotia 2.4M Stone Grey",
      m2Price: "9.99",
      packPrice: "9.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Scotia_SC70_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-scotia-2-4m-fc70",
    },
    "ACC-PR-SC-001": {
      id: "ACC-PR-SC-001",
      internalId: "4050",
      name: "Series Laminate 2.4M Pure White Scotia",
      m2Price: "7.99",
      packPrice: "7.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_2.4m_Pure_White_Scotia_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-2-4m-pure-white-scotia",
    },
    "ACC-PR-SE-SC1": {
      id: "ACC-PR-SE-SC1",
      internalId: "4028",
      name: "Series Laminate Scotia 2.4M Natural Oak",
      m2Price: "5.99",
      packPrice: "5.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Scotia_2.4m_Natural_Oak_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-scotia-2-4m-natural-oak",
    },
    "ACC-PR-SK-001": {
      id: "ACC-PR-SK-001",
      internalId: "4049",
      name: "Series Laminate 2.4M Pure White Skirting",
      m2Price: "15.99",
      packPrice: "15.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Series_Laminate_2.4m_Pure_White_Skirting_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-2-4m-pure-white-skirting",
    },
    "ACC-SGCOMB16": {
      id: "ACC-SGCOMB16",
      internalId: "14299",
      name: "Foil Applicator Gun",
      m2Price: "25.99",
      packPrice: "25.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Foil-applicator-gun-500x500.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-installation-foil-applicator-gun",
    },
    "ACC-SO-CW2-OAK": {
      id: "ACC-SO-CW2-OAK",
      internalId: "4559",
      name: "Solid Hardwood Carpet To Wood Profile Oak 2M",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Oak_Profile_Carpet_to_Wood_2m_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-carpet-to-wood-profile-oak-2m",
    },
    "ACC-SO-EP-Antique": {
      id: "ACC-SO-EP-Antique",
      internalId: "4477",
      name: "Solid Hardwood End Profile 2M Antique",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_End_Profile_2m_Antique_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-end-profile-2m-antique",
    },
    "ACC-SO-EP-Ash": {
      id: "ACC-SO-EP-Ash",
      internalId: "4478",
      name: "Solid Hardwood End Profile 2M Ash",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_End_Profile_2m_Ash_01.jpg	",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-end-profile-2m-ash",
    },
    "ACC-SO-EP-Beech": {
      id: "ACC-SO-EP-Beech",
      internalId: "4479",
      name: "Solid Hardwood End Profile 2M Beech",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_End_Profile_2m_Beech_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-end-profile-2m-beech",
    },
    "ACC-SO-EP-Colonial": {
      id: "ACC-SO-EP-Colonial",
      internalId: "4482",
      name: "Solid Hardwood End Profile 2M Colonial",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_End_Profile_2m_Colonial_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-end-profile-2m-colonial",
    },
    "ACC-SO-EP-Espresso180": {
      id: "ACC-SO-EP-Espresso180",
      internalId: "4483",
      name: "Solid Hardwood End Profile 2M Espresso180",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_End_Profile_2m_Espresso180_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-end-profile-2m-espresso180",
    },
    "ACC-SO-EP-Espresso3": {
      id: "ACC-SO-EP-Espresso3",
      internalId: "4484",
      name: "Solid Hardwood End Profile 2M Espresso3",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_End_Profile_2m_Espresso3_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-end-profile-2m-espresso3",
    },
    "ACC-SO-EP-Fumed": {
      id: "ACC-SO-EP-Fumed",
      internalId: "4485",
      name: "Solid Hardwood End Profile 2M Fumed",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_End_Profile_2m_Fumed_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-end-profile-2m-fumed",
    },
    "ACC-SO-EP-GREY": {
      id: "ACC-SO-EP-GREY",
      internalId: "4587",
      name: "Solid Hardwood End Profile 2M French Grey",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_End_Profile_2m_French_Grey_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-end-profile-2m-french-grey",
    },
    "ACC-SO-EP-Maple": {
      id: "ACC-SO-EP-Maple",
      internalId: "4486",
      name: "Solid Hardwood End Profile 2M Maple",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_End_Profile_2m_Maple_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-end-profile-2m-maple",
    },
    "ACC-SO-EP-Pearl": {
      id: "ACC-SO-EP-Pearl",
      internalId: "4487",
      name: "Solid Hardwood End Profile 2M Pearl",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_End_Profile_2m_Pearl_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-end-profile-2m-pearl",
    },
    "ACC-SO-EP-Wfumed": {
      id: "ACC-SO-EP-Wfumed",
      internalId: "4489",
      name: "Solid Hardwood End Profile 2M White Fumed",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_End_Profile_2m_White_Fumed_01.jpg	",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-end-profile-2m-white-fumed",
    },
    "ACC-SO-EP-WWash": {
      id: "ACC-SO-EP-WWash",
      internalId: "4561",
      name: "Solid Hardwood End Profile 2M White Wash",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_End_Profile_2m_White_Wash_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-end-profile-2m-white-wash",
    },
    "ACC-SO-EP2-Oak": {
      id: "ACC-SO-EP2-Oak",
      internalId: "4476",
      name: "Solid Hardwood End Profile Oak 2M",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_End_Profile_2m_Oak_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-end-profile-oak-2m",
    },
    "ACC-SO-RP-Antique": {
      id: "ACC-SO-RP-Antique",
      internalId: "4504",
      name: "Solid Hardwood Ramp Profile 2M Antique",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Ramp_Profile_2m_Antique_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-ramp-profile-2m-antique",
    },
    "ACC-SO-RP-Ash": {
      id: "ACC-SO-RP-Ash",
      internalId: "4505",
      name: "Solid Hardwood Ramp Profile 2M Ash",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Ramp_Profile_2m_Ash_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-ramp-profile-2m-ash",
    },
    "ACC-SO-RP-Beech": {
      id: "ACC-SO-RP-Beech",
      internalId: "4506",
      name: "Solid Hardwood Ramp Profile 2M Beech",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Solid_Hardwood_Ramp_Profile_2m_Beech_01.jpg	",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-ramp-profile-2m-beech",
    },
    "ACC-SO-RP-Colonial": {
      id: "ACC-SO-RP-Colonial",
      internalId: "4509",
      name: "Solid Hardwood Ramp Profile 2M Colonial",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Ramp_Profile_2m_Colonial_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-ramp-profile-2m-colonial",
    },
    "ACC-SO-RP-Espresso180": {
      id: "ACC-SO-RP-Espresso180",
      internalId: "4510",
      name: "Solid Hardwood Ramp Profile 2M Espresso180",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Ramp_Profile_2m_Espresso180_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-ramp-profile-2m-espresso180",
    },
    "ACC-SO-RP-Espresso3": {
      id: "ACC-SO-RP-Espresso3",
      internalId: "4511",
      name: "Solid Hardwood Ramp Profile 2M Espresso3",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Ramp_Profile_2m_Espresso3_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-ramp-profile-2m-espresso3",
    },
    "ACC-SO-RP-Fumed": {
      id: "ACC-SO-RP-Fumed",
      internalId: "4512",
      name: "Solid Hardwood Ramp Profile 2M Fumed",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Ramp_Profile_2m_Fumed_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-ramp-profile-2m-fumed",
    },
    "ACC-SO-RP-GREY": {
      id: "ACC-SO-RP-GREY",
      internalId: "4585",
      name: "Solid Hardwood Ramp Profile 2M French Grey",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_2m_Ramp_Profile_French_Grey_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-ramp-profile-2m-french-grey",
    },
    "ACC-SO-RP-Maple": {
      id: "ACC-SO-RP-Maple",
      internalId: "4513",
      name: "Solid Hardwood Ramp Profile 2M Maple",
      m2Price: "44.99",
      packPrice: "44.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Ramp_Profile_2m_Maple_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-ramp-profile-2m-maple",
    },
    "ACC-SO-RP-Pearl": {
      id: "ACC-SO-RP-Pearl",
      internalId: "4514",
      name: "Solid Hardwood Ramp Profile 2M Pearl",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Ramp_Profile_2m_Pearl_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-ramp-profile-2m-pearl",
    },
    "ACC-SO-RP-WFumed": {
      id: "ACC-SO-RP-WFumed",
      internalId: "4516",
      name: "Solid Hardwood Ramp Profile 2M White Fumed",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Ramp_Profile_2m_White_Fumed_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-ramp-profile-2m-white-fumed",
    },
    "ACC-SO-RP-WWash": {
      id: "ACC-SO-RP-WWash",
      internalId: "4564",
      name: "Solid Hardwood Ramp Profile 2M White Wash",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Ramp_Profile_2m_White_Wash_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-ramp-profile-2m-white-wash",
    },
    "ACC-SO-RP2-Oak": {
      id: "ACC-SO-RP2-Oak",
      internalId: "4503",
      name: "Solid Hardwood Ramp Profile Oak 2M",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Ramp_Profile_2m_Oak_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-ramp-profile-oak-2m",
    },
    "ACC-SO-SC-Antique": {
      id: "ACC-SO-SC-Antique",
      internalId: "4517",
      name: "Solid Hardwood Scotia 2M Antique",
      m2Price: "19.99",
      packPrice: "19.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Scotia_2m_Length_Antique_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-scotia-2m-antique",
    },
    "ACC-SO-SC-Ash": {
      id: "ACC-SO-SC-Ash",
      internalId: "4518",
      name: "Solid Hardwood Scotia 2M Ash",
      m2Price: "19.99",
      packPrice: "19.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Scotia_2m_Length_Ash_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-scotia-2m-ash",
    },
    "ACC-SO-SC-Beech": {
      id: "ACC-SO-SC-Beech",
      internalId: "4519",
      name: "Solid Hardwood Scotia 2M Beech",
      m2Price: "19.99",
      packPrice: "19.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Scotia_2m_Length_Beech_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-scotia-2m-beech",
    },
    "ACC-SO-SC-Colonial": {
      id: "ACC-SO-SC-Colonial",
      internalId: "4522",
      name: "Solid Hardwood Scotia 2M Colonial",
      m2Price: "19.99",
      packPrice: "19.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid Hardwood Scotia 2m Length Colonial_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-scotia-2m-colonial",
    },
    "ACC-SO-SC-Espresso180": {
      id: "ACC-SO-SC-Espresso180",
      internalId: "4523",
      name: "Solid Hardwood Scotia 2M Espresso180",
      m2Price: "19.99",
      packPrice: "19.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Scotia_2m_Length_Espresso180_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-scotia-2m-espresso180",
    },
    "ACC-SO-SC-Espresso3": {
      id: "ACC-SO-SC-Espresso3",
      internalId: "4524",
      name: "Solid Hardwood Scotia 2M Espresso3",
      m2Price: "19.99",
      packPrice: "19.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Scotia_2m_Length_Espresso3_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-scotia-2m-espresso3",
    },
    "ACC-SO-SC-Fumed": {
      id: "ACC-SO-SC-Fumed",
      internalId: "4525",
      name: "Solid Hardwood Scotia 2M Fumed",
      m2Price: "19.99",
      packPrice: "19.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Scotia_2m_Length_Fumed_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-scotia-2m-fumed",
    },
    "ACC-SO-SC-GREY": {
      id: "ACC-SO-SC-GREY",
      internalId: "4586",
      name: "Solid Hardwood Scotia 2M French Grey",
      m2Price: "19.99",
      packPrice: "19.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_2m_Scotia_French_Grey_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-scotia-2m-french-grey",
    },
    "ACC-SO-SC-Maple": {
      id: "ACC-SO-SC-Maple",
      internalId: "4526",
      name: "Solid Hardwood Scotia 2M Maple",
      m2Price: "19.99",
      packPrice: "19.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Scotia_2m_Length_Maple_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-scotia-2m-maple",
    },
    "ACC-SO-SC-Oak": {
      id: "ACC-SO-SC-Oak",
      internalId: "4527",
      name: "Solid Hardwood Scotia Oak 2M",
      m2Price: "19.99",
      packPrice: "19.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Scotia_2m_Length_Oak_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-scotia-oak-2m",
    },
    "ACC-SO-SC-Pearl": {
      id: "ACC-SO-SC-Pearl",
      internalId: "4528",
      name: "Solid Hardwood Scotia 2M Pearl",
      m2Price: "19.99",
      packPrice: "19.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Scotia_2m_Length_Pearl_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-scotia-2m-pearl",
    },
    "ACC-SO-SC-WFumed": {
      id: "ACC-SO-SC-WFumed",
      internalId: "4530",
      name: "Solid Hardwood Scotia 2M White Fumed",
      m2Price: "19.99",
      packPrice: "19.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Scotia_2m_Length_White_Fumed_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-scotia-2m-white-fumed",
    },
    "ACC-SO-SC-Wwash": {
      id: "ACC-SO-SC-Wwash",
      internalId: "4565",
      name: "Solid Hardwood Scotia 2M White Wash",
      m2Price: "19.99",
      packPrice: "19.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Scotia_2m_Length_White_Wash_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-scotia-2m-white-wash",
    },
    "ACC-SO-SN-Antique": {
      id: "ACC-SO-SN-Antique",
      internalId: "4531",
      name: "Solid Hardwood Stair Nosing 1M Antique",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Stair_Nosing_1m_Antique_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-stair-nosing-1m-antique",
    },
    "ACC-SO-SN-Ash": {
      id: "ACC-SO-SN-Ash",
      internalId: "4532",
      name: "Solid Hardwood Stair Nosing 1M Ash",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Stair_Nosing_1m_Ash_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-stair-nosing-1m-ash",
    },
    "ACC-SO-SN-Beech": {
      id: "ACC-SO-SN-Beech",
      internalId: "4533",
      name: "Solid Hardwood Stair Nosing 1M Beech",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Stair_Nosing_1m_Beech_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-stair-nosing-1m-beech",
    },
    "ACC-SO-SN-Colonial": {
      id: "ACC-SO-SN-Colonial",
      internalId: "4535",
      name: "Solid Hardwood Stair Nosing 1M Colonial",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Stair_Nosing_1m_Colonial_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-stair-nosing-1m-colonial",
    },
    "ACC-SO-SN-Espresso180": {
      id: "ACC-SO-SN-Espresso180",
      internalId: "4536",
      name: "Solid Hardwood Stair Nosing 1M Espresso180",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Stair_Nosing_1m_Espresso180_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-stair-nosing-1m-espresso180",
    },
    "ACC-SO-SN-Espresso3": {
      id: "ACC-SO-SN-Espresso3",
      internalId: "4537",
      name: "Solid Hardwood Stair Nosing 1M Espresso3",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Stair_Nosing_1m_Espresso3_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-stair-nosing-1m-espresso3",
    },
    "ACC-SO-SN-Fumed": {
      id: "ACC-SO-SN-Fumed",
      internalId: "4538",
      name: "Solid Hardwood Stair Nosing 1M Fumed",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Stair_Nosing_1m_Fumed_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-stair-nosing-1m-fumed",
    },
    "ACC-SO-SN-GREY": {
      id: "ACC-SO-SN-GREY",
      internalId: "4589",
      name: "Solid Hardwood Stair Nosing 1M French Grey",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Stair_Nosing_1m_French_Grey_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-stair-nosing-1m-french-grey",
    },
    "ACC-SO-SN-Oak": {
      id: "ACC-SO-SN-Oak",
      internalId: "4540",
      name: "Solid Hardwood Stair Nosing Oak 1M",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Stair_Nosing_1m_Oak_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-stair-nosing-oak-1m",
    },
    "ACC-SO-SN-Pearl": {
      id: "ACC-SO-SN-Pearl",
      internalId: "4541",
      name: "Solid Hardwood Stair Nosing 1M Pearl",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Stair_Nosing_1m_Pearl_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-stair-nosing-1m-pearl",
    },
    "ACC-SO-SN-WFumed": {
      id: "ACC-SO-SN-WFumed",
      internalId: "4543",
      name: "Solid Hardwood Stair Nosing 1M White Fumed",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Stair_Nosing_1m_White_Fumed_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-stair-nosing-1m-white-fumed",
    },
    "ACC-SO-SN-Wwash": {
      id: "ACC-SO-SN-Wwash",
      internalId: "4567",
      name: "Solid Hardwood Stair Nosing 1M White Wash",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Stair_Nosing_1m_White_Wash_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-stair-nosing-1m-white-wash",
    },
    "ACC-SO-TP-Antique": {
      id: "ACC-SO-TP-Antique",
      internalId: "4545",
      name: "Solid Hardwood T Profile 2M Antique",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_T_Profile_2m_Antique_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-t-profile-2m-antique",
    },
    "ACC-SO-TP-Ash": {
      id: "ACC-SO-TP-Ash",
      internalId: "4546",
      name: "Solid Hardwood T Profile 2M Ash",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_T_Profile_2m_Ash_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-t-profile-2m-ash",
    },
    "ACC-SO-TP-Beech": {
      id: "ACC-SO-TP-Beech",
      internalId: "4547",
      name: "Solid Hardwood T Profile 2M Beech",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_T_Profile_2m_Beech_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-t-profile-2m-beech",
    },
    "ACC-SO-TP-Colonial": {
      id: "ACC-SO-TP-Colonial",
      internalId: "4550",
      name: "Solid Hardwood T Profile 2M Colonial",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_T_Profile_2m_Colonial_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-t-profile-2m-colonial",
    },
    "ACC-SO-TP-Espresso180": {
      id: "ACC-SO-TP-Espresso180",
      internalId: "4551",
      name: "Solid Hardwood T Profile 2M Espresso180",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_T_Profile_2m_Espresso180_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-t-profile-2m-espresso180",
    },
    "ACC-SO-TP-Espresso3": {
      id: "ACC-SO-TP-Espresso3",
      internalId: "4552",
      name: "Solid Hardwood T Profile 2M Espresso3",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_T_Profile_2m_Espresso3_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-t-profile-2m-espresso3",
    },
    "ACC-SO-TP-Fumed": {
      id: "ACC-SO-TP-Fumed",
      internalId: "4553",
      name: "Solid Hardwood T Profile 2M Fumed",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_T_Profile_2m_Fumed_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-t-profile-2m-fumed",
    },
    "ACC-SO-TP-GREY": {
      id: "ACC-SO-TP-GREY",
      internalId: "4584",
      name: "Solid Hardwood T Profile 2M French Grey",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_2m_T_Profile_French_Grey_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-t-profile-2m-french-grey",
    },
    "ACC-SO-TP-Maple": {
      id: "ACC-SO-TP-Maple",
      internalId: "4554",
      name: "Solid Hardwood T Profile 2M Maple",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_T_Profile_2m_Maple_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-t-profile-2m-maple",
    },
    "ACC-SO-TP-Pearl": {
      id: "ACC-SO-TP-Pearl",
      internalId: "4555",
      name: "Solid Hardwood T Profile 2M Pearl",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_T_Profile_2m_Pearl_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-t-profile-2m-pearl",
    },
    "ACC-SO-TP-WFumed": {
      id: "ACC-SO-TP-WFumed",
      internalId: "4557",
      name: "Solid Hardwood T Profile 2M White Fumed",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_T_Profile_2m_White_Fumed_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-t-profile-2m-white-fumed",
    },
    "ACC-SO-TP-Wwash": {
      id: "ACC-SO-TP-Wwash",
      internalId: "4568",
      name: "Solid Hardwood T Profile 2M White Wash",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_T_Profile_2m_White_Wash_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-t-profile-2m-white-wash",
    },
    "ACC-SO-TP2-Oak": {
      id: "ACC-SO-TP2-Oak",
      internalId: "4544",
      name: "Solid Hardwood T Profile Oak 2M",
      m2Price: "34.99",
      packPrice: "34.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_T_Profile_2m_Oak_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-t-profile-oak-2m",
    },
    "ACC-SO-TS-Oak": {
      id: "ACC-SO-TS-Oak",
      internalId: "4558",
      name: "Solid Oak Taurus Skirting Boards 2M Length",
      m2Price: "38.99",
      packPrice: "38.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Solid_Hardwood_Taurus_Skirting_Boards_Oak_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-solid-hardwood-taurus-skirting-boards-oak",
    },
    "ACC-UN-DP-001": {
      id: "ACC-UN-DP-001",
      internalId: "4048",
      name: "Fast Fit Polythene Membrane 10m2",
      m2Price: "21.99",
      packPrice: "21.99",
      coverage: "10",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Fast_Fit_Polythene_Damp_Proof_Membrane_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-flooring-underlay-fast-fit-polythene-damp-proof-membrane",
    },
    "ACC-UN-FB-55": {
      id: "ACC-UN-FB-55",
      internalId: "4574",
      name: "Fibre Boards 5mm Underlay",
      m2Price: "4.99",
      packPrice: "34.93",
      coverage: "7",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Fibre_Boards_5.5mm_Underlay_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-flooring-underlay-fibre-boards-5-5mm-underlay",
    },
    "ACC-UN-FF-001": {
      id: "ACC-UN-FF-001",
      internalId: "4052",
      name: "Fast Fit Solid Wood 10m2 Underlay",
      m2Price: "9.99",
      packPrice: "99.9",
      coverage: "10",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Fast_Fit_Peel___Stick_Solid_Wood_Flooring_Underlay_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-flooring-underlay-fast-fit-peel-stick-solid-wood-flooring-underlay",
    },
    "ACC-UN-NO-002": {
      id: "ACC-UN-NO-002",
      internalId: "4560",
      name: "Essential Cushion White 15m2 Underlay",
      m2Price: "1.99",
      packPrice: "29.85",
      coverage: "15",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Cushion_White_Underlay_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-flooring-underlay-cushion-white-underlay",
    },
    "ACC-UN-NO-004": {
      id: "ACC-UN-NO-004",
      internalId: "4468",
      name: "Essential Plus Cushion White 15m2 Underlay",
      m2Price: "2.99",
      packPrice: "44.85",
      coverage: "15",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Cushion_Plus_White_Underlay_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-flooring-underlay-cushion-plus-white-underlay",
    },
    "ACC-UN-NO-005": {
      id: "ACC-UN-NO-005",
      internalId: "4469",
      name: "Cushion Ultra Silver Wood 15m2 Underlay",
      m2Price: "4.99",
      packPrice: "74.85",
      coverage: "15",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Cushion_Heat_Flow_Underlay_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-flooring-underlay-cushion-heat-flow-underlay",
    },
    "ACC-UN-NO-008": {
      id: "ACC-UN-NO-008",
      internalId: "4572",
      name: "Cushion Acoustic Gold Wood Flooring 15m2 Underlay",
      m2Price: "7.49",
      packPrice: "112.35",
      coverage: "15",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/bronto_Cushion_Acoustic_Gold_Underlay_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-flooring-underlay-cushion-acoustic-gold-underlay",
    },
    "ACC-UN-NO-010": {
      id: "ACC-UN-NO-010",
      internalId: "75245",
      name: "Cushion Ultra Silver Wood 10m2 Underlay",
      m2Price: "4.99",
      packPrice: "49.90",
      coverage: "10",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/ww2_Website_Images/Cushion_Ultra_Silver_Wood_10m2_Underlay_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-flooring-underlay-cushion-ultra-silver-wood-10m2-underlay",
    },
    "ACC-UN-LC-04": {
      id: "ACC-UN-LC-04",
      internalId: "83286",
      name: "Acoustic Vinyl 10m2 Underlay",
      m2Price: "5.99",
      packPrice: "59.90",
      coverage: "10",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/ww2_Website_Images/Acoustic_lvt_10m2__Underlay_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-flooring-underlay-acoustic-lvt-underlay",
    },
    "ACC-UN-CAR08-001": {
      id: "ACC-UN-CAR08-001",
      internalId: "73944",
      name: "Optimum 8mm Carpet 15m2 Underlay",
      m2Price: "5.99",
      packPrice: "90.26",
      coverage: "15",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Optimum_8mm_Carpet_Underlay_01.jpg",
      productLink: "https://trade.ukflooringdirect.co.uk/accessories-carpet-underlay-optimum-8mm",
    },
    "ACC-GR1502-001": {
      id: "ACC-GR1502-001",
      internalId: "73946",
      name: "Premium Dual Purpose Gripper Rods 10x1.5m",
      m2Price: "9.99",
      packPrice: "9.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Premium_Dual_Purpose_Gripper_Rods_10x1.5m_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-dual-purpose-gripper-rods-10x1.5m",
    },
    "ACC-CS-S09-005": {
      id: "ACC-CS-S09-005",
      internalId: "73951",
      name: "Carpet Cover Strip Door Profile Silver 0.9m",
      m2Price: "4.99",
      packPrice: "4.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Carpet_Cover_Strip_Door_Profile_Silver_0.9m_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-carpet-cover-strip-door-profile-silver-0.9m",
    },
    "ACC-CCA-001": {
      id: "ACC-CCA-001",
      internalId: "77252",
      name: "Heavy Duty Carpet Adhesive 500ml",
      m2Price: "5.99",
      packPrice: "5.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Heavy_Duty_Carpet_Adhesive_500ml_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-installation-heavy-duty-carpet-adhesive-500ml",
    },
    "ACC-VA-001": {
      id: "ACC-VA-001",
      internalId: "73963",
      name: "Vitality Vinyl Adhesive 500ml",
      m2Price: "9.99",
      packPrice: "9.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Vitality_Vinyl_Adhesive_500ml_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-vinyl-adhesive-choice-vinyl-vitality-adhesive-500ml",
    },
    "ACC-PR-SE-SC13": {
      id: "ACC-PR-SE-SC13",
      internalId: "4028",
      name: "Series Laminate Scotia 2.4m FC13",
      m2Price: "9.99",
      packPrice: "9.99",
      coverage: "",
      imageLink:
        "https://trade.ukflooringdirect.co.uk/images/Bronto_Images/Bronto_Series_Laminate_Scotia_2.4m_Natural_Oak_01.jpg",
      productLink:
        "https://trade.ukflooringdirect.co.uk/accessories-finishing-profile-series-laminate-scotia-2-4m-natural-oak",
    },
  };
};
