import { FC, useContext, useState } from "react";
import { useFormikContext } from "formik";
import to from "await-to-js";
import { PAYPAL_VOID_URL } from "@ukfd/checkout-utils";
import { ukfdFetch } from "@ukfd/shared-utils";
import { Button } from "@ukfd/ui";
import { ExternalOrderDetailsContext, UiStateContext } from "@context";
import { PayPalPaymentMethodProps } from "./PayPalPaymentMethod.types";

const PayPalPaymentMethod: FC<PayPalPaymentMethodProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { values, setValues } = useFormikContext<FormikOrderDocument>();

  const {
    orderDetails: { authorisationId, externalOrderId, origin },
    setOrderDetails,
  } = useContext(ExternalOrderDetailsContext);

  const { setUiState } = useContext(UiStateContext);

  const voidPayment = async () => {
    setIsLoading(true);

    await to(
      ukfdFetch<void>(PAYPAL_VOID_URL, {
        method: "POST",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderId: externalOrderId,
          paypalAuthorisationId: authorisationId,
        }),
      })
    );

    setIsLoading(false);
    setUiState({
      selectedPaymentMethodTab: "card",
      arePaymentMethodTabsHidden: false,
    });
    setOrderDetails({ origin: "" });
    setValues({
      ...values,
      payment: {
        ...values.payment,
        type: "card",
      },
    });
  };

  if (origin === "paypal") {
    return (
      <p>
        You have selected to complete your purchase using PayPal.{" "}
        <Button
          disabled={isLoading}
          loading={isLoading}
          variant="ghost"
          onClick={() => voidPayment()}
        >
          Change
        </Button>
      </p>
    );
  }

  return <p>Please use the PayPal button below to complete your purchase using PayPal.</p>;
};

export default PayPalPaymentMethod;
