import { gql } from "@apollo/client";

export const ALL_DELIVERY_DATES = gql`
  mutation allDeliveryDates(
    $isVatIncluded: Boolean!
    $customerType: String!
    $postcode: String!
    $basketId: String!
  ) {
    getAllDeliveryDates(
      isVatIncluded: $isVatIncluded
      customerType: $customerType
      postcode: $postcode
      basketId: $basketId
    ) {
      collection {
        internalId
        isAvailableForCollection
        earliestDeliveryDate
        service {
          internalId
          carrier
          name
        }
        shippingRate
        shippingRateIncVat
      }
      deliveryDates {
        date
        shippingMethods {
          name
          id
          shippingRate
          shippingRateIncVat
          description
          cutoffTime
          service {
            internalId
            carrier
            name
          }
        }
      }
    }
  }
`;
