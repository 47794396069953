import * as React from "react";
import { getData } from "@ukfd/checkout-utils";
import { Select } from "@ukfd/ui";
import { SelectCountryListProps } from "./SelectCountryList.types";

const SelectCountryList: React.FC<SelectCountryListProps> = (props) => (
  <Select
    {...props}
    options={getData().map((country: { name: string; code: string }) => ({
      text: country.name,
      value: country.code,
    }))}
  />
);

export default SelectCountryList;
