import * as Yup from "yup";

export const UK_POSTCODE_REGEX =
  /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/;

export const isValidPostcode = (postcode: string) => UK_POSTCODE_REGEX.test(postcode);

const POSTCODE_VALIDATION_RULE = Yup.string()
  .label("Postcode")
  .matches(UK_POSTCODE_REGEX, "Please enter a valid UK postcode")
  .required("Please enter a valid UK postcode");

export const POSTCODE_VALIDATION_SCHEMA = (name: string) =>
  Yup.object()
    .shape({ [name]: POSTCODE_VALIDATION_RULE })
    .required();

export const UK_PHONE_NUMBER =
  /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|#)\d{3,4})?$/;

const EMAIL_REGEX =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const getCardExpirationValidation = (additionalSchema: any = {}) =>
  Yup.object()
    .shape({
      ...additionalSchema,
      expirationMonth: Yup.string().required().label("Expiry date"),
      expirationYear: Yup.string().required().label("Expiry date"),
    })
    .test("validateExpiryDate", {}, ({ expirationMonth, expirationYear }) => {
      const validationError = new Yup.ValidationError(
        "Please enter a valid expiry date",
        null,
        "payment.expirationMonth"
      );

      // Must have month and year
      if (!expirationMonth || !expirationYear) {
        return validationError;
      }

      const year = +expirationYear;
      const month = +expirationMonth;

      const yearNow = new Date().getFullYear() - 2000;
      const monthNow = new Date().getMonth() + 1; // Unfortunately month is zero based (tut JavaScript)

      // Must be numbers
      if (!year || Number.isNaN(year) || Number.isNaN(month) || month < 0) {
        return validationError;
      }

      // Must be valid month
      if (month > 12) {
        return validationError;
      }

      // Year must not be in the past
      if (year < yearNow) {
        return validationError;
      }

      // Month + year combined must not be in the past
      if (year === yearNow && month < monthNow) {
        return validationError;
      }

      return true;
    });

export const getDefaultValidation = ({
  min = 2,
  max = 100,
  label,
}: {
  min?: number;
  max?: number;
  label: string;
  hideMessage?: boolean;
}) =>
  Yup.string()
    .required(() => `${label} is required`)
    .min(min, () => `${label} is too short`)
    .max(max, () => `${label} is too long`)
    .label(label);

const CUSTOMER_DETAILS = Yup.object()
  .shape({
    firstName: Yup.string()
      .matches(/^[A-Za-z '"-]{2,30}$/, "Please enter a valid first name")
      .label("First Name")
      .required(),
    lastName: Yup.string()
      .matches(/^[A-Za-z '"-]{2,30}$/, "Please enter a valid last name")
      .label("Last Name")
      .required(),
    emailAddress: Yup.string()
      .matches(EMAIL_REGEX, "Please enter a valid email address")
      .label("Email Address")
      .max(43)
      .required(),
    phoneNumber: Yup.string()
      .matches(UK_PHONE_NUMBER, "Please enter a valid UK based phone number")
      .label("Phone Number")
      .required(),
  })
  .required();

const ADDRESS = Yup.object()
  .shape({
    postcode: POSTCODE_VALIDATION_RULE,
    addressLine1: getDefaultValidation({ label: "Address Line 1" }),
    addressLine2: Yup.string().optional().label("Address Line 2"),
    city: getDefaultValidation({ label: "City" }),
    county: getDefaultValidation({ label: "County", max: 30 }),
    country: Yup.string().label("Country"),
  })
  .required("Delivery address is required");

const DELIVERY_METHOD = Yup.object()
  .shape({
    deliveryOption: Yup.string().required().label("Delivery option"),
    deliveryDate: Yup.string().required().label("Delivery date"),
    hasVehicleSizeLimitations: Yup.string().required().label("Vehicle size limitations"),
    furtherDetails: Yup.string().optional().label("Further delivery details"),
  })
  .required();

const PAYMENT = getCardExpirationValidation({
  nameOnCard: getDefaultValidation({ label: "Name on Card" }),
});

const SAMPLE_SURVEY_FORM = Yup.object()
  .shape({
    livingRoom: Yup.boolean().optional(),
    diningRoom: Yup.boolean().optional(),
    bathroom: Yup.boolean().optional(),
    bedroom: Yup.boolean().optional(),
    kitchen: Yup.boolean().optional(),
    hallway: Yup.boolean().optional(),
    study: Yup.boolean().optional(),
    loft: Yup.boolean().optional(),
    conservatory: Yup.boolean().optional(),
    utilityRoom: Yup.boolean().optional(),
    outbuilding: Yup.boolean().optional(),
    other: Yup.boolean().optional(),
  })
  .test("surveyValidationTest", {}, (obj) => {
    if (Object.keys(obj).some((key) => obj[key] === true)) {
      return true;
    }

    return new Yup.ValidationError(
      "Please select at least one option",
      null,
      "sampleSurveyForm.livingRoom"
    );
  });

const notRequiredWhenNotUsingCyberSource = (schema: any) =>
  Yup.lazy((_, { context: { origin, selectedPaymentMethodTab } }) => {
    if (
      (origin as ExternalOrigin) === "paypal" ||
      (selectedPaymentMethodTab as PaymentMethod) === "paypal" ||
      (selectedPaymentMethodTab as PaymentMethod) === "invoice"
    ) {
      return Yup.mixed().notRequired();
    }
    return schema;
  });

export const STANDARD_CHECKOUT_VALIDATION_SCHEMA = Yup.object().shape({
  customerDetails: CUSTOMER_DETAILS,
  deliveryAddress: ADDRESS,
  deliveryMethod: DELIVERY_METHOD,
  billingAddress: ADDRESS,
  payment: notRequiredWhenNotUsingCyberSource(PAYMENT),
});

export const SAMPLE_CHECKOUT_VALIDATION_SCHEMA = Yup.object().shape({
  customerDetails: CUSTOMER_DETAILS,
  deliveryAddress: ADDRESS,
  sampleSurveyForm: SAMPLE_SURVEY_FORM,
});
