import dayjs from "dayjs";
import { Logger } from "@ukfd/shared-utils";

interface TrackingData {
  event: string;
  [key: string]: string | number;
}

export const pushToDataLayer = (data: TrackingData) => {
  const dataLayer = window?.dataLayer;
  if (!dataLayer || !data) {
    return false;
  }
  dataLayer.push(data);
};

type DeliveryTrackingEventNames =
  | "delivery_method__type_selected"
  | "delivery_method__date_selected"
  | "delivery_method__time_selected"
  | "delivery_method__date_arrows_click"
  | "delivery_method__access_restrictions";

export const onDeliveryTrackingEvent = (
  event: DeliveryTrackingEventNames,
  data: string | null | undefined
) => {
  switch (event) {
    case "delivery_method__type_selected":
      if (data) {
        pushToDataLayer({
          event,
          delivery_method__selected_type: data,
        });
      }
      break;
    case "delivery_method__date_selected": {
      if (data) {
        try {
          const today = dayjs();
          const selected = dayjs(data);
          // "today" inclusive
          // i.e. if today is Tues and delivery is Thurs
          // dayjs will say the difference is 1 day
          // the expectation from data team is it should be 2 days
          const diff = selected.diff(today, "days") + 1;
          return pushToDataLayer({
            event,
            delivery_method__days_in_future: diff,
          });
        } catch (e: any) {
          Logger.error(e);
        }
      }
      break;
    }
    case "delivery_method__time_selected":
      if (data) {
        pushToDataLayer({
          event,
          delivery_method__selected_time: data,
        });
      }
      break;
    case "delivery_method__date_arrows_click":
      if (data) {
        pushToDataLayer({
          event,
          delivery_method__date_arrow: data,
        });
      }
      break;
    case "delivery_method__access_restrictions":
      if (data) {
        pushToDataLayer({
          event,
          delivery_method__access_restrictions: data,
        });
      }
      break;
  }
};
