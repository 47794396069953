import { useContext, useState } from "react";
import { useFormikContext } from "formik";
import { useMutation } from "@apollo/client";
import { CHECKOUT_APPLY_PROMO_MUTATION, gtmPromoCode } from "@ukfd/checkout-utils";
import { Button, NativeInput, Text } from "@ukfd/ui";
import { CheckoutContext } from "@context";
import s from "./PromotionalCode.module.css";

const PromotionalCode: React.FC = () => {
  const { values, setValues } = useFormikContext<FormikOrderDocument>();
  const [promotionalCode, setPromotionalCode] = useState("");
  const [hasSuccess, setHasSuccess] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);

  const {
    basket,
    onPromotionalCodeApplied,
    customer: { customerType },
  } = useContext(CheckoutContext);

  const [addPromoCodeToBasket, { loading, error }] = useMutation<{
    checkoutApplyPromoToBasket: Basket;
  }>(CHECKOUT_APPLY_PROMO_MUTATION, {
    variables: {
      id: basket.id,
      promocodesRequested: [promotionalCode],
      customerType,
    },
  });

  const applyPromotionalCode = async () => {
    const { data, errors } = await addPromoCodeToBasket();
    if (errors || error) {
      setHasSuccess(false);
      setHasFailed(true);
      return;
    }

    const updatedBasket = data?.checkoutApplyPromoToBasket;
    const promoSummary = updatedBasket?.promo?.summary.find(({ code }) => code === promotionalCode);
    const hasBeenApplied = !!promoSummary?.isValid;

    if (hasBeenApplied) {
      gtmPromoCode("apply", promotionalCode);
      gtmPromoCode("validate", promotionalCode, true);
      onPromotionalCodeApplied(updatedBasket!);
      setValues({
        ...values,
        promotionalCodes: [promotionalCode],
      });
      setPromotionalCode("");
      setHasSuccess(true);
      setHasFailed(false);
    } else {
      gtmPromoCode("apply", promotionalCode);
      gtmPromoCode("validate", promotionalCode, false);
      setPromotionalCode("");
      setHasSuccess(false);
      setHasFailed(true);
    }
  };

  return (
    <>
      <Text className={s.text} variant="asideHeading">
        Add a promotion code
      </Text>
      <div className={s.container}>
        <NativeInput
          className="data-hj-allow"
          data-testid="promotionalCodeInput"
          name="promotionalCode"
          value={promotionalCode}
          onFocus={() => gtmPromoCode("click")}
          onChange={(e) => {
            const code = e.target.value;
            setPromotionalCode(code.toUpperCase());
          }}
        />
        <Button
          className={s.button}
          disabled={loading || !promotionalCode}
          loading={loading}
          variant="secondary"
          onClick={() => applyPromotionalCode()}
        >
          Apply
        </Button>
        {hasSuccess && basket.promo !== null && (
          <div className="border-green border-2 bg-accent-1 mt-5 p-3 text-green text-sm">
            Promotional code successfully added to your order.
          </div>
        )}

        {hasFailed && (
          <div className="border-red border-2 bg-accent-1 mt-5 p-3 text-red text-sm">
            The given promotional code was not valid.
          </div>
        )}
      </div>
    </>
  );
};

export default PromotionalCode;
