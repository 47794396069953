const mappedRollWidthOptions = (
  rollWidthOptions: number[] | null
): { text: string; value: string; isDisabled?: boolean }[] | null => {
  if (!rollWidthOptions || !Array.isArray(rollWidthOptions)) {
    return null;
  }
  const defaultOptions = { text: "Width (m)", value: "width", isDisabled: true };
  const options = rollWidthOptions?.map((option) => {
    return {
      text: String(option),
      value: String(option),
    };
  });
  options.unshift(defaultOptions);
  return options;
};

export default mappedRollWidthOptions;
