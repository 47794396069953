import { useContext, useEffect, useState } from "react";
import Script from "next/script";
import clsx from "clsx";
import CheckIcon from "@heroicons/react/solid/CheckIcon";
import XIcon from "@heroicons/react/solid/XIcon";
import { CyberSourceInit } from "@ukfd/checkout-utils";
import { Alert, Input, LoadingDots } from "@ukfd/ui";
import { CheckoutContext, UiStateContext } from "@context";
import { AddressFormContainer, BillingAddressForm, ExpiryDate } from "@components";
import { ReactComponent as Mastercard } from "@images/Mastercard.svg";
import { ReactComponent as VisaDebit } from "@images/VISA-Debit.svg";
import { ReactComponent as Visa } from "@images/VISA.svg";
import s from "./PaymentForm.module.css";

const PaymentForm: React.FC = () => {
  const [isErrorState, setIsErrorState] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {
    uiState: { microform, tokenisationErrors },
    setUiState,
  } = useContext(UiStateContext);

  const { token } = useContext(CheckoutContext);

  const handlePaymentFormStateChanged = (e: MessageEvent<MicroformMessageEvent>) => {
    const { data } = e;

    if (!data.event || !["change"].includes(data.event)) {
      return;
    }

    const { valid, empty, card, couldBeValid } = data.data;

    if (couldBeValid || empty) {
      return setUiState({ tokenisationErrors: [] });
    }

    if (valid) {
      return setUiState({ tokenisationErrors: [] });
    }

    // This appears to be the only means of knowing if the user is
    // typing in the Card Number field
    if (card) {
      setUiState({
        tokenisationErrors: [
          {
            location: "number",
            message: "Please enter a valid Card Number from one of our accepted cards",
          },
        ],
      });
      return;
    }
    setUiState({
      tokenisationErrors: [
        {
          location: "securityCode",
          message: "Please enter a valid Security Code (CVV)",
        },
      ],
    });
  };

  useEffect(() => {
    global.addEventListener("message", handlePaymentFormStateChanged);
    return () => {
      global.removeEventListener("message", handlePaymentFormStateChanged);
    };
  }, []);

  const hasFailedToLoad = !isLoading && (isErrorState || !microform);
  const fieldHasError = (fieldName: string) =>
    tokenisationErrors?.findIndex((error) => error.location === fieldName) !== -1;

  return (
    <>
      <Script
        src="https://flex.cybersource.com/cybersource/assets/microform/0.11/flex-microform.min.js"
        strategy="afterInteractive"
        onError={() => {
          setIsLoading(false);
          setIsErrorState(true);
          setUiState({ microform: undefined });
        }}
        onLoad={() => {
          setIsLoading(false);
          setUiState({ microform: CyberSourceInit(token) });
        }}
      />

      {hasFailedToLoad && (
        <Alert
          id="payment-error"
          message="Unable to initialise payment form"
          title="Something went wrong!"
        />
      )}

      {microform && (
        <>
          <div>
            <label className={s.formLabel}>Card Number *</label>
            <div className={s.iframeContainer}>
              <div
                id="card-number-container"
                className={clsx(s.cardNumberContainer, "flex-microform-container", {
                  [s.errorContainer]: hasFailedToLoad || fieldHasError("number"),
                })}
              />
              <div className={clsx("flex-microform-indicator", s.iconOuterContainer)}>
                <div className={clsx(s.iconContainer)}>
                  {fieldHasError("number") ? (
                    <XIcon
                      className={clsx("flex-microform-indicator-invalid", s.icon, s.iconInvalid)}
                    />
                  ) : (
                    <CheckIcon
                      className={clsx("flex-microform-indicator-valid", s.icon, s.iconValid)}
                    />
                  )}
                </div>
              </div>

              {isLoading && <LoadingDots />}
            </div>
            {hasFailedToLoad && (
              <span className={s.errorText}>Unable to initialise card number field.</span>
            )}
            {!hasFailedToLoad && fieldHasError("number") && (
              <span className={s.errorText}>Please enter a valid Card Number</span>
            )}
            <div className={s.cardLogoContainer}>
              <Visa title="Visa logo" />
              <VisaDebit title="Visa debit logo" />
              <Mastercard title="Mastercard logo" />
            </div>
            <p className={s.acceptedCards}>Accepted debit and credit card types</p>
          </div>

          <div className={s.expiration}>
            <ExpiryDate
              id="expiry-date"
              label="Expiry Date"
              monthName="payment.expirationMonth"
              yearName="payment.expirationYear"
              required
            />
          </div>

          <div>
            <label className={s.formLabel}>Security code (CVV) *</label>
            <div className={s.iframeContainer}>
              <div className={s.cvvNumberContainer}>
                <div
                  id="cvv-number-container"
                  className={clsx("flex-microform-container", {
                    [s.errorContainer]: hasFailedToLoad || fieldHasError("securityCode"),
                  })}
                />
                <div className={clsx("flex-microform-indicator", s.iconOuterContainer)}>
                  <div className={clsx(s.iconContainer)}>
                    {fieldHasError("securityCode") ? (
                      <XIcon
                        className={clsx("flex-microform-indicator-invalid", s.icon, s.iconInvalid)}
                      />
                    ) : (
                      <CheckIcon
                        className={clsx("flex-microform-indicator-valid", s.icon, s.iconValid)}
                      />
                    )}
                  </div>
                </div>
              </div>
              {isLoading && <LoadingDots />}
            </div>
            {hasFailedToLoad && (
              <span className={s.errorText}>Unable to initialise CVV number field.</span>
            )}
            {!hasFailedToLoad && fieldHasError("securityNumber") && (
              <span className={s.errorText}>Please enter a valid Security Code (CVV)</span>
            )}
          </div>

          <Input label="Name on Card" name="payment.nameOnCard" required />

          <AddressFormContainer form={BillingAddressForm} />
        </>
      )}
    </>
  );
};

export default PaymentForm;
