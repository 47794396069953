import { useContext } from "react";
import { useFormikContext } from "formik";
import clsx from "clsx";
import Bugsnag from "@bugsnag/js";
import type { Address as PayPalAddress } from "@paypal/paypal-js/types/apis/commons";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PAYPAL_CLIENT_ID, scrollFirstErrorIntoView } from "@ukfd/checkout-utils";
import { EMPTY_ORDER_DOCUMENT } from "@ukfd/constants";
import { Logger, safelyRound, scrollIntoView } from "@ukfd/shared-utils";
import { CheckoutContext, ExternalOrderDetailsContext, UiStateContext } from "@context";
import s from "./PayWithPaypalButton.module.css";
import { PayWithPaypalButtonProps } from "./PayWithPaypalButton.types";

const PayWithPaypalButton: React.FC<PayWithPaypalButtonProps> = ({
  className,
  hidden = false,
  trySubmitImmediately = false,
}) => {
  const { setValues, validateForm, submitForm } = useFormikContext<FormikOrderDocument>();
  const { setOrderDetails } = useContext(ExternalOrderDetailsContext);
  const { setUiState } = useContext(UiStateContext);
  const {
    basket: { promo, price, id: basketId },
    delivery: { deliveryCost, deliveryPrice, isCollection },
  } = useContext(CheckoutContext);

  const deliveryAmount = isCollection
    ? 0
    : deliveryPrice || // Actual delivery price
      deliveryCost; // Predicted delivery price based on basket weight

  const total = (promo?.subtotal || price.subtotal || 0) + (deliveryAmount || 0);

  const toAddress = (paypalAddress: PayPalAddress | undefined): FormikAddress | null => ({
    addressLine1: paypalAddress?.address_line_1 || "",
    addressLine2: paypalAddress?.address_line_2 || "",
    city: paypalAddress?.admin_area_1 || "",
    county: paypalAddress?.admin_area_2 || "",
    country: paypalAddress?.country_code || "GB",
    postcode: paypalAddress?.postal_code || "",
  });

  return (
    <div className={clsx(className, { [s.hide]: hidden })}>
      <PayPalScriptProvider
        options={{
          "client-id": PAYPAL_CLIENT_ID,
          currency: "GBP",
          intent: "authorize",
          commit: false,
        }}
      >
        <PayPalButtons
          forceReRender={[total]}
          createOrder={(_, actions) =>
            actions.order.create({
              intent: "AUTHORIZE",
              purchase_units: [
                {
                  amount: {
                    value: safelyRound(total).toString(),
                  },
                },
              ],
            })
          }
          style={{
            layout: "horizontal",
            tagline: false,
            height: 46,
            label: "checkout",
          }}
          onApprove={async ({ orderID }, actions) => {
            Bugsnag.leaveBreadcrumb(`Order Id is ${orderID}`);
            return actions.order?.authorize().then(async (authorisation) => {
              // Its necessary to have logic for processing PayPal response in the set state
              // callback because PayPal is memoising the callback (this function)
              // resulting in an out-of-date state
              setValues((values) => {
                const authorisationId = authorisation.purchase_units[0].payments
                  ?.authorizations?.[0].id as string;

                const deliveryAddresses = authorisation.purchase_units
                  ?.map((unit) => toAddress(unit.shipping?.address))
                  .filter((x) => !!x?.addressLine1);

                const deliveryAddress =
                  deliveryAddresses?.[0] ?? EMPTY_ORDER_DOCUMENT.deliveryAddress;
                const billingAddress =
                  deliveryAddresses?.[0] ?? EMPTY_ORDER_DOCUMENT.billingAddress;

                const customerDeliveryAddress: FormikAddress = {
                  id: "",
                  addressLine1: values.deliveryAddress.addressLine1 || deliveryAddress.addressLine1,
                  addressLine2: values.deliveryAddress.addressLine2 || deliveryAddress.addressLine2,
                  city: values.deliveryAddress.city || deliveryAddress.city,
                  county: values.deliveryAddress.county || deliveryAddress.county,
                  country: values.deliveryAddress.country || deliveryAddress.country,
                  postcode: values.deliveryAddress.postcode || deliveryAddress.postcode,
                };

                const customerBillingAddress: FormikAddress = {
                  id: "",
                  addressLine1: values.billingAddress.addressLine1 || billingAddress.addressLine1,
                  addressLine2: values.billingAddress.addressLine2 || billingAddress.addressLine2,
                  city: values.billingAddress.city || billingAddress.city,
                  county: values.billingAddress.county || billingAddress.county,
                  country: values.billingAddress.country || billingAddress.country,
                  postcode: values.billingAddress.postcode || billingAddress.postcode,
                };

                const customerDetails: CustomerDetails = {
                  firstName:
                    values.customerDetails.firstName || authorisation.payer.name?.given_name || "",
                  lastName:
                    values.customerDetails.lastName || authorisation.payer.name?.surname || "",
                  emailAddress:
                    values.customerDetails.emailAddress || authorisation.payer.email_address || "",
                  phoneNumber:
                    values.customerDetails.phoneNumber ||
                    (authorisation as any).payer.phone?.phone_number?.national_number ||
                    "",
                };

                setOrderDetails({
                  externalOrderId: orderID,
                  authorisationId: authorisationId,
                  deliveryAddress: customerDeliveryAddress,
                  billingAddress: customerBillingAddress,
                  customerDetails,
                  origin: "paypal",
                });

                Bugsnag.leaveBreadcrumb(`Paypal Authorisation Id: ${authorisationId}`);
                Bugsnag.leaveBreadcrumb(`Basket Id: ${basketId}`);

                return {
                  ...values,
                  deliveryAddress: customerDeliveryAddress,
                  billingAddress: customerBillingAddress,
                  customerDetails,
                };
              }, true);

              if (!(await scrollFirstErrorIntoView())) {
                if (!trySubmitImmediately) {
                  scrollIntoView("delivery-method");
                }
              }

              setUiState({
                selectedPaymentMethodTab: "paypal",
                arePaymentMethodTabsHidden: true,
                isCopyingDeliveryAddress: true,
              });
              validateForm();

              if (trySubmitImmediately) {
                // UKFD-25 On Safari Mac and iOS, the formik submitForm function does not work without a timeout
                window.setTimeout(() => {
                  submitForm();
                }, 50);
              }

              if (!orderID) {
                Logger.error(new Error(`There is no Paypal OrderId`));
              }
            });
          }}
          onClick={() => {
            window.dataLayer?.push({
              event: "paypal_checkout_button_clicked",
            });
          }}
        />
      </PayPalScriptProvider>
    </div>
  );
};

export default PayWithPaypalButton;
