import * as React from "react";

type UiState = {
  selectedPaymentMethodTab?: PaymentMethod;
  arePaymentMethodTabsHidden?: boolean;
  areDeliveryOptionsTabsHidden?: boolean;
  savedDeliveryMethod?: string;
  savedDeliveryDate?: string;
  deliveryAddressMode?: AddressMode;
  microform?: Microform;
  tokenisationErrors?: MicroformTokeniseErrorDetail[];
  processingError?: string;
  isCopyingDeliveryAddress?: boolean;
};

type UiStateProvider = {
  uiState: UiState;
  setUiState: (uiState: UiState) => void;
};

const DEFAULT_STATE: UiState = {
  microform: {} as Microform,
  tokenisationErrors: [],
  isCopyingDeliveryAddress: true,
};

export const UiStateContext = React.createContext<UiStateProvider>({
  uiState: DEFAULT_STATE,
} as UiStateProvider);

const UiStateProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, setState] = React.useState<UiState>(DEFAULT_STATE);

  return (
    <UiStateContext.Provider
      value={{
        uiState: state,
        setUiState: (newUiState) =>
          setState((previousState) => ({ ...previousState, ...newUiState })),
      }}
    >
      {children}
    </UiStateContext.Provider>
  );
};

export default UiStateProvider;
