import { Text } from "@ukfd/ui";
import s from "./Collection.module.css";
import { CollectionProps } from "./Collection.types";

const Collection: React.FC<CollectionProps> = () => (
  <div className={s.root}>
    <Text className={s.collection} variant="body">
      Collection from our warehouse in Coventry:
    </Text>
    <br></br>
    <Text variant="body">Unit H4 Pilgrims Walk,</Text>
    <Text variant="body">Prologis Park,</Text>
    <Text variant="body">Coventry,</Text>
    <Text variant="body">Warks CV6 4QG</Text>
    <br></br>
    <Text variant="body">We will contact you when your order is ready to collect.</Text>
  </div>
);
export default Collection;
