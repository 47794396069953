import { useContext, useEffect } from "react";
import { useRouter } from "next/router";
import { Form, Formik, yupToFormErrors } from "formik";
import Bugsnag from "@bugsnag/js";
import to from "await-to-js";
import Cookies from "js-cookie";
import {
  FEATURE_RELATED_PRODUCTS,
  SESSION_STORAGE_ORDER_KEY,
  STANDARD_CHECKOUT_VALIDATION_SCHEMA,
  UKFD_COOKIES,
  VOID_PAYMENT_MUTATION,
  paymentFlowLogger,
  scrollFirstErrorIntoView,
  ukfdGraph,
} from "@ukfd/checkout-utils";
import { getInitialCheckoutValues } from "@ukfd/constants";
import { Logger, logGraphQLErrors } from "@ukfd/shared-utils";
import { Section, Text } from "@ukfd/ui";
import { CheckoutContext, ExternalOrderDetailsContext, UiStateContext } from "@context";
import { useOrderDocument, useTryValidateOrder } from "@hooks";
import {
  AddressSelector,
  BasketLayout,
  CustomerDetails,
  DeliveryMethodContainer,
  MessageCentre,
  OrderSummary,
  PaymentOptions,
  PlaceOrderButton,
  PromotionalCode,
  RelatedProductsContainer,
  ValidationSummary,
} from "@components";

const StandardCheckout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const router = useRouter();

  const {
    orderDetails: { origin, authorisationId, externalOrderId },
  } = useContext(ExternalOrderDetailsContext);

  const { basket, customer } = useContext(CheckoutContext);

  const {
    uiState: { deliveryAddressMode, selectedPaymentMethodTab, microform, isCopyingDeliveryAddress },
    setUiState,
  } = useContext(UiStateContext);

  const { tryValidateOrder } = useTryValidateOrder();
  const { createOrderDocument } = useOrderDocument();

  useEffect(() => {
    const voidPayment = async () => {
      const hasPreventDoubleRefreshCookie = Cookies.get(UKFD_COOKIES.PAYMENT_ATTEMPTED);
      if (hasPreventDoubleRefreshCookie) {
        // Customer has refreshed during the payment journey and might have already paid
        // Attempt to void payment
        Cookies.remove(UKFD_COOKIES.PAYMENT_ATTEMPTED);

        const data: SavedOrderDocument = JSON.parse(
          sessionStorage.getItem(SESSION_STORAGE_ORDER_KEY)!
        );

        if (!data) {
          return;
        }

        const [error, result] = await to(
          ukfdGraph<VoidPaymentMutation, VoidPaymentVariables>(VOID_PAYMENT_MUTATION, {
            orderId: data.orderId!,
          })
        );

        logGraphQLErrors(error, result);
      }
    };
    voidPayment();
  }, []);

  return (
    <Formik
      initialValues={getInitialCheckoutValues({ customer })}
      validateOnMount={true}
      validate={async (values) =>
        STANDARD_CHECKOUT_VALIDATION_SCHEMA.validate(values, {
          abortEarly: false,
          context: { origin, selectedPaymentMethodTab },
        })
          .then(() => yupToFormErrors({}))
          .catch((err) => yupToFormErrors(err))
      }
      onSubmit={(values) => {
        return new Promise((resolve, reject) => {
          setUiState({ tokenisationErrors: [] });

          const orderDocument = createOrderDocument(values);

          const savedOrderDocument: SavedOrderDocument = {
            ...orderDocument,
            basket: {
              ...orderDocument.basket,
              items: basket.basketItems,
            },
            payment: {
              ...values.payment,
            },
            promo: basket.promo,
            price: basket.price,
            deliveryAddressMode,
            isCopyingDeliveryAddress,
          };

          const validateOrder = async () => {
            const { success, data } = await tryValidateOrder(orderDocument, savedOrderDocument);
            if (!success) {
              (data as Error[]).forEach((error) => Logger.error(error));
              setUiState({
                processingError: "order validation failed",
              });
              reject();
            }
            return success;
          };

          if (origin === "paypal") {
            paymentFlowLogger({ step: "start-order" });

            Bugsnag.leaveBreadcrumb(
              `Starting PayPal order for payment amount of: ${JSON.stringify(
                savedOrderDocument?.basket?.summary || {}
              )}`
            );
            Bugsnag.leaveBreadcrumb(
              `PayPal order has the following basket price data: ${JSON.stringify(basket.price)}`
            );

            if (basket.promo) {
              Bugsnag.leaveBreadcrumb(
                `PayPal order has the following promo data: ${JSON.stringify(basket.promo)}`
              );
            }

            validateOrder().then(async (isValid) => {
              if (isValid) {
                paymentFlowLogger({ step: "validate-order-success" });
                await router.push(
                  `/order/ProcessOrderByPaypal?externalOrderId=${externalOrderId}&authorisationId=${authorisationId}`
                );
                resolve(null);
              } else {
                paymentFlowLogger({ step: "validate-order-fail" });
                Logger.error(new Error("Paypal Order Validation failed"), { severity: 1 });
              }
            });
          }

          if (values.payment.type === "invoice") {
            validateOrder().then(async (isValid) => {
              if (isValid) {
                await router.push(`/order/ProcessOrder`);
                resolve(null);
              } else {
                Logger.error(new Error("Invoice Order Validation failed"), { severity: 1 });
              }
            });
          }

          if (values.payment.type === "card") {
            paymentFlowLogger({ step: "start-order" });
            const { expirationMonth, expirationYear } = values.payment;
            const options: MicroformOptions = {
              expirationMonth,
              expirationYear: (+(expirationYear || "0") + 2000).toString(),
            };

            Bugsnag.leaveBreadcrumb(
              `Starting card order for payment amount of: ${JSON.stringify(
                savedOrderDocument?.basket?.summary || {}
              )}`
            );
            Bugsnag.leaveBreadcrumb(
              `Card order has the following basket price data: ${JSON.stringify(basket.price)}`
            );

            if (basket.promo) {
              Bugsnag.leaveBreadcrumb(
                `Card order has the following promo data: ${JSON.stringify(basket.promo)}`
              );
            }

            paymentFlowLogger({ step: "attempt-tokenise-card" });

            microform?.createToken(
              options,
              async (err: MicroformTokeniseError, createdToken: string) => {
                if (err) {
                  paymentFlowLogger({ step: "tokenise-card-fail" });
                  Logger.error(new Error("Failed tokenising card"), err);
                  setUiState({
                    tokenisationErrors: err.details.length
                      ? err.details
                      : [{ message: err.message, location: "number" }],
                  });
                  scrollFirstErrorIntoView();
                  reject();
                  return;
                }

                paymentFlowLogger({ step: "tokenise-card-success" });

                const isValid = await validateOrder();
                if (isValid) {
                  paymentFlowLogger({ step: "validate-order-success" });
                  await router.push(`/order/ProcessOrderByCardPayment?token=${createdToken}`);
                  resolve(null);
                } else {
                  paymentFlowLogger({ step: "validate-order-fail" });
                  Logger.error(new Error("Card Order Validation failed"), { severity: 1 });
                }
              }
            );
          }
        });
      }}
    >
      <BasketLayout
        aside={
          <div className="bg-white p-5 gap-3">
            <OrderSummary />
            <PromotionalCode />
          </div>
        }
      >
        <MessageCentre />

        <Form>
          <Section>
            <Text variant="sectionHeading">Shopping Basket</Text>
            {children}
          </Section>

          <Section>
            <CustomerDetails title="Customer Details" />
          </Section>

          <Section>
            <AddressSelector />
          </Section>

          <Section>
            <DeliveryMethodContainer />
          </Section>

          <Section>
            <PaymentOptions />
          </Section>

          <ValidationSummary />

          <PlaceOrderButton />
        </Form>
        {FEATURE_RELATED_PRODUCTS === "true" && <RelatedProductsContainer />}
      </BasketLayout>
    </Formik>
  );
};

export default StandardCheckout;
