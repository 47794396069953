import { DeepPartial } from "ts-essentials";
import { Logger } from "@ukfd/shared-utils";
import { gtmFormatProductUpdate } from "../gtm/gtmFormatters";

const gtmCurrency = "GBP";

type DeepProducts = DeepPartial<GtmProduct & { id: GtmString }>[];

type GtmCheckoutRemove = {
  event: "CheckoutRemove";
  ecommerce_action: {
    currencyCode: "GBP";
    remove: {
      actionField: {
        list: "Shopping cart";
      };
      products: DeepProducts;
    };
  };
};

type GtmCheckoutIncrease = {
  event: "IncreaseItemQty";
  ecommerce_action: {
    currencyCode: "GBP";
    add: {
      products: DeepProducts;
    };
  };
};

type GtmCheckoutDecrease = {
  event: "DecreaseItemQty";
  ecommerce_action: {
    currencyCode: "GBP";
    remove: {
      products: DeepProducts;
    };
  };
};

// Need to define the GTM shape in typescript, and then test
const gtmCheckoutUpdate = (event: string, product: Product): void | null => {
  if (!event || !product) {
    Logger.error(
      new Error(`You must pass an event name & product data when using gtmCheckoutUpdate()`)
    );
    return;
  }

  let data: GtmCheckoutRemove | GtmCheckoutIncrease | GtmCheckoutDecrease | null = null;

  switch (event) {
    case "remove":
      data = {
        event: "CheckoutRemove",
        ecommerce_action: {
          currencyCode: gtmCurrency,
          remove: {
            actionField: {
              list: "Shopping cart",
            },
            products: [gtmFormatProductUpdate(product)],
          },
        },
      };
      break;
    case "increase":
      data = {
        event: "IncreaseItemQty",
        ecommerce_action: {
          currencyCode: gtmCurrency,
          add: {
            products: [gtmFormatProductUpdate(product)],
          },
        },
      };
      break;
    case "decrease":
      data = {
        event: "DecreaseItemQty",
        ecommerce_action: {
          currencyCode: gtmCurrency,
          remove: {
            products: [gtmFormatProductUpdate(product)],
          },
        },
      };
  }

  if (!data) {
    return null;
  }

  /* Required for clearing persistent data before the next push */
  window?.dataLayer?.push({ ecommerce_action: null });

  return window.dataLayer?.push(data);
};

export default gtmCheckoutUpdate;
