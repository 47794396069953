import { gql } from "@apollo/client";

export const CUSTOMER_DETAILS_FIELDS = gql`
  fragment CustomerDetails on Customer {
    customerId
    customerIdName
    firstName
    lastName
    customerType
    createdAt
    email
    telephoneNumber
    priceLevel
    tradeInformation {
      customerTradeSector
    }
    deliveryAddress {
      id
      isDefaultShipping
      isDefaultBilling
      label
      postcode
      firstLine
      secondLine
      city
      state
      country
    }
    billingAddress {
      id
      isDefaultShipping
      isDefaultBilling
      label
      postcode
      firstLine
      secondLine
      city
      state
      country
    }
  }
`;
