import Bugsnag from "@bugsnag/js";
import { Logger } from "@ukfd/shared-utils";

export const setSessionStorageItem = (key: string, value: any) => {
  if (typeof window === undefined) {
    return;
  }

  sessionStorage.setItem(key, JSON.stringify(value));
};

export const deleteSessionStorageItem = (key: string) => {
  if (typeof window === undefined) {
    return;
  }

  sessionStorage.removeItem(key);
};

export const getSessionStorageItem = (key: string) => {
  if (typeof window === undefined) {
    return;
  }

  return sessionStorage.getItem(key);
};

/**
 * Wait for a value in the session storage to verify it is set
 */
export const asyncCheckSessionStorage = async (ms: number, key: string, targetValue: string) => {
  let limit = 10;
  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      const valueInStorage = getSessionStorageItem(key);
      if (valueInStorage === targetValue) {
        resolve(valueInStorage);
        clearInterval(interval);
      } else if (limit <= 1) {
        reject();
        Bugsnag.leaveBreadcrumb(
          `Error in asyncCheckSessionStorage, limit of retries has been exceeded for key ${key}`
        );
        Logger.error(
          new Error(
            `Error in asyncCheckSessionStorage, limit of retries has been exceeded for key ${key}`
          ),
          {
            severity: 1,
          }
        );
        clearInterval(interval);
      }
      limit--;
    }, ms);
  });
};
