import { gql } from "@apollo/client";
import { CUSTOMER_DETAILS_FIELDS } from "./fragments/customer";
import { PRICE_FIELDS } from "./fragments/price";
import { PRODUCT_DETAILS_FIELDS } from "./fragments/product";
import { PROMO_FIELDS } from "./fragments/promo";

export const CHECKOUT_QUERY = gql`
  ${PRODUCT_DETAILS_FIELDS}
  ${CUSTOMER_DETAILS_FIELDS}
  ${PRICE_FIELDS}
  ${PROMO_FIELDS}
  query checkout(
    $id: String!
    $promocodesRequested: [String!]!
    $customerType: String!
    $customerId: String
  ) {
    checkoutGetPage(
      id: $id
      promocodesRequested: $promocodesRequested
      customerType: $customerType
      customerId: $customerId
    ) {
      basket {
        id
        basketItems {
          basketItemId
          sku
          quantity
          details {
            ...ProductDetails
          }
          price {
            ...Price
          }
        }
        itemCount
        price {
          ...Price
        }
        promo {
          ...Promo
        }
      }
      paymentOptions {
        retail {
          method
        }
        trade {
          method
        }
      }
      paymentToken
      delivery {
        basketWeight
        deliveryCost
      }
      customerDetails {
        ...CustomerDetails
      }
    }
  }
`;

export const CHECKOUT_APPLY_PROMO_MUTATION = gql`
  ${PRODUCT_DETAILS_FIELDS}
  ${PRICE_FIELDS}
  ${PROMO_FIELDS}
  mutation checkoutApplyPromo(
    $id: String!
    $promocodesRequested: [String!]!
    $customerType: String!
  ) {
    checkoutApplyPromoToBasket(
      id: $id
      promocodesRequested: $promocodesRequested
      customerType: $customerType
    ) {
      id
      itemCount
      price {
        ...Price
      }
      basketItems {
        basketItemId
        sku
        quantity
        details {
          ...ProductDetails
        }
        price {
          ...Price
        }
      }
      promo {
        ...Promo
      }
    }
  }
`;

export const CHECKOUT_CREATE_BASKET = gql`
  mutation checkoutCreateBasket(
    $customerId: String
    $customerType: String
    $basketItems: [BasketItemArgs!]!
  ) {
    checkoutCreateBasket(
      customerId: $customerId
      customerType: $customerType
      basketItems: $basketItems
    ) {
      basket {
        id
        basketItems {
          sku
          quantity
          details {
            title
            sku
          }
        }
      }
      paymentOptions {
        retail {
          method
        }
        trade {
          method
        }
      }
    }
  }
`;
