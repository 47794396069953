import gtmCheckoutUpdate from "../gtmCheckoutUpdate";

const gtmHandleIncreaseOrDecrease = ({
  product,
  basketItems,
  updatedBasketItems,
}: {
  product: Product;
  basketItems: Product[];
  updatedBasketItems: Product[];
}): void | null => {
  const currentProduct = basketItems.find((item) => item.sku === product.sku);
  const updatedProduct = updatedBasketItems.find((item) => item.sku === product.sku);

  if (currentProduct?.quantity && updatedProduct?.quantity) {
    const hasIncreased = currentProduct.quantity < updatedProduct.quantity;
    const hasDecreased = currentProduct.quantity > updatedProduct.quantity;

    if (hasIncreased) {
      return gtmCheckoutUpdate("increase", updatedProduct);
    }
    if (hasDecreased) {
      return gtmCheckoutUpdate("decrease", updatedProduct);
    }
  }
};

export default gtmHandleIncreaseOrDecrease;
