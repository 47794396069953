import { FC, useState } from "react";
import TrashIcon from "@heroicons/react/solid/TrashIcon";
import {
  HOME_URL,
  preventDecimalValuesForAccessories,
  preventValuesLessThanOne,
} from "@ukfd/checkout-utils";
import {
  formatAsCurrency,
  getAnchorElementFromClick,
  getCheckoutRequiredData,
  onLinkClickForwardWithURLParams,
} from "@ukfd/shared-utils";
import { Button, LoadingSpinner, NativeInput } from "@ukfd/ui";
import s from "./BasketItem.module.css";
import type { BasketItemProps } from "./BasketItem.types";

const BasketItem: FC<BasketItemProps> = ({
  altText,
  imageUrl,
  title,
  totalPrice,
  squareMeterPrice,
  squareMeterValue,
  packValue,
  packPrice,
  onChange,
  onRemove,
  productClass,
  url,
}) => {
  const isProduct = productClass === "product";
  const isAccessory = productClass === "accessory";

  if (isAccessory && Number.isInteger(squareMeterValue) === false) {
    const value = preventDecimalValuesForAccessories(String(squareMeterValue));
    onChange?.(preventValuesLessThanOne(value));
  }

  const [sqmInput, setSqmInput] = useState(String(squareMeterValue));
  const [isLoading, setIsLoading] = useState(false);

  const itemURL = `${HOME_URL}/${url}`;

  return (
    <div className={s.root} data-testid="basket-item-pack">
      {isLoading && <LoadingSpinner />}
      {imageUrl && (
        <a
          href={itemURL}
          onClick={(e) => {
            e.preventDefault();
            const checkoutData = getCheckoutRequiredData();
            const target = getAnchorElementFromClick(e.target as HTMLElement);
            onLinkClickForwardWithURLParams(target as HTMLElement, checkoutData);
          }}
        >
          <img
            alt={altText}
            className={s.image}
            height={400}
            loading="lazy"
            src={imageUrl.replace("Data Feed", "Data_Feed")}
            width={600}
          />
        </a>
      )}
      <div className={s.productDetailsWrapper}>
        <div className={s.productDetails}>
          {title && (
            <a className={s.title} href={itemURL}>
              {title}
            </a>
          )}
        </div>
        <div className={s.prices}>
          {totalPrice && (
            <p className={s.totalPrice} data-testid="basket-item-pack-total-price">
              {formatAsCurrency(totalPrice)}
            </p>
          )}
          {isProduct && squareMeterPrice && (
            <p className={s.squareMeterPrice} data-testid="basket-item-pack-sqm-price">
              Per sq m. {formatAsCurrency(squareMeterPrice)}
            </p>
          )}
          {packPrice && (
            <p className={s.packPrice} data-testid="basket-item-pack-price">
              Per pack {formatAsCurrency(packPrice)}
            </p>
          )}
        </div>
      </div>
      <div className={s.units}>
        <div className={s.inputWrapper}>
          <NativeInput
            className={s.input}
            inputMode={isProduct ? "decimal" : "numeric"}
            label="Sq m"
            labelClassName={s.inputLabel}
            name="sqm-input"
            step={isProduct ? "0.1" : "1"}
            type="number"
            value={sqmInput}
            onBlur={(e) => {
              if (e.target.value === "") {
                setSqmInput(preventValuesLessThanOne("1"));
                onChange?.(preventValuesLessThanOne("1"));
              }
            }}
            onChange={(e) => {
              const value = isAccessory
                ? preventDecimalValuesForAccessories(e.target.value)
                : e.target.value;
              setSqmInput(preventValuesLessThanOne(value));
              onChange?.(preventValuesLessThanOne(value));
            }}
          />
          {isProduct && (
            <p className={s.sqm} data-testid="basket-item-sqm-to-pack-total">
              Square meters = {packValue} packs
            </p>
          )}
          <Button
            className={s.removeButton}
            data-testid="basket-item-pack-remove-button"
            variant="icon"
            onClick={() => {
              setIsLoading(true);
              onRemove?.();
            }}
          >
            <TrashIcon className={s.removeButtonIcon} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BasketItem;
