import { createContext, useState } from "react";

type ExternalOrderDetails = {
  deliveryAddress?: FormikAddress;
  billingAddress?: FormikAddress;
  customerDetails?: CustomerDetails;
  externalOrderId?: string;
  authorisationId?: string;
  origin?: ExternalOrigin;
};

type ExternalOrderDetailsContext = {
  orderDetails: ExternalOrderDetails;
  setOrderDetails: (orderDetails: ExternalOrderDetails) => void;
};

export const ExternalOrderDetailsContext = createContext<ExternalOrderDetailsContext>({
  orderDetails: {},
} as ExternalOrderDetailsContext);

const ExternalOrderDetailsProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [orderDetails, setOrderDetails] = useState<ExternalOrderDetails>({
    origin: "",
  } as ExternalOrderDetails);

  return (
    <ExternalOrderDetailsContext.Provider
      value={{
        orderDetails,
        setOrderDetails: (newOrderDetails) =>
          setOrderDetails((previousOrderDetails) => ({
            ...previousOrderDetails,
            ...newOrderDetails,
          })),
      }}
    >
      {children}
    </ExternalOrderDetailsContext.Provider>
  );
};

export default ExternalOrderDetailsProvider;
