import * as React from "react";
import { useFormikContext } from "formik";
import { formatAddressBookAddress } from "@ukfd/checkout-utils";
import { Checkbox, Input } from "@ukfd/ui";
import { AddressFinder, SelectCountryList } from "@components";
import s from "./BillingAddressForm.module.css";
import { BillingAddressFormProps } from "./BillingAddressForm.types";

const BillingAddressForm: React.FC<BillingAddressFormProps> = ({
  onAddressSelected,
  onCopyAddressChanged,
  isAddressBeingCopied,
}) => {
  const { values } = useFormikContext<FormikOrderDocument>();

  const {
    values: {
      billingAddress: { postcode },
    },
  } = useFormikContext<FormikOrderDocument>();

  const onBillingAddressSelected = (selectedAddress: FormikAddress) => {
    onAddressSelected({
      billingAddress: {
        ...selectedAddress,
      },
    });
  };

  const onGetPostCode = (postCodeToSet: string) => {
    onAddressSelected({
      billingAddress: {
        ...values.billingAddress,
        postcode: postCodeToSet,
      },
    });
  };

  return (
    <div className={s.root} data-testid="billingAddress.form">
      <div>
        <Checkbox
          checked={!!isAddressBeingCopied}
          data-dl="copy-delivery-address"
          data-testid="copy-delivery-address"
          label="Use delivery address as billing address"
          name="same-as-delivery-address"
          native
          onChange={(event) => onCopyAddressChanged(event.target.checked)}
        />
        {isAddressBeingCopied && (
          <p className="text-sm ml-6">
            {formatAddressBookAddress({
              firstLine: values.deliveryAddress.addressLine1,
              secondLine: values.deliveryAddress.addressLine2,
              city: values.deliveryAddress.city,
              postcode: values.deliveryAddress.postcode,
              country: values.deliveryAddress.country,
            } as Address)}
          </p>
        )}
      </div>
      {!isAddressBeingCopied && (
        <>
          <AddressFinder
            data-dl="billing-address-postcode"
            isChecked={isAddressBeingCopied}
            isDisabled={isAddressBeingCopied}
            label="Postcode"
            name="billingAddress.postcode"
            postCodeValue={postcode}
            onChange={onGetPostCode}
            onSelected={onBillingAddressSelected}
          />
          <Input
            autoComplete="address-line1"
            data-dl="billing-address-addressLine1"
            data-testid="billingAddress.addressLine1"
            disabled={isAddressBeingCopied}
            label="Address Line 1"
            name="billingAddress.addressLine1"
            required
          />
          <Input
            autoComplete="address-line2"
            data-dl="billing-address-addressLine2"
            data-testid="billingAddress.addressLine2"
            disabled={isAddressBeingCopied}
            label="Address Line 2"
            name="billingAddress.addressLine2"
          />
          <Input
            autoComplete="address-level2"
            data-dl="billing-address-city"
            data-testid="billingAddress.city"
            disabled={isAddressBeingCopied}
            label="City"
            maxLength={25}
            name="billingAddress.city"
            required
          />
          <Input
            data-dl="billing-address-county"
            data-testid="billingAddress.county"
            disabled={isAddressBeingCopied}
            label="County"
            name="billingAddress.county"
            required
          />
          <div>
            <SelectCountryList
              className="w-full"
              data-dl="billing-address-country"
              disabled={isAddressBeingCopied}
              label="Country"
              labelClassName={s.countryLabel}
              name="billingAddress.country"
              required
            />
          </div>
        </>
      )}
    </div>
  );
};

export default BillingAddressForm;
