import { gql } from "@apollo/client";

export const PRODUCT_DETAILS_FIELDS = gql`
  fragment ProductDetails on ProductDetails {
    productType
    productClass
    sku
    internalid
    url
    image
    title
    expectedRevenue
    packPrice
    m2Price
    m2PriceRetail
    m2PerPack
    weight
    prRating
    prCount
    expectedMargin
    matrixType
    isSampleItem
    isSpecialOrderItem
    isOnline
    isInactive
    isDiscontinued
    isGoogleFeed
    isSampleItem
    pageTitle
    metaTag
    boxHeight
    boxWidth
    boxDepth
    classTitle
    brand
    material
    finish
    colour
    thickness
    style
    installationTypes
    rollWidth
    rollWidthOptions
    paymentModel
    width
    packs
    m2TotalCoverage
    totalLinePrice
  }
`;
