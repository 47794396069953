import { useContext } from "react";
import { useFormikContext } from "formik";
import { Button, Input, Text } from "@ukfd/ui";
import { CheckoutContext, UiStateContext } from "@context";
import { AddressFinder, SelectCountryList } from "@components";
import s from "./DeliveryAddressForm.module.css";
import { DeliveryAddressFormProps } from "./DeliveryAddressForm.types";

const DeliveryAddressForm: React.FC<DeliveryAddressFormProps> = ({ onAddressSelected }) => {
  const { values } = useFormikContext<FormikOrderDocument>();

  const { customer } = useContext(CheckoutContext);
  const { setUiState } = useContext(UiStateContext);

  const {
    values: {
      deliveryAddress: { postcode },
    },
  } = useFormikContext<FormikOrderDocument>();

  const onDeliveryAddressSelected = (selectedAddress: FormikAddress) => {
    onAddressSelected({
      deliveryAddress: {
        ...selectedAddress,
      },
    });
  };

  const onGetPostCode = (postCodeToSet: string) => {
    onAddressSelected({
      deliveryAddress: {
        ...values.deliveryAddress,
        postcode: postCodeToSet,
      },
    });
  };

  return (
    <div className={s.root} data-testid="deliveryAddress.form">
      <Text id="delivery-address" variant="sectionHeading">
        Delivery Address
      </Text>
      {(customer?.deliveryAddress?.length || 0 > 0) && (
        <Button
          className={s.minimal}
          variant="minimal"
          onClick={() => setUiState({ deliveryAddressMode: "ADDRESS_BOOK" })}
        >
          Use address from your address book
        </Button>
      )}

      <AddressFinder
        data-dl="delivery-address-postcode"
        label="Postcode"
        name="deliveryAddress.postcode"
        postCodeValue={postcode}
        onChange={onGetPostCode}
        onSelected={onDeliveryAddressSelected}
      />
      <Input
        autoComplete="address-line1"
        data-dl="delivery-address-addressLine1"
        data-testid="deliveryAddress.addressLine1"
        label="Address Line 1"
        name="deliveryAddress.addressLine1"
        required
      />
      <Input
        autoComplete="address-line2"
        data-dl="delivery-address-addressLine2"
        data-testid="deliveryAddress.addressLine2"
        label="Address Line 2"
        name="deliveryAddress.addressLine2"
      />
      <Input
        autoComplete="address-level2"
        data-dl="delivery-address-city"
        data-testid="deliveryAddress.city"
        label="City"
        maxLength={25}
        name="deliveryAddress.city"
        required
      />
      <Input
        data-dl="delivery-address-county"
        data-testid="deliveryAddress.county"
        label="County"
        name="deliveryAddress.county"
        required
      />
      <div>
        <SelectCountryList
          className="w-full"
          data-dl="delivery-address-country"
          label="Country"
          labelClassName={s.countryLabel}
          name="deliveryAddress.country"
          required
        />
      </div>
    </div>
  );
};

export default DeliveryAddressForm;
