import { FC, useEffect, useState } from "react";
import {
  LOQATE_API_KEY,
  LOQATE_BASE_URL,
  POSTCODE_VALIDATION_SCHEMA,
  getCode,
} from "@ukfd/checkout-utils";
import { ukfdFetch } from "@ukfd/shared-utils";
import { NativeInput } from "@ukfd/ui";
import s from "./AddressFinder.module.css";
import { AddressFinderProps } from "./AddressFinder.types";

const AddressFinder: FC<AddressFinderProps> = ({
  onSelected,
  name,
  label,
  onChange,
  postCodeValue,
  isChecked,
  isDisabled,
  ...props
}) => {
  const [value, setValue] = useState("");
  const [container, setContainer] = useState<FindAddressItem | null>(null);
  const [results, setResults] = useState<FindAddressItem[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [stopSearch, setStopSearch] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);

  const handleResultPick = async (id: string) => {
    const result = await ukfdFetch<LoqateRetrieveResponse>(
      `${LOQATE_BASE_URL}Retrieve/v1.00/json3.ws`,
      {
        params: { Key: LOQATE_API_KEY, Id: id },
      }
    );

    const {
      Items: [
        {
          PostalCode: postcode,
          Line1: addressLine1,
          Line2: addressLine2,
          City: city,
          ProvinceName: county,
          AdminAreaName: adminAreaName,
          CountryName: country,
        },
      ],
    } = result;

    onSelected({
      postcode,
      addressLine1,
      addressLine2,
      city,
      county: county || adminAreaName,
      country: getCode(country)!,
    });
    setShowResults(false);
    setStopSearch(true);
    setValue(postcode);
  };

  useEffect(() => {
    if (!isChecked) {
      setValue("");
    }
  }, [isChecked]);

  useEffect(() => {
    if (value !== postCodeValue) {
      setStopSearch(true);
      setValue(postCodeValue ?? value);
    }
  }, [postCodeValue]);

  useEffect(() => {
    const fetchAsync = async () => {
      if (value?.length < 3 || stopSearch || !hasInteracted) {
        setShowResults(false);
        return;
      }
      setShowResults(true);

      const { Items: findResults } = await ukfdFetch<LoqateFindResponse>(
        `${LOQATE_BASE_URL}Find/v1.1/json3.ws`,
        {
          params: {
            Key: LOQATE_API_KEY,
            Text: value,
            Countries: "gbr",
            Container: container && container.Id ? container.Id : "",
            Limit: 7,
          },
        }
      );

      setResults(findResults);
    };
    fetchAsync();
  }, [container, value, stopSearch]);

  return (
    <div className={s.root}>
      <NativeInput
        autoComplete={hasInteracted ? "stopIt" : "postal-code"}
        data-testid={name}
        disabled={isDisabled}
        label={label}
        name={name}
        spellCheck={false}
        validationSchema={POSTCODE_VALIDATION_SCHEMA(name)}
        value={postCodeValue && isChecked ? postCodeValue : value}
        required
        onFocus={() => setHasInteracted(true)}
        onBlur={() => {
          if (container === null) {
            setShowResults(false);
          }
        }}
        onChange={(e) => {
          setContainer(null);
          setStopSearch(false);
          onChange?.(e.target.value);
          setValue(e.target.value);
        }}
        {...props}
      />

      {results?.length > 0 && showResults && (
        <ul className={s.resultsList} data-testid={`${name}-results-list`}>
          {container && (
            <li className={s.searchHint} data-testid={`${name}-search-hint`}>
              Results within: {container.Text}
            </li>
          )}
          {results.map((result) => (
            <li key={result.Id} className={s.resultWrapper}>
              <button
                className={s.result}
                data-testid={result.Type}
                type="button"
                onMouseDown={() => {
                  if (result.Type === "Address") {
                    handleResultPick(result.Id);
                    setContainer(null);
                    return;
                  }
                  setContainer(result);
                }}
              >
                <p>
                  {`${result.Text}${result.Description && ", "}`}
                  <span className={s.description}>{result.Description}</span>
                </p>
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AddressFinder;
