import { useMutation } from "@apollo/client";
import Bugsnag from "@bugsnag/js";
import to from "await-to-js";
import {
  SESSION_STORAGE_ORDER_KEY,
  VALIDATE_ORDER_MUTATION,
  VALIDATE_ORDER_PAYMENT_MUTATION,
  asyncCheckSessionStorage,
  saveOrderDocumentToSessionStorage,
} from "@ukfd/checkout-utils";
import { logGraphQLErrors } from "@ukfd/shared-utils";

const useTryValidateOrder = () => {
  const [validateOrder] = useMutation<OrderValidation, OrderValidationVariables>(
    VALIDATE_ORDER_MUTATION
  );
  const [validatePayment] = useMutation<OrderPaymentValidation, OrderPaymentValidationVariables>(
    VALIDATE_ORDER_PAYMENT_MUTATION
  );

  return {
    tryValidateOrder: async (
      orderDocument: OrderDocument,
      savedOrderDocument: SavedOrderDocument
    ) => {
      const [error, data] = await to(
        validateOrder({
          variables: { order: orderDocument },
        })
      );

      const errors = logGraphQLErrors(error, data);
      if (errors) {
        return {
          success: false,
          data: errors,
        };
      }

      if (data?.data?.validateOrder.status !== "VALID") {
        return {
          success: false,
          data: [new Error("The order is invalid")],
        };
      }

      const { orderId, sampleOrderId } = data.data.validateOrder;

      // Patch saved order document
      Bugsnag.leaveBreadcrumb(
        `Saving to session storage with orderId: ${orderId} and sampleOrderId: ${sampleOrderId}`
      );
      const updatedOrderDocument = { ...savedOrderDocument, orderId, sampleOrderId };
      saveOrderDocumentToSessionStorage(updatedOrderDocument);
      Bugsnag.leaveBreadcrumb(
        `Saving successful for: ${orderId} and sampleOrderId: ${sampleOrderId}`
      );

      await asyncCheckSessionStorage(
        1000,
        SESSION_STORAGE_ORDER_KEY,
        JSON.stringify(updatedOrderDocument)
      );

      Bugsnag.leaveBreadcrumb(
        `Async check complete: ${orderId} and sampleOrderId: ${sampleOrderId}`
      );

      return {
        success: true,
        data: {
          orderId,
          sampleOrderId,
        },
      };
    },

    tryValidateOrderPayment: async (paymentDetails: OrderPaymentValidationVariables) => {
      const [error, data] = await to(
        validatePayment({
          variables: paymentDetails,
        })
      );

      const errors = logGraphQLErrors(error, data);
      if (errors) {
        return {
          success: false,
          data: errors,
        };
      }

      if (error || data?.data?.validatePayment.status !== "PENDING") {
        return {
          success: false,
          data: [new Error("The order payment data provided is invalid")],
        };
      }

      return {
        success: true,
        data: null,
      };
    },
  };
};

export default useTryValidateOrder;
