import { useContext, useEffect } from "react";
import { useRouter } from "next/router";
import { useFormikContext } from "formik";
import { SESSION_STORAGE_ORDER_KEY } from "@ukfd/checkout-utils";
import { UiStateContext } from "@context";

const usePrefillFormik = () => {
  const router = useRouter();
  const { error } = router.query;
  const { setValues } = useFormikContext<FormikOrderDocument>();
  const { setUiState } = useContext(UiStateContext);

  useEffect(() => {
    const data = sessionStorage.getItem(SESSION_STORAGE_ORDER_KEY);
    const orderDocument: SavedOrderDocument = data ? JSON.parse(data) : null;

    if (!orderDocument) {
      return;
    }

    setUiState({
      savedDeliveryMethod: orderDocument.delivery?.internalid || "",
      savedDeliveryDate: orderDocument.delivery?.chosenDeliveryDate || "",
      deliveryAddressMode: orderDocument.deliveryAddressMode,
      isCopyingDeliveryAddress: orderDocument.isCopyingDeliveryAddress,
    });

    setTimeout(() => {
      setValues((values) => ({
        ...values,
        customerDetails: {
          firstName: orderDocument.customerDetails.firstname,
          lastName: orderDocument.customerDetails.lastname,
          emailAddress: orderDocument.customerDetails.email,
          phoneNumber: orderDocument.customerDetails.telephoneNumber,
        },
        deliveryAddress: {
          id: orderDocument.customerDetails.deliveryAddress.id,
          addressLine1: orderDocument.customerDetails.deliveryAddress.firstLine,
          addressLine2: orderDocument.customerDetails.deliveryAddress.secondLine,
          city: orderDocument.customerDetails.deliveryAddress.city,
          county: orderDocument.customerDetails.deliveryAddress.county,
          country: orderDocument.customerDetails.deliveryAddress.country,
          postcode: orderDocument.customerDetails.deliveryAddress.postcode,
        },
        billingAddress: {
          id: orderDocument.customerDetails.billingAddress.id,
          addressLine1: orderDocument.customerDetails.billingAddress.firstLine,
          addressLine2: orderDocument.customerDetails.billingAddress.secondLine,
          city: orderDocument.customerDetails.billingAddress.city,
          county: orderDocument.customerDetails.billingAddress.county,
          country: orderDocument.customerDetails.billingAddress.country,
          postcode: orderDocument.customerDetails.billingAddress.postcode,
        },
        deliveryMethod: {
          hasVehicleSizeLimitations: orderDocument.delivery?.restrictions ? "yes" : "no",
          furtherDetails: orderDocument.delivery?.customerNotes || "",
          deliveryDate: "",
          deliveryOption: "",
        },
      }));
    }, 0);
  }, [error]);
};

export default usePrefillFormik;
