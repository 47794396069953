import * as React from "react";
import { useField } from "formik";
import clsx from "clsx";
import CheckIcon from "@heroicons/react/solid/CheckIcon";
import XIcon from "@heroicons/react/solid/XIcon";
import s from "./ExpiryDate.module.css";
import { ExpiryDateProps } from "./ExpiryDate.types";

const ExpiryDate: React.FC<ExpiryDateProps> = ({ id, monthName, yearName, label, required }) => {
  const [monthField, { touched: mTouched, error: monthError }] = useField(monthName);
  const [yearField, { touched: yTouched, error: yearError }] = useField(yearName);

  const canShowError = (monthError || yearError) && mTouched && yTouched;
  const canShowSuccess = !monthError && mTouched && !yearError && yTouched;

  const yearRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (monthField.value && monthField.value.length === 2) {
      yearRef.current?.focus();
    }
  }, [monthField.value]);

  return (
    <div className={clsx(s.root)}>
      {label && (
        <label className={s.label} htmlFor={monthName}>
          {label} {required && "*"}
        </label>
      )}

      <div
        id={id}
        className={clsx(s.inputContainer, {
          [s.error]: canShowError,
          [s.success]: canShowSuccess,
        })}
      >
        <input
          aria-label="Expiry month"
          autoComplete="cc-exp-month"
          className={s.input}
          id={monthName}
          inputMode="numeric"
          maxLength={2}
          placeholder="MM"
          onFocus={(e) => e.target.select()}
          {...monthField}
        />
        <span className={s.divider}>/</span>
        <input
          ref={yearRef}
          aria-label="Expiry year"
          autoComplete="cc-exp-year"
          className={s.input}
          id={yearName}
          inputMode="numeric"
          maxLength={2}
          placeholder="YY"
          onFocus={(e) => e.target.select()}
          {...yearField}
        />
        {(canShowSuccess || canShowError) && (
          <div className={s.iconOuterContainer}>
            <div className={clsx(s.iconContainer)}>
              {canShowSuccess && (
                <CheckIcon className={clsx(s.icon, s.iconValid)} data-testid="checkIcon" />
              )}
              {canShowError && (
                <XIcon className={clsx(s.icon, s.iconInvalid)} data-testid="crossIcon" />
              )}
            </div>
          </div>
        )}
      </div>
      {(monthError || yearError) && canShowError && (
        <span
          className={clsx({
            [s.labelInvalid]: canShowError,
            [s.labelValid]: canShowSuccess,
          })}
        >
          {monthError || yearError}
        </span>
      )}
    </div>
  );
};

export default ExpiryDate;
