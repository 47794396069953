import { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Logger, scrollIntoView } from "@ukfd/shared-utils";
import { Alert } from "@ukfd/ui";
import { UiStateContext } from "@context";

const MessageCentre: React.FC = () => {
  const router = useRouter();
  const [message, setMessage] = useState<React.ReactElement>();
  const {
    uiState: { processingError },
  } = useContext(UiStateContext);

  const error = router.query?.error || processingError;

  useEffect(() => {
    scrollIntoView();

    if (error) {
      Logger.error(new Error(error as string));
    }

    if (error === "cannot retry payment") {
      setMessage(
        <>
          There was an error processing your order.{" "}
          <strong>
            It is possible your card has been charged, but your order has not yet been placed.
          </strong>{" "}
          <br />
          <br />
          Check your card details and try again, or call{" "}
          <b>
            <a className="underline" href="tel:+442476012840">
              02476 012 840
            </a>
          </b>
          .
        </>
      );
    } else if (error === "sample order validation failed") {
      setMessage(
        <>
          There was an error processing your order.
          <br />
          <br />
          Please call us on{" "}
          <b>
            <a className="underline" href="tel:+442476012840">
              02476 012 840
            </a>
          </b>{" "}
          if the problem persists.
        </>
      );
    } else {
      setMessage(
        <>
          There was an error processing your order,
          <strong> you have not been charged.</strong> <br />
          <br />
          Check your card details and try again, or call{" "}
          <b>
            <a className="underline" href="tel:+442476012840">
              02476 012 840
            </a>
          </b>
          .
        </>
      );
    }
  }, [error]);

  return (
    <>{!!error && <Alert id="error" message={<p>{message}</p>} title="Error processing order" />}</>
  );
};

export default MessageCentre;
