import { useFormikContext } from "formik";
import { getFormikErrorMessages } from "@ukfd/checkout-utils";
import { Alert } from "@ukfd/ui";

const ValidationSummary: React.FC = () => {
  const { isValid, errors, submitCount } = useFormikContext<FormikOrderDocument>();

  if (submitCount === 0 || isValid) {
    return null;
  }

  return (
    <Alert
      id="validationSummary"
      title="Incomplete or incorrect fields"
      message={
        <ol>
          {[...new Set(getFormikErrorMessages(errors))].map((message) => (
            <li key={message}>{message}</li>
          ))}
        </ol>
      }
    ></Alert>
  );
};

export default ValidationSummary;
