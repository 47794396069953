import { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import {
  gtmCheckoutAccessoriesCrossSellAdd,
  gtmCheckoutAccessoriesCrossSellImpression,
  showRelatedProducts,
} from "@ukfd/checkout-utils";
import { Modal, useScreenSize } from "@ukfd/ui";
import { CheckoutContext } from "@context";
import { RelatedProductCard, RelatedProductsCarousel } from "@components";
import { CheckoutAccessoriesCrossSellProps } from "../RelatedProductCard/RelatedProductCard.types";
import s from "./RelatedProductsContainer.module.css";
import { RelatedProductsContainerProps } from "./RelatedProductsContainer.types";

const RelatedProductsContainer: React.FC<RelatedProductsContainerProps> = () => {
  const { basket, customer } = useContext(CheckoutContext);
  const [seeRelatedProducts, setSeeRelatedProducts] = useState<RelatedProductProps[] | null>(null);
  const [isRelatedProductsVisible, setIsRelatedProductsVisible] = useState(false);

  const { isLarge } = useScreenSize();

  const onRelatedProductAddToBasket = (product: CheckoutAccessoriesCrossSellProps) => {
    const { title, sku, quantity, coverage, packPrice } = product;
    gtmCheckoutAccessoriesCrossSellAdd({
      title,
      packPrice,
      sku,
      quantity,
      metersSquared: coverage ? Number(coverage) * quantity : 0,
    });
  };

  useEffect(() => {
    const relatedProducts = showRelatedProducts(basket.basketItems, customer.customerType);
    setSeeRelatedProducts(relatedProducts);
    setIsRelatedProductsVisible(!!relatedProducts);
  }, []);

  useEffect(() => {
    if (isRelatedProductsVisible) {
      gtmCheckoutAccessoriesCrossSellImpression(seeRelatedProducts);
    }
  }, [isRelatedProductsVisible]);

  if (!seeRelatedProducts || seeRelatedProducts?.length === 0) {
    return null;
  }

  const products = seeRelatedProducts.map((relatedProduct) => (
    <div key={relatedProduct.id} className={clsx(s.card, s.withBorder)}>
      <RelatedProductCard
        alt={relatedProduct.name}
        coverage={relatedProduct.coverage}
        m2Price={relatedProduct.m2Price}
        packPrice={relatedProduct.packPrice}
        recommendedQuantity={relatedProduct.recommendedQuantity}
        sku={relatedProduct.id}
        src={relatedProduct.imageLink}
        title={relatedProduct.name}
        onRelatedProductAddToBasket={onRelatedProductAddToBasket}
      />
    </div>
  ));

  return (
    <Modal
      description="You’ll need these to complete your project. We’ve handpicked the ones you need!"
      isOpen={!!isRelatedProductsVisible}
      title="Add your installation products and finishing profiles"
    >
      {isLarge && <>{products}</>}
      {!isLarge && (
        <RelatedProductsCarousel additionalClasses={s.carousel}>{products}</RelatedProductsCarousel>
      )}
    </Modal>
  );
};

export default RelatedProductsContainer;
