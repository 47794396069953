const gtmCheckoutAccessoriesCrossSellImpression = (
  relatedProducts: RelatedProductProps[] | null
) => {
  if (!relatedProducts || relatedProducts?.length === 0) {
    return;
  }

  const impressions = relatedProducts.map((relatedProduct, index) => ({
    name: relatedProduct.name,
    id: relatedProduct.id,
    price: relatedProduct.packPrice,
    quantity: relatedProduct.recommendedQuantity,
    brand: "Accessories",
    category: "Accessories",
    colour: "&nbsp;",
    child_product_sku: relatedProduct.id,
    ...(relatedProduct.coverage !== "" && {
      meters_squared: relatedProduct.recommendedQuantity * Number(relatedProduct.coverage),
    }),
    list: "You will also need",
    position: index + 1,
  }));

  window.dataLayer?.push({
    event: "CheckoutAccessoriesCrossSellImpression",
    ecommerce: {
      currencyCode: "GBP",
      impressions,
    },
  });
};

export default gtmCheckoutAccessoriesCrossSellImpression;
