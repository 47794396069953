import { useContext, useEffect } from "react";
import { useFormikContext } from "formik";
import { TabControl, Text } from "@ukfd/ui";
import { CheckoutContext, UiStateContext } from "@context";
import { InvoiceMethod, PayPalPaymentMethod, PaymentForm } from "@components";

const PaymentOptions: React.FC = () => {
  const { values, setValues, validateForm } = useFormikContext<FormikOrderDocument>();
  const { paymentOptions, customer } = useContext(CheckoutContext);
  const {
    uiState: { selectedPaymentMethodTab, arePaymentMethodTabsHidden },
    setUiState,
  } = useContext(UiStateContext);

  const availablePaymentOptions = paymentOptions[customer.customerType];

  useEffect(() => {
    const selectedTab = availablePaymentOptions[0].method as PaymentMethod;

    setUiState({ selectedPaymentMethodTab: selectedTab });
    setValues({
      ...values,
      payment: { ...values.payment, type: selectedTab },
    });
  }, []);

  return (
    <>
      <Text id="payment-method" variant="sectionHeading">
        Payment Method
      </Text>

      <TabControl
        areTabsHidden={arePaymentMethodTabsHidden}
        selectedTab={selectedPaymentMethodTab}
        onChange={(selectedTab) => {
          setUiState({ selectedPaymentMethodTab: selectedTab as PaymentMethod });
          setValues({
            ...values,
            payment: { ...values.payment, type: selectedTab as PaymentMethod },
          });
          validateForm();
        }}
      >
        {availablePaymentOptions.map(({ method }) => {
          const foundTabItem = [
            {
              id: "card",
              label: "Debit / Credit Card",
              component: <PaymentForm />,
            },
            { id: "paypal", label: "PayPal", component: <PayPalPaymentMethod /> },
            {
              id: "invoice",
              label: "Invoice",
              component: <InvoiceMethod />,
            },
          ].find((item) => item.id === method)!;
          return (
            <TabControl.Item
              key={`tab-control-${foundTabItem.id}`}
              id={foundTabItem.id}
              label={foundTabItem.label}
            >
              {foundTabItem.component}
            </TabControl.Item>
          );
        })}
      </TabControl>
    </>
  );
};

export default PaymentOptions;
