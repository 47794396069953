import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import {
  CHECKOUT_QUERY,
  UKFD_COOKIES,
  UKFD_PAYMENT_COOKIES,
  sortBasket,
  ukfdGraph,
} from "@ukfd/checkout-utils";
import {
  CheckoutContext,
  CheckoutProvider,
  ExternalOrderDetailsProvider,
  UiStateProvider,
} from "@context";
import {
  BasketItemsContainer,
  DefaultLayout,
  EmptyBasket,
  SampleCheckout,
  StandardCheckout,
} from "@components";

export const getServerSideProps: GetServerSideProps<CheckoutPageProps> = async ({
  req,
  res,
  query,
}) => {
  const basketId = req.cookies?.basketId || (typeof query.id === "string" ? query.id : undefined);
  const promocodesRequested = req.cookies[UKFD_COOKIES.PROMO_CODES];

  const customerId =
    req.cookies?.ukfdUserID ||
    (typeof query.customerId === "string" ? query.customerId : undefined);

  const acceptedCustomerTypes = ["retail", "trade"] as CustomerType[];
  const desiredCustomerType =
    (req.cookies?.ukfdCustomerType as CustomerType) ||
    ((query.customerType || "retail") as CustomerType);
  const customerType = acceptedCustomerTypes.includes(desiredCustomerType)
    ? desiredCustomerType
    : "retail";

  // Clean up any payment related cookies
  res.setHeader(
    "set-cookie",
    UKFD_PAYMENT_COOKIES.map((cookie) => `${cookie}=; max-age=0; path=/;`)
  );

  if (!basketId) {
    return {
      props: {
        dataLayerProps: {
          page: { category: "Empty basket" },
        },
        token: null,
      },
    };
  }

  const { data } = await ukfdGraph<CheckoutAPIResponse>(CHECKOUT_QUERY, {
    id: basketId,
    promocodesRequested: promocodesRequested?.split(",") || [],
    customerType,
    customerId,
  });

  // console.log({ data });

  if (!data) {
    return {
      props: {
        dataLayerProps: {
          page: { category: "Empty basket" },
        },
        token: null,
      },
    };
  }

  const { basket, paymentOptions, customerDetails, paymentToken, delivery } =
    data?.checkoutGetPage || {};

  const dataLayerProps: DataLayerProps = {
    user: { loggedIn: !!customerDetails?.customerId, ...customerDetails } || null,
    page: { category: "checkout" },
    ecommerce: {
      actionField: {
        id: basketId,
        price: basket?.price || null,
      },
      products: sortBasket(basket?.basketItems) || null,
    },
  };

  return {
    props: {
      delivery: delivery || null,
      basket: { ...basket, basketItems: sortBasket(basket?.basketItems) } || null,
      paymentOptions: paymentOptions || null,
      customer: {
        ...customerDetails,
        customerType,
      },
      token: paymentToken || null,
      dataLayerProps,
    },
  };
};

const Home = (props: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return (
    // @ts-expect-error Ignore TypeScript errors
    <CheckoutProvider value={props}>
      <UiStateProvider>
        <ExternalOrderDetailsProvider>
          <CheckoutContext.Consumer>
            {({ basket }) => {
              const hasBasket = !!basket;
              const hasBasketItems = hasBasket && !!basket?.basketItems.length;
              const isSamplesOnly =
                hasBasketItems &&
                basket.basketItems.every((product) => product.details?.productClass === "sample");

              return (
                <>
                  {/* No checkout data available */}
                  {(!hasBasket || !hasBasketItems) && <EmptyBasket />}

                  {/* All products are samples */}
                  {hasBasket && hasBasketItems && isSamplesOnly && <SampleCheckout />}

                  {/* Mixed baskets */}
                  {hasBasket && hasBasketItems && !isSamplesOnly && (
                    <StandardCheckout>
                      <BasketItemsContainer />
                    </StandardCheckout>
                  )}
                </>
              );
            }}
          </CheckoutContext.Consumer>
        </ExternalOrderDetailsProvider>
      </UiStateProvider>
    </CheckoutProvider>
  );
};

Home.Layout = DefaultLayout;

export default Home;
