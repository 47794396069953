import { FC, useContext } from "react";
import { useFormikContext } from "formik";
import {
  calculateBasketTotal,
  getFormikErrorMessages,
  gtmEvent,
  scrollFirstErrorIntoView,
} from "@ukfd/checkout-utils";
import { Button } from "@ukfd/ui";
import { CheckoutContext, ExternalOrderDetailsContext, UiStateContext } from "@context";
import { PayWithPaypalButton } from "@components";
import s from "./PlaceOrderButton.module.css";

/**
 * @todo: improve bugsnag logging to match payment information send to cybersource with information logged in lambda
 */

const PlaceOrderButton: FC = () => {
  const { isValid, isSubmitting, submitForm, errors } = useFormikContext<FormikOrderDocument>();

  const {
    uiState: { selectedPaymentMethodTab, tokenisationErrors },
  } = useContext(UiStateContext);

  const {
    orderDetails: { origin },
  } = useContext(ExternalOrderDetailsContext);

  const {
    delivery: { deliveryPrice },
  } = useContext(CheckoutContext);

  const { basket, basketTotal } = useContext(CheckoutContext);

  const isInvoicePaymentMethodValid = selectedPaymentMethodTab === "invoice" && isValid;

  const isCardPaymentMethodValid =
    selectedPaymentMethodTab === "card" && isValid && tokenisationErrors?.length === 0;

  const isCapturingImmediately = selectedPaymentMethodTab === "paypal" && origin !== "paypal";
  const isCaptureImmediatelyValid = isCapturingImmediately && isValid;
  const isPayPalPaymentMethodValid = selectedPaymentMethodTab === "paypal" && isValid;

  const canDisplayDefaultButton =
    origin === "paypal" ||
    selectedPaymentMethodTab === "card" ||
    selectedPaymentMethodTab === "invoice";

  const canDisplayPayPalButton = origin !== "paypal" && selectedPaymentMethodTab === "paypal";
  const isFormReadyToSubmit =
    (isCardPaymentMethodValid ||
      isCaptureImmediatelyValid ||
      isPayPalPaymentMethodValid ||
      isInvoicePaymentMethodValid) &&
    isValid;

  const orderTotal = calculateBasketTotal(
    basket.promo?.subtotal || basketTotal || 0,
    deliveryPrice
  );

  return (
    <div className={s.root}>
      <div className={s.container}>
        <p className={s.totalLabel}>Total</p>
        <div className={s.total}>{orderTotal}</div>
        <div className={s.buttonContainer}>
          {canDisplayDefaultButton && (
            <Button
              className={s.placeOrderButton}
              disabled={isSubmitting}
              loading={isSubmitting}
              type={isFormReadyToSubmit ? "submit" : "button"}
              onClick={async () => {
                if (!isFormReadyToSubmit) {
                  await submitForm();
                  await scrollFirstErrorIntoView();
                  gtmEvent("placeOrderValidationError", {
                    page: { category: "checkout-validation" },
                    errors: getFormikErrorMessages(errors),
                  });
                }
              }}
            >
              {isSubmitting ? "Processing" : "Place Order"}
            </Button>
          )}

          {canDisplayPayPalButton && (
            <PayWithPaypalButton className={s.placeOrderButton} trySubmitImmediately />
          )}
        </div>
        <div className={s.termsContainer}>
          <p className={s.termsText}>
            By placing your order, you agree to our{" "}
            <a
              className={s.termsAnchor}
              href="https://trade.ukflooringdirect.co.uk/terms-conditions"
              rel="noreferrer"
              target="_blank"
            >
              Terms &amp; Conditions
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PlaceOrderButton;
