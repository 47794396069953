import { scrollIntoView } from "@ukfd/shared-utils";

const scrollFirstErrorIntoView = async (): Promise<boolean> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const element = document.querySelector(
        "[class*='inputInvalid'],[class*='labelInvalid'],[class*='_error__'],[class*='_errorContainer__']"
      );
      if (element) {
        scrollIntoView(element.id);
      } else {
        const validationSummary = document.querySelector("[role='alert']");
        if (validationSummary) {
          scrollIntoView(validationSummary.id);
        }
      }

      resolve(!!element);
    }, 0);
  });
};

export default scrollFirstErrorIntoView;
