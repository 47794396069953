import { calculateTaxAmount } from "./taxCalculator";

const createOrderSummary = ({
  promo,
  price,
  shippingRate,
}: {
  promo: Promo | undefined;
  price: BasketPrice;
  shippingRate: number;
}): NetsuiteOrderDocumentBasketSummary => {
  const discountIncTax = promo?.discounttotal ?? 0;
  const itemsSubtotalIncTax = price.subtotal ?? 0;
  const shippingCostIncTax = shippingRate ?? 0;

  const subtotalIncTaxMinusDiscount = itemsSubtotalIncTax - discountIncTax;
  const taxAmount = calculateTaxAmount(subtotalIncTaxMinusDiscount + shippingCostIncTax);

  const orderTotalIncTax = itemsSubtotalIncTax - discountIncTax + shippingCostIncTax;

  return {
    subtotal: price.subtotal,
    discount: discountIncTax,
    taxamount: taxAmount,
    shippingcost: shippingCostIncTax,
    total: orderTotalIncTax,
  };
};

export default createOrderSummary;
