import { useContext } from "react";
import { createOrderSummary } from "@ukfd/checkout-utils";
import { CheckoutContext } from "@context";
import { useAnalytics } from "@hooks";

const useOrderDocument = () => {
  const {
    basket,
    customer: { customerId, customerType, tradeInformation },
    delivery: { deliveryPrice, deliveryPriceFormatted, deliveryPriceExVat, internalId, service },
  } = useContext(CheckoutContext);

  const { custbody_google_analytics_client_id, custbody_captured_data_webstore } = useAnalytics();

  return {
    createOrderDocument: function (
      values: FormikOrderDocument,
      orderId?: string,
      sampleOrderId?: string
    ): OrderDocument {
      const { customerDetails, deliveryAddress, deliveryMethod, billingAddress } = values;

      return {
        orderId,
        sampleOrderId,
        custbody_captured_data_webstore,
        custbody_google_analytics_client_id,
        basket: {
          id: basket.id,
          items: basket.basketItems.map(({ sku, price, details }) => ({
            id: details?.internalid || "",
            SKU: sku,
            quantity: details?.packs || 0,
            price: {
              amount: (price?.packPrice || 0) * (details?.packs || 0),
              discount: 0, // We dont discount basket items
              rate: price?.packPrice || 0,
              total: price?.subtotal || 0,
            },
          })),
          promocodesApplied:
            basket.promo?.summary.map(({ code }) => code!).filter((x) => !!x) || [],

          summary: createOrderSummary({
            promo: basket.promo,
            price: basket.price,
            shippingRate: deliveryPrice || 0,
          }),
        },
        customerDetails: {
          customerId,
          name: `${customerDetails.firstName} ${customerDetails.lastName}`,
          firstname: customerDetails.firstName,
          lastname: customerDetails.lastName,
          email: customerDetails.emailAddress,
          customerType,
          telephoneNumber: customerDetails.phoneNumber,
          deliveryAddress: {
            id: deliveryAddress.id || "",
            firstLine: deliveryAddress.addressLine1,
            secondLine: deliveryAddress.addressLine2 || "",
            fullname: `${customerDetails.firstName} ${customerDetails.lastName}`,
            phone: customerDetails.phoneNumber,
            city: deliveryAddress.city,
            state: deliveryAddress.county,
            country: deliveryAddress.country,
            county: deliveryAddress.county,
            postcode: deliveryAddress.postcode,
          },
          tradeInformation: {
            customerTradeSector: tradeInformation?.customerTradeSector,
          },
          billingAddress: {
            id: billingAddress.id || "",
            firstLine: billingAddress.addressLine1,
            secondLine: billingAddress.addressLine2 || "",
            fullname: `${customerDetails.firstName} ${customerDetails.lastName}`,
            phone: customerDetails.phoneNumber,
            city: billingAddress.city,
            state: billingAddress.county,
            country: billingAddress.country,
            county: billingAddress.county,
            postcode: billingAddress.postcode,
          },
        },
        delivery: {
          internalid: internalId,
          shippingRate: deliveryPrice || 0,
          shippingRateExVat: deliveryPriceExVat,
          shippingRateFormatted: deliveryPriceFormatted,
          service: {
            carrier: service?.carrier,
            internalid: service?.internalId,
            name: service?.name,
          },
          chosenDeliveryDate: deliveryMethod.deliveryDate,
          customerNotes: deliveryMethod.furtherDetails || "",
          restrictions: deliveryMethod.hasVehicleSizeLimitations === "yes",
        },
      };
    },

    createSampleOrderDocument: function (
      values: FormikSampleOrderDocument,
      sampleOrderId?: string
    ): OrderDocument {
      const { customerDetails, deliveryAddress } = values;

      const address: NetSuiteOrderDocumentCustomerAddress = {
        id: deliveryAddress.id || "",
        firstLine: deliveryAddress.addressLine1,
        secondLine: deliveryAddress.addressLine2 || "",
        fullname: `${customerDetails.firstName} ${customerDetails.lastName}`,
        phone: customerDetails.phoneNumber,
        city: deliveryAddress.city,
        state: deliveryAddress.county,
        country: deliveryAddress.country,
        county: deliveryAddress.county,
        postcode: deliveryAddress.postcode,
      };

      return {
        sampleOrderId,
        custbody_captured_data_webstore,
        custbody_google_analytics_client_id,
        basket: {
          id: basket.id,
          items: basket.basketItems.map(({ sku, price, details }) => ({
            id: details?.internalid || "",
            SKU: sku,
            quantity: details?.packs || 0,
            price: {
              amount: (price?.packPrice || 0) * (details?.packs || 0),
              discount: 0, // We dont discount basket items
              rate: price?.packPrice || 0,
              total: price?.subtotal || 0,
            },
          })),
          promocodesApplied: [],
          summary: createOrderSummary({
            promo: basket.promo,
            price: basket.price,
            shippingRate: deliveryPrice || 0,
          }),
        },
        customerDetails: {
          customerId,
          name: `${customerDetails.firstName} ${customerDetails.lastName}`,
          firstname: customerDetails.firstName,
          lastname: customerDetails.lastName,
          email: customerDetails.emailAddress,
          customerType,
          telephoneNumber: customerDetails.phoneNumber,
          deliveryAddress: address,
          billingAddress: address,
        },
      };
    },
  };
};

export default useOrderDocument;
