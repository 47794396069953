declare let Flex: any;

const styles = {
  input: {
    "font-size": "16px",
    "font-family":
      "Montserrat, ui-sans-serif, system-ui, -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif",
    color: "#374151",
    "-webkit-font-smoothing": "antialiased",
  },
  ":disabled": { cursor: "not-allowed" },
};

const CyberSourceInit = (token: string) => {
  try {
    const flex = new Flex(token);
    const microform = flex.microform({
      styles,
    });

    const number = microform.createField("number", {
      placeholder: "Enter card number",
    });

    const securityCode = microform.createField("securityCode", {
      placeholder: "eg. 123",
    });

    number.load("#card-number-container");
    securityCode.load("#cvv-number-container");

    return microform;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export default CyberSourceInit;
