import { FC, useContext } from "react";
import { useFormikContext } from "formik";
import clsx from "clsx";
import { useMutation } from "@apollo/client";
import {
  CHECKOUT_APPLY_PROMO_MUTATION,
  calculateBasketTotal,
  calculateTaxAmount,
} from "@ukfd/checkout-utils";
import { formatAsCurrency } from "@ukfd/shared-utils";
import { Button, Text } from "@ukfd/ui";
import { CheckoutContext } from "@context";
import s from "./OrderSummary.module.css";

const OrderSummary: FC = () => {
  const {
    basket: {
      id: basketId,
      price: { subtotalExVat, subtotal },
      promo,
    },
    delivery: {
      deliveryCost,
      deliveryPriceExVat,
      deliveryPrice,
      deliveryPriceFormatted,
      deliveryDate,
      isCollection,
    },
    onPromotionalCodeApplied,
    basketTotal: subTotal,
    isVatIncluded,
    isVatExcluded,
    customer: { customerType },
  } = useContext(CheckoutContext);

  const { values, setValues } = useFormikContext<FormikOrderDocument>();

  const [applyPromoCodes, { loading }] = useMutation<{
    checkoutApplyPromoToBasket: Basket;
  }>(CHECKOUT_APPLY_PROMO_MUTATION, {
    variables: { id: basketId, promocodesRequested: [], customerType },
  });

  const clearPromotionalCodes = async () => {
    const { data, errors } = await applyPromoCodes();

    if (errors) {
      return;
    }

    const basket = data?.checkoutApplyPromoToBasket;
    onPromotionalCodeApplied(basket!);
    setValues({
      ...values,
      promotionalCodes: [],
    });
  };

  const hasPromotionalCode = !!promo?.summary.some((x) => !!x?.isValid);

  const PromotionalCode = () => (
    <div className={s.container}>
      <div className={s.title}>
        Saving{" "}
        <small className={s.subText}>
          {promo?.summary?.map(({ code }) => code?.toUpperCase()).join(", ")}
        </small>{" "}
        <div className={s.clearButtonWrapper}>
          {!loading && <span className={s.bracket}>(</span>}
          <Button
            className={s.clearButton}
            disabled={loading}
            loading={loading}
            variant="ghost"
            onClick={() => clearPromotionalCodes()}
          >
            {!loading && "clear"}
          </Button>
          {!loading && <span className={s.bracket}>)</span>}
        </div>
      </div>{" "}
      <p className={s.value} data-testid="saving-total">
        {promo?.discounttotal && formatAsCurrency(promo?.discounttotal * -1)}
      </p>
    </div>
  );

  return (
    <>
      <Text className={s.mainTitle} variant="asideHeading">
        Order summary
      </Text>

      {deliveryPriceFormatted && deliveryDate && (
        <>
          <div className={s.container} data-testid="order-value">
            <p className={s.title}>Sub total</p>{" "}
            {isVatIncluded && (
              <p className={s.value} data-testid="basket-total">
                {subTotal && formatAsCurrency(subTotal)}
              </p>
            )}
            {isVatExcluded && (
              <p className={s.value} data-testid="basket-total">
                {subtotalExVat && formatAsCurrency(subtotalExVat)}
              </p>
            )}
          </div>
          <div className={s.container} data-testid="delivery-value">
            {isCollection && (
              <>
                <p className={s.title}>Collection</p> <p className={s.value}>Free</p>
              </>
            )}
            {!isCollection && (
              <>
                <p className={s.title}>
                  Delivery <small className={s.subText}>({deliveryDate})</small>
                </p>{" "}
                {isVatIncluded && <p className={s.value}>{deliveryPriceFormatted}</p>}
                {isVatExcluded && (
                  <p className={s.value}>{formatAsCurrency(deliveryPriceExVat || 0)}</p>
                )}
              </>
            )}
          </div>
          {hasPromotionalCode && <PromotionalCode />}

          {isVatExcluded && (
            <div className={clsx(s.container, s.vatTotal)}>
              <p className={s.title}>VAT</p>{" "}
              <p className={s.value} data-testid="vat-total">
                {subtotalExVat &&
                  formatAsCurrency(calculateTaxAmount((subtotal || 0) + (deliveryPrice || 0)))}
              </p>
            </div>
          )}
          <div className={clsx(s.container, s.basketTotal)} data-testid="basket-total-container">
            <p className={s.title}>Total</p>{" "}
            <p className={s.value} data-testid="basket-total">
              {calculateBasketTotal(promo?.subtotal || subTotal || 0, deliveryPrice)}
            </p>
          </div>
        </>
      )}

      {(!deliveryPriceFormatted || !deliveryDate) && (
        <>
          <div className={s.container} data-testid="total-exc-delivery">
            <p className={s.title}>Sub total</p>{" "}
            {isVatIncluded && (
              <p className={s.value} data-testid="basket-total">
                {subTotal && formatAsCurrency(subTotal)}
              </p>
            )}
            {isVatExcluded && (
              <p className={s.value} data-testid="basket-total">
                {subtotalExVat && formatAsCurrency(subtotalExVat)}
              </p>
            )}
            {isVatExcluded && (
              <>
                <p className={s.title}>VAT</p>{" "}
                <p className={s.value} data-testid="vat-total">
                  {subtotal && subtotalExVat && formatAsCurrency(subtotal - subtotalExVat)}
                </p>
              </>
            )}
          </div>
          <div className={clsx(s.container, s.deliveryFrom)} data-testid="delivery-from">
            <p className={s.title}>Delivery</p>{" "}
            <p className={s.value}>
              <span className={clsx(s.subText)}>from</span> {formatAsCurrency(deliveryCost || 0)}
            </p>
          </div>

          {hasPromotionalCode && <PromotionalCode />}
        </>
      )}
    </>
  );
};

export default OrderSummary;
