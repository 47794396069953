import { FC, useContext, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { useMutation } from "@apollo/client";
import to from "await-to-js";
import dayjs from "dayjs";
import minBy from "lodash/minBy";
import { ALL_DELIVERY_DATES, isValidPostcode, onDeliveryTrackingEvent } from "@ukfd/checkout-utils";
import { formatAsCurrency, logGraphQLErrors } from "@ukfd/shared-utils";
import { RadioGroup, TabControl, Text, TextArea } from "@ukfd/ui";
import { CheckoutContext } from "@context";
import { Collection, DeliveryCalendar, DeliveryOptions } from "@components";
import s from "./DeliveryMethodContainer.module.css";
import {
  DeliveryMethodContainerProps,
  LastSelectedDelivery,
} from "./DeliveryMethodContainer.types";

const DeliveryMethodContainer: FC<DeliveryMethodContainerProps> = () => {
  const { values, setValues } = useFormikContext<FormikOrderDocument>();
  const {
    basket,
    customer,
    isVatIncluded,
    setDelivery,
    delivery: { basketWeight },
  } = useContext(CheckoutContext);

  const LIGHT_PARCEL_MAX_WEIGHT_KG = 50;

  const [deliveryData, setDeliveryData] = useState<GetAllDeliveryDates>();
  const [lastSelectedDelivery, setLastSelectedDelivery] = useState<LastSelectedDelivery>({
    date: "",
    option: "",
  });

  const [getAllDeliveryDates, { loading, error }] = useMutation<
    GetAllDeliveryDatesResult,
    GetAllDeliveryDatesVariables
  >(ALL_DELIVERY_DATES);

  const hasNotInitialised = !loading && !error && !deliveryData;
  const isLoadingDeliveryDates = loading && !error;
  const hasEncounteredError = !loading && error;
  const hasLoadedWithData = !loading && !error && deliveryData;
  const hasLoadedInvalidDeliveryOptions =
    hasLoadedWithData && deliveryData?.deliveryDates[0].date === "12/31/9999";

  const getCheapestShippingMethod = (shippingMethods: DeliveryDatesShippingMethod[]) =>
    minBy(shippingMethods, ({ shippingRate }) => shippingRate);

  const onSelectedDayChanged = (date: string) => {
    setValues((current) => {
      if (current.deliveryMethod.deliveryOption === deliveryData?.collection?.internalId) {
        // Do nothing when date changes because the customer has opted for Click and Collect
        return current;
      }

      if (current.deliveryMethod.deliveryDate === date) {
        return current;
      }

      // with the new date; go find that date in the delivery data
      const deliveryOptionsForDate = deliveryData?.deliveryDates.find(
        ({ date: deliveryOptionDate }) => deliveryOptionDate === date
      );

      const deliveryOption = deliveryOptionsForDate?.shippingMethods?.find(
        ({ id }) => id === current.deliveryMethod.deliveryOption
      );
      // Pre-select cheapest delivery option when changing days
      return {
        ...current,
        deliveryMethod: {
          ...current.deliveryMethod,
          deliveryDate: date,
          deliveryOption:
            deliveryOption?.id ||
            getCheapestShippingMethod(deliveryOptionsForDate!.shippingMethods)!.id,
        },
      };
    });

    // track date selected interaction
    onDeliveryTrackingEvent("delivery_method__date_selected", date);
  };

  const onTabChanged = (selectedTab: DeliveryOption, previousTab: DeliveryOption) => {
    setValues((current) => {
      if (selectedTab === "collection") {
        const {
          collection: {
            internalId,
            earliestDeliveryDate,
            shippingRate,
            shippingRateIncVat,
            service,
          },
        } = deliveryData!;

        setDelivery({
          internalId: internalId,
          deliveryDate: dayjs(earliestDeliveryDate).format("ddd Do MMM"),
          deliveryPrice: shippingRateIncVat,
          deliveryPriceExVat: shippingRate,
          deliveryPriceFormatted: formatAsCurrency(shippingRateIncVat),
          isCollection: true,
          service,
        } as DeliveryOptions);

        return {
          ...current,
          deliveryMethod: {
            ...current.deliveryMethod,
            deliveryDate: earliestDeliveryDate,
            deliveryOption: internalId,
          },
        };
      }

      // track tab user interaction
      onDeliveryTrackingEvent("delivery_method__type_selected", selectedTab);

      if (!(selectedTab === "delivery" && previousTab === "collection")) {
        return current;
      }

      // Customer has gone from the collection tab to the delivery tab
      // Restore their previously selected delivery date and option
      return {
        ...current,
        deliveryMethod: {
          ...current.deliveryMethod,
          deliveryDate: lastSelectedDelivery.date,
          deliveryOption: lastSelectedDelivery.option,
        },
      };
    });
  };

  const updateDelivery = (shippingMethod: DeliveryDatesShippingMethod, deliveryDate: string) => {
    // track interaction with delivery option
    onDeliveryTrackingEvent("delivery_method__time_selected", shippingMethod.service.name);

    // Update the delivery dates in the global state provider
    setDelivery({
      internalId: shippingMethod!.id,
      deliveryDate: dayjs(deliveryDate).format("ddd Do MMM"),
      deliveryPrice: shippingMethod!.shippingRateIncVat,
      deliveryPriceExVat: shippingMethod!.shippingRate,
      deliveryPriceFormatted: formatAsCurrency(shippingMethod!.shippingRateIncVat),
      isCollection: false,
      service: shippingMethod!.service,
    } as DeliveryOptions);
  };

  const mapDeliveryOptions = () => {
    const shippingMethods =
      deliveryData!.deliveryDates.find(({ date }) => date === values.deliveryMethod.deliveryDate)
        ?.shippingMethods || [];

    return shippingMethods.map((option) => {
      const {
        service: { name: description },
        shippingRate,
        shippingRateIncVat,
        id: value,
      } = option;

      return {
        description,
        price: isVatIncluded ? shippingRateIncVat : shippingRate,
        value,
      };
    });
  };

  const onCalendarNavigationClicked = (direction: "right" | "left") => {
    onDeliveryTrackingEvent("delivery_method__date_arrows_click", direction);
  };

  const onDeliveryRestrictionRadioBtnClick = (
    details: "All vehicles can deliver" | "A heavy goods vehicle will not fit"
  ) => {
    onDeliveryTrackingEvent("delivery_method__access_restrictions", details);
  };

  useEffect(() => {
    if (!isValidPostcode(values.deliveryAddress.postcode)) {
      return;
    }

    setDeliveryData(undefined);
    setLastSelectedDelivery({
      date: "",
      option: "",
    });

    const loadDeliveryDates = async () => {
      const [err, result] = await to(
        getAllDeliveryDates({
          variables: {
            basketId: basket.id,
            postcode: values.deliveryAddress.postcode,
            customerType: customer.customerType,
            isVatIncluded,
          },
        })
      );
      logGraphQLErrors(err, result);
      setDeliveryData(result?.data?.getAllDeliveryDates);
    };
    loadDeliveryDates();
    window.dataLayer?.push({
      event: "delivery_method__DSO_queried",
      delivery_method__DSO_message: "Load delivery dates...",
    });
  }, [values.deliveryAddress.postcode, basket.basketItems]);

  useEffect(() => {
    if (!deliveryData || loading) {
      return;
    }
    // Find first delivery date that has an available shipping option
    const firstAvailableOption = deliveryData.deliveryDates.find(
      (x) => x.shippingMethods.length > 0
    );

    if (!firstAvailableOption) {
      // Unlikely there will be no delivery options available
      return;
    }

    const cheapestShippingMethod = getCheapestShippingMethod(firstAvailableOption.shippingMethods);

    // Set delivery date and option in Formik.
    // This will cause calendar to scroll to correct date

    updateDelivery(cheapestShippingMethod!, firstAvailableOption.date);

    setValues({
      ...values,
      deliveryMethod: {
        ...values.deliveryMethod,
        deliveryDate: firstAvailableOption.date,
        deliveryOption: cheapestShippingMethod!.id,
      },
    });
  }, [deliveryData]);

  useEffect(() => {
    if (!deliveryData || loading) {
      return;
    }

    const selectedDeliveryData = deliveryData?.deliveryDates.find(
      ({ date }) => values.deliveryMethod.deliveryDate === date
    );
    const selectedShippingOption = selectedDeliveryData?.shippingMethods.find(
      ({ id }) => id === values.deliveryMethod.deliveryOption
    );

    if (!selectedShippingOption) {
      // Likely because the customer has selected Click & Collect
      return;
    }

    // Customer has selected their preferred shipping date and option.
    // Set their preference in the CheckoutProvider.
    // This is required for
    //  A) Setting the right delivery option in Order Summary
    //  B) Passing the correct delivery preferences to Netsuite
    updateDelivery(selectedShippingOption, selectedDeliveryData!.date);

    // Track the selected delivery option so it can be restored later if needed
    setLastSelectedDelivery({
      date: selectedDeliveryData!.date,
      option: selectedShippingOption!.id,
    });
  }, [values.deliveryMethod.deliveryOption, values.deliveryMethod.deliveryDate]);

  return (
    <>
      <Text id="delivery-method" variant="sectionHeading">
        Delivery Method
      </Text>

      {hasNotInitialised && (
        <p className={s.warning}>Warning: Please enter a valid shipping address first</p>
      )}

      {isLoadingDeliveryDates && <p className={s.warning}>Loading delivery dates...</p>}

      {hasEncounteredError && (
        <p className={s.warning}>There's been an error please hit the live chat button</p>
      )}

      {hasLoadedInvalidDeliveryOptions && (
        <p className={s.warning}>
          We may be unable to deliver to your address. Please contact customer support or hit the
          live chat button.
        </p>
      )}

      {hasLoadedWithData && (
        <TabControl
          areTabsHidden={!deliveryData.collection.isAvailableForCollection}
          onChange={onTabChanged}
        >
          <TabControl.Item id="delivery" label="Delivery">
            {!hasLoadedInvalidDeliveryOptions && (
              <>
                <Text variant="asideHeading">Choose a date</Text>
                <div>
                  <Text variant="body">
                    For Express Delivery please call <a href="tel:02476012848">02476 01 2848</a>,
                    use our live chat or email on{" "}
                    <a href="mailto:sales@ukflooringdirect.co.uk">sales@ukflooringdirect.co.uk</a>
                  </Text>
                </div>
                <div>
                  <DeliveryCalendar
                    dates={deliveryData.deliveryDates}
                    isVatIncluded={isVatIncluded}
                    onCalendarNavigationClick={onCalendarNavigationClicked}
                    onChange={onSelectedDayChanged}
                  />
                  <DeliveryOptions options={mapDeliveryOptions()} />
                  {basketWeight > LIGHT_PARCEL_MAX_WEIGHT_KG && (
                    <ul className={s.list} data-testid="delivery-info-text">
                      <li>Delivery between 8am - 6pm</li>
                      {/* <li>We'll text you a 4 hour delivery window</li> */}
                      <li>The team will call 30 minutes before arrival</li>
                      <li>A signature upon delivery will be required</li>
                    </ul>
                  )}
                </div>
              </>
            )}
            <div>
              <Text className={s.question} variant="body">
                Are there any delivery restrictions?
              </Text>
              <RadioGroup
                options={[
                  {
                    name: "deliveryMethod.hasVehicleSizeLimitations",
                    label: "No - All vehicles can deliver",
                    value: "no",
                    onClick: () => {
                      // track interaction with delivery restrictions
                      onDeliveryRestrictionRadioBtnClick("All vehicles can deliver");
                    },
                  },
                  {
                    name: "deliveryMethod.hasVehicleSizeLimitations",
                    label: "Yes - A heavy goods vehicle will not fit",
                    value: "yes",
                    onClick: () => {
                      // track interaction with delivery restrictions
                      onDeliveryRestrictionRadioBtnClick("A heavy goods vehicle will not fit");
                    },
                  },
                ]}
              />
            </div>
            <TextArea
              label="Any other information which may help us with your delivery?"
              maxLength={100}
              name="deliveryMethod.furtherDetails"
              rows={6}
            />
          </TabControl.Item>
          <TabControl.Item id="collection" label="Collection">
            <Collection />
          </TabControl.Item>
        </TabControl>
      )}
    </>
  );
};

export default DeliveryMethodContainer;
