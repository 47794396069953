import groupBy from "lodash/groupBy";

const sortBasket = (basketItems: Product[]) => {
  if (!basketItems?.length) {
    return [];
  }

  const {
    product: products,
    accessory: accessories,
    sample: samples,
  } = groupBy(basketItems, (item) => item.details?.productClass);

  return [...(products || []), ...(accessories || []), ...(samples || [])];
};

export default sortBasket;
