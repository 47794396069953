import * as React from "react";
import clsx from "clsx";
import { Input, Text } from "@ukfd/ui";
import { ExternalOrderDetailsContext } from "@context";
import { usePrefillFormik } from "@hooks";
import s from "./CustomerDetails.module.css";
import { CustomerDetailsProps } from "./CustomerDetails.types";

const CustomerDetails: React.FC<CustomerDetailsProps> = ({ className, title }) => {
  const {
    orderDetails: { origin },
  } = React.useContext(ExternalOrderDetailsContext);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const forceValidation = origin !== "";

  usePrefillFormik();

  return (
    <div className={clsx(s.root, className)} data-testid="customer-details-form">
      <Text id="customer-details" variant="sectionHeading">
        {title}
      </Text>
      <Input
        ref={inputRef}
        autoComplete="given-name"
        data-dl="customer-details-firstName"
        data-testid="customer-details-firstName"
        forceValidation={forceValidation}
        label="First Name"
        name="customerDetails.firstName"
        required
      />
      <Input
        autoComplete="family-name"
        data-dl="customer-details-lastName"
        data-testid="customer-details-lastName"
        forceValidation={forceValidation}
        label="Last Name"
        name="customerDetails.lastName"
        required
      />
      <Input
        autoComplete="email"
        data-dl="customer-details-emailAddress"
        data-testid="customer-details-emailAddress"
        forceValidation={forceValidation}
        inputMode="email"
        label="Email Address"
        name="customerDetails.emailAddress"
        required
      />
      <Input
        autoComplete="tel"
        data-dl="customer-details-phoneNumber"
        data-testid="customer-details-phoneNumber"
        forceValidation={forceValidation}
        inputMode="tel"
        label="Phone Number"
        name="customerDetails.phoneNumber"
        required
      />
      <p className={s.privacyText}>
        {" "}
        You'll receive email updates about your order and relevant marketing messages. UK Flooring
        Direct will collect and use your data in accordance with our{" "}
        <a
          className={s.privacyAnchor}
          data-hashtag="#privacy-cookies"
          data-touchpoint="home"
          href="/privacy-cookies"
          target="_blank"
        >
          data &amp; privacy notice
        </a>{" "}
        where you will also be able to find out how to opt-out at any time.{" "}
      </p>
    </div>
  );
};

export default CustomerDetails;
