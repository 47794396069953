type CheckoutAccessoriesCrossSellAddProps = {
  title: string;
  sku: string;
  quantity: number;
  metersSquared: number;
  packPrice: string;
};

type CheckoutAccessoriesCrossSellData = {
  name: string;
  parent_product_sku: string;
  quantity: number;
  price: string;
  product_type: string;
  category: string;
  brand: string;
  colour: string;
  child_product_sku: string;
  meters_squared: number;
};

const gtmCheckoutAccessoriesCrossSellAdd = (product: CheckoutAccessoriesCrossSellAddProps) => {
  const { title, sku, quantity, metersSquared, packPrice } = product;
  const trackedProduct = {
    name: title,
    parent_product_sku: sku,
    price: packPrice,
    quantity: quantity,
    product_type: "purchase",
    category: "Accessories",
    brand: "Accessories",
    colour: "&nbsp;",
    child_product_sku: sku,
    meters_squared: metersSquared,
  } as CheckoutAccessoriesCrossSellData;

  window.dataLayer?.push({
    event: "CheckoutAccessoriesCrossSellAdd",
    ecommerce_action: {
      currencyCode: "GBP",
      add: {
        actionField: { list: "You will also need" },
        products: [trackedProduct],
      },
    },
  });
};

export default gtmCheckoutAccessoriesCrossSellAdd;
