import { useContext, useEffect } from "react";
import { useFormikContext } from "formik";
import { SESSION_STORAGE_ORDER_KEY } from "@ukfd/checkout-utils";
import { EMPTY_ORDER_DOCUMENT } from "@ukfd/constants";
import { CheckoutContext, UiStateContext } from "@context";
import { AddressBook, AddressFormContainer, DeliveryAddressForm } from "@components";
import { AddressSelectorProps } from "./AddressSelector.types";

const AddressSelector: React.FC<AddressSelectorProps> = () => {
  const { customer } = useContext(CheckoutContext);
  const {
    uiState: { deliveryAddressMode },
    setUiState,
  } = useContext(UiStateContext);

  const { values, setValues } = useFormikContext<FormikOrderDocument>();
  const isAddressBookVisible = deliveryAddressMode === "ADDRESS_BOOK";

  const deliveryAddress =
    customer?.deliveryAddress?.find((x) => x.isDefaultShipping === "true") ||
    customer?.deliveryAddress?.[0];

  const handleToggleAddress = () => {
    setValues({
      ...values,
      deliveryAddress:
        !isAddressBookVisible || !deliveryAddress
          ? EMPTY_ORDER_DOCUMENT.deliveryAddress
          : {
              id: deliveryAddress.id,
              addressLine1: deliveryAddress.firstLine,
              addressLine2: deliveryAddress.secondLine,
              postcode: deliveryAddress.postcode,
              city: deliveryAddress.city,
              country: deliveryAddress.country,
              county: deliveryAddress.state,
            },
    });
  };

  useEffect(() => {
    const data = sessionStorage.getItem(SESSION_STORAGE_ORDER_KEY);
    const orderDocument: SavedOrderDocument = data ? JSON.parse(data) : null;

    const getAddressMode = () => {
      if (orderDocument?.deliveryAddressMode) {
        return orderDocument?.deliveryAddressMode;
      } else if (customer?.deliveryAddress?.length > 0) {
        return "ADDRESS_BOOK";
      } else {
        return "MANUAL";
      }
    };

    setUiState({
      deliveryAddressMode: getAddressMode(),
    });
  }, [customer?.deliveryAddress]);

  useEffect(() => {
    handleToggleAddress();
  }, [deliveryAddressMode]);

  return (
    <>
      {customer?.deliveryAddress?.length && isAddressBookVisible ? (
        <AddressBook />
      ) : (
        <AddressFormContainer form={DeliveryAddressForm} />
      )}
    </>
  );
};

export default AddressSelector;
