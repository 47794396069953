import { gql } from "@apollo/client";

export const PRICE_FIELDS = gql`
  fragment Price on ProductPrice {
    subtotal
    subtotalExVat
    range {
      rangeString
      rangeStart
      rangeEnd
    }
    packPrice
    packPriceExTax
    m2Price
    m2PriceExTax
    selectedPrice
  }
`;
