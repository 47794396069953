import { DocumentNode, FetchResult } from "@apollo/client";
import apolloClient from "./apolloClient";

async function ukfdGraph<T = any, U = any>(
  userQuery: DocumentNode,
  additionalVariables: U = {} as any
): Promise<FetchResult<T>> {
  const isMutation = (userQuery.definitions[0] as any).operation == "mutation";
  if (isMutation) {
    return await apolloClient.mutate({
      mutation: userQuery,
      variables: additionalVariables,
      fetchPolicy: "no-cache",
    });
  } else {
    return await apolloClient.query({
      query: userQuery,
      variables: additionalVariables,
      fetchPolicy: "no-cache",
    });
  }
}
export default ukfdGraph;
