import { FC, useRef, useState } from "react";
import clsx from "clsx";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import minBy from "lodash/minBy";
import { CalendarDay, NavigationButton } from "@ukfd/ui";
import s from "./DeliveryCalendar.module.css";
import { DeliveryCalendarProps } from "./DeliveryCalendar.types";

const DeliveryCalendar: FC<DeliveryCalendarProps> = ({
  dates,
  onChange,
  onCalendarNavigationClick,
  className,
  isVatIncluded,
}) => {
  const [cutXAxisWhiteSpace, setCutXAxisWhiteSpace] = useState(true);
  const [selectedDeliveryDay, setSelectedDeliveryDay] = useState(0);
  const splide = useRef<Splide>(null);

  const findNextAvailable = (currentIndex: number, direction: "left" | "right") => {
    // Recursive function to find next available delivery day when scrolling either left or right

    if (currentIndex === 0 && direction === "left") {
      // Ran out of options going left
      return 0;
    }
    if (currentIndex === dates.length - 1 && direction === "right") {
      // Ran out of options going right
      return dates.length - 1;
    }

    const next = direction === "left" ? currentIndex - 1 : currentIndex + 1;
    const hasShippingMethods = dates[next].shippingMethods?.length > 0;

    if (hasShippingMethods) {
      return next;
    }

    return findNextAvailable(next, direction);
  };

  return (
    <Splide
      ref={splide}
      className={clsx(s.container, className)}
      hasTrack={false}
      options={{
        start: selectedDeliveryDay,
        rewind: false,
        arrows: false,
        gap: ".5rem",
        perMove: 1,
        focus: "center",
        trimSpace: cutXAxisWhiteSpace,
        snap: true,
        pagination: false,
        autoWidth: true,
      }}
      onActive={({ index }) => {
        setSelectedDeliveryDay(index);
        onChange?.(dates[index].date);
      }}
      onClick={(slide, e) => {
        const isDesiredSlideAvailable = dates[e.index].shippingMethods?.length > 0;
        if (!isDesiredSlideAvailable) {
          return;
        }

        slide.go(e.index);
      }}
      onMove={(slide, currentActiveIndex) => {
        const isDesiredSlideAvailable = dates[currentActiveIndex].shippingMethods?.length > 0;
        if (!isDesiredSlideAvailable) {
          slide.go(findNextAvailable(currentActiveIndex, "left"));
          return;
        }

        if (currentActiveIndex < 4) {
          setCutXAxisWhiteSpace(true);
        } else if (currentActiveIndex >= 4 && currentActiveIndex < 10) {
          setCutXAxisWhiteSpace(false);
        } else {
          setCutXAxisWhiteSpace(true);
        }
      }}
    >
      <SplideTrack>
        {dates.map(({ date, shippingMethods }, index) => {
          const minPrice = minBy(shippingMethods, (x) => x.shippingRate);
          const price =
            (isVatIncluded ? minPrice?.shippingRateIncVat : minPrice?.shippingRate) || 0;

          return (
            <SplideSlide key={date} className={s.slide}>
              <CalendarDay
                date={date}
                hasIndicator={shippingMethods.length > 1 && selectedDeliveryDay === index}
                price={price || 0}
                selected={selectedDeliveryDay === index}
                unavailable={shippingMethods.length === 0}
              />
            </SplideSlide>
          );
        })}
      </SplideTrack>

      {selectedDeliveryDay !== 0 && (
        <NavigationButton
          className={clsx(s.button, s.buttonLeft)}
          direction="left"
          onClick={() => {
            if (onCalendarNavigationClick) {
              onCalendarNavigationClick("left");
            }
            splide.current?.go(findNextAvailable(selectedDeliveryDay, "left"));
          }}
        />
      )}

      {selectedDeliveryDay !== dates.length - 1 && (
        <NavigationButton
          className={clsx(s.button, s.buttonRight)}
          direction="right"
          onClick={() => {
            if (onCalendarNavigationClick) {
              onCalendarNavigationClick("right");
            }
            splide.current?.go(findNextAvailable(selectedDeliveryDay, "right"));
          }}
        />
      )}
    </Splide>
  );
};

export default DeliveryCalendar;
