import { safelyRound } from "@ukfd/shared-utils";

const GB_VAT_DEFAULT = 0.2; // 20%

export function calculateTaxAmount(amount: number): number {
  return safelyRound(amount - amount / (1 + GB_VAT_DEFAULT));
}

export function addTax(amount: number): number {
  return safelyRound(amount * (1 + GB_VAT_DEFAULT));
}

export function removeTax(amount: number): number {
  return safelyRound(amount - (amount - amount / (1 + GB_VAT_DEFAULT)));
}
