import { gql } from "@apollo/client";

export const VALIDATE_ORDER_MUTATION = gql`
  mutation validateOrder($order: OrderDocument!) {
    validateOrder(order: $order) {
      orderId
      sampleOrderId
      status
    }
  }
`;

export const VALIDATE_ORDER_PAYMENT_MUTATION = gql`
  mutation validatePayment(
    $orderId: String!
    $paymentType: PaymentDto!
    $expirationMonth: String
    $expirationYear: String
    $nameOnCard: String
    $makeDefaultCreditCard: Boolean = false
    $saveCard: Boolean = false
    $cybersourceResponse: CybersourcePaymentDetails
    $paypalResponse: PayPalPaymentDetails
  ) {
    validatePayment(
      orderId: $orderId
      paymentType: $paymentType
      expirationMonth: $expirationMonth
      expirationYear: $expirationYear
      nameOnCard: $nameOnCard
      makeDefaultCreditCard: $makeDefaultCreditCard
      saveCard: $saveCard
      cybersourceResponse: $cybersourceResponse
      paypalResponse: $paypalResponse
    ) {
      orderId
      sampleOrderId
      status
    }
  }
`;
