import * as React from "react";
import { Checkbox, Select, Text } from "@ukfd/ui";
import s from "./SampleSurveyForm.module.css";

const SampleSurveyForm: React.FC = () => (
  <>
    <Text id="about-your-project" variant="sectionHeading">
      About your project
    </Text>
    <Text variant="body">
      Help us to better understand your project needs by answering the following questions:
    </Text>
    <Text className="font-bold" variant="body">
      What room(s) are you looking to purchase flooring for? *
    </Text>

    <div>
      <Checkbox label="Living Room" name="sampleSurveyForm.livingRoom" />
      <Checkbox label="Dining Room" name="sampleSurveyForm.diningRoom" />
      <Checkbox label="Bathroom" name="sampleSurveyForm.bathroom" />
      <Checkbox label="Bedroom" name="sampleSurveyForm.bedroom" />
      <Checkbox label="Kitchen" name="sampleSurveyForm.kitchen" />
      <Checkbox label="Hallway" name="sampleSurveyForm.hallway" />
      <Checkbox label="Study" name="sampleSurveyForm.study" />
      <Checkbox label="Loft" name="sampleSurveyForm.loft" />
      <Checkbox label="Conservatory" name="sampleSurveyForm.conservatory" />
      <Checkbox label="Utility Room" name="sampleSurveyForm.utilityRoom" />
      <Checkbox label="Outbuilding" name="sampleSurveyForm.outbuilding" />
      <Checkbox label="Other" name="sampleSurveyForm.other" />
    </div>

    <div className="mb-3">
      <Select
        label="How soon are you looking to purchase your flooring?"
        labelClassName={s.selectLabel}
        name="sampleSurveyForm.whenInstalling"
        options={[
          {
            text: "I'm ready now",
            value: "imReadyNow",
          },
          {
            text: "In the next 4 weeks",
            value: "inTheNext4Weeks",
          },
        ]}
        required
      />
    </div>
  </>
);

export default SampleSurveyForm;
