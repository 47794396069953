import * as React from "react";
import clsx from "clsx";
import s from "./BasketLayout.module.css";

export interface BasketLayoutProps {
  aside?: React.ReactNode;
  narrow?: boolean;
  children?: React.ReactNode;
}

const BasketLayout: React.FC<BasketLayoutProps> = ({ aside, narrow = false, children }) => {
  return (
    <main className={s.root}>
      <div
        className={clsx(s.wrapper, {
          [s.fullWidth]: !aside,
          [s.narrow]: narrow,
        })}
      >
        {children}
      </div>
      {aside && <aside className={s.aside}>{aside}</aside>}
    </main>
  );
};

export default BasketLayout;
