import { gql } from "@apollo/client";
import { PRICE_FIELDS } from "./fragments/price";
import { PRODUCT_DETAILS_FIELDS } from "./fragments/product";
import { PROMO_FIELDS } from "./fragments/promo";

export const UPDATE_BASKET_MUTATION = gql`
  ${PRODUCT_DETAILS_FIELDS}
  ${PRICE_FIELDS}
  ${PROMO_FIELDS}
  mutation updateBasketQuery(
    $id: ID!
    $basketItemId: String
    $sku: String!
    $quantity: Float!
    $productType: String!
    $promocodesRequested: [String!]!
    $customerType: String!
  ) {
    checkoutUpdateBasket(
      id: $id
      basketItemId: $basketItemId
      sku: $sku
      quantity: $quantity
      productType: $productType
      promocodesRequested: $promocodesRequested
      customerType: $customerType
    ) {
      basket {
        id
        itemCount
        price {
          subtotal
          subtotalExVat
        }
        promo {
          ...Promo
        }
        basketItems {
          basketItemId
          sku
          quantity
          details {
            ...ProductDetails
          }
          price {
            ...Price
          }
        }
      }
      delivery {
        basketWeight
        deliveryCost
      }
    }
  }
`;

export const DELETE_BASKET_ITEM_MUTATION = gql`
  ${PRODUCT_DETAILS_FIELDS}
  ${PROMO_FIELDS}
  ${PRICE_FIELDS}
  mutation deleteBasketItemMutation(
    $id: String!
    $basketItemId: String!
    $promocodesRequested: [String!]
    $customerType: String
  ) {
    checkoutDeleteBasketItem(
      id: $id
      basketItemId: $basketItemId
      promocodesRequested: $promocodesRequested
      customerType: $customerType
    ) {
      basket {
        id
        itemCount
        price {
          subtotal
          subtotalExVat
        }
        promo {
          ...Promo
        }
        basketItems {
          basketItemId
          sku
          quantity
          details {
            ...ProductDetails
          }
          price {
            ...Price
          }
        }
      }
      delivery {
        basketWeight
        deliveryCost
      }
    }
  }
`;

export const DELETE_BASKET_MUTATION = gql`
  mutation deleteBasketQuery($basketId: String!) {
    deleteBasket(basketId: $basketId) {
      id
    }
  }
`;
